import { Check, Close, InfoOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import {
  Box,
  Button,
  Card,
  CardActions,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SxProps,
  Theme,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material'
import ExternalLink from '@mui/material/Link'
import { isMobile } from 'mobile-device-detect'
import { useState } from 'react'
import { useEffectOnce } from 'react-use'
import config from '../../../config'
import pbTheme from '../../../config/theme'
import useGAEvent from '../../auth/hooks/useGAEvent'
import { GenericHeader } from '../homepage/components/elements/StepHeader'

const PlansSection = styled(Box)(({ theme }) => ({
  margin: '40px 0',
  textAlign: 'center',
  padding: '0 10px 10px',
  [theme.breakpoints.up('md')]: {
    padding: '0 75px 10px',
    margin: '40px 0 140px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '0 140px 10px'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '0 20px 10px'
  }
}))

const StyledGrid = styled(Grid)(() => ({
  justifyContent: 'center',
  marginTop: '10px'
}))

const StyledCard = styled(Card)(() => ({
  borderRadius: '30px',
  marginTop: '20px',
  textAlign: 'center',
  border: '1px solid #DADADA',
  boxShadow: '0px 5.59441px 151.049px rgba(227, 227, 227, 0.25)',
  flex: 1,
  overflow: 'visible'
}))

const CardHead = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: 1,
  marginBottom: '10px',
  marginTop: '50px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1.75rem',
    marginBottom: '5px'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.75rem',
    marginBottom: '10px',
    marginTop: '70px'
  }
}))

const CardStorage = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: 1,
  textTransform: 'none',
  [theme.breakpoints.up('sm')]: {
    padding: '0 10px',
    fontSize: '0.75rem',
    lineHeight: 1.25
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '1.5rem'
  }
}))

const CardPrice = styled(Typography)(({ theme }) => ({
  color: '#0b0b0b',
  fontSize: '5.5rem',
  fontWeight: 600,
  lineHeight: 1,
  marginTop: '20px',
  marginBottom: '10px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '4.25rem'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '4.75rem'
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: '25px',
    fontSize: '5.5rem'
  },
  [theme.breakpoints.up('xl')]: {
    marginTop: '30px',
    fontSize: '7.5rem'
  }
}))

const CardTerm = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: 1,
  margin: '5px 0 0',
  marginBottom: '0',
  [theme.breakpoints.up('lg')]: {
    margin: '10px 0',
    fontSize: '1.25rem'
  },
  [theme.breakpoints.up('xl')]: {
    margin: '10px 0 20px',
    fontSize: '1.5rem'
  }
}))

const PaddingActions = styled(CardActions)(({ theme }) => ({
  padding: '20px 16px',
  [theme.breakpoints.up('sm')]: {
    padding: '20px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '20px 20px'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '20px 35px'
  }
}))

const StyledButton = styled(Button)(({ theme }) => ({
  background: theme.palette.primary.main,
  boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
  borderRadius: '86px',
  color: theme.palette.primary.contrastText,
  fontSize: '1rem',
  fontWeight: 500,
  textTransform: 'none',
  margin: '0 auto',
  padding: '8px 20px',
  // maxWidth: '400px',
  width: '80%',
  '&:hover': {
    background: 'linear-gradient(175deg, #02E39F 5.3%, #202CD3 102.34%)',
    color: theme.palette.primary.contrastText
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
    padding: '8px 15px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    padding: '5px 10px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '5px 5px',
    fontSize: '1.25rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '10px 20px',
    fontSize: '1.5rem'
  }
}))

const CurrentPlan = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  lineHeight: 1.75,
  color: theme.palette.primary.contrastText,
  background: '#658CBB',
  borderRadius: '86px',
  fontWeight: 500,
  margin: '0 auto',
  padding: '8px 40px',
  maxWidth: '400px',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    fontSize: '0.875rem',
    padding: '8px 15px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '10px 10px',
    fontSize: '1.275rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '20px 40px',
    fontSize: '1.5rem'
  }
}))

const Recommended = styled('p')(({ theme }) => ({
  fontSize: '1rem',
  color: 'black',
  background: '#00DB98',
  borderRadius: '50px',
  fontWeight: 500,
  margin: '0 auto',
  padding: '8px 35px',
  position: 'absolute',
  zIndex: 1,
  width: '70%',
  maxWidth: '300px',
  [theme.breakpoints.up('xs')]: {
    fontSize: '1rem',
    padding: '8px 20px',
    top: '-22px',
    left: '50%',
    transform: 'translate(-50%, 0)'
  },
  [theme.breakpoints.up('sm')]: {
    padding: '8px 30px'
  },
  [theme.breakpoints.up('md')]: {
    padding: '8px 10px',
    top: '-20px'
  },
  [theme.breakpoints.up('lg')]: {
    padding: '10px 20px'
  },
  [theme.breakpoints.up('xl')]: {
    top: '-25px'
  }
}))

const CardSavings = styled(Typography)(({ theme }) => ({
  marginBottom: 0,
  marginTop: 0,
  padding: '18px 16px 20px',
  color: '#2b2b2b',
  fontSize: '0.875rem',
  fontWeight: 300,
  lineHeight: 1.25,
  '& span': {
    fontWeight: '600',
    color: theme.palette.primary.main
  },
  [theme.breakpoints.up('lg')]: {
    padding: '20px 20px',
    fontSize: '1.125rem'
  },
  [theme.breakpoints.up('xl')]: {
    padding: '40px 50px 40px',
    fontSize: '1.5rem'
  }
}))

const BlackLink = styled(ExternalLink)(({ theme }) => ({
  marginTop: 12,
  fontWeight: 600,
  display: 'block',
  color: 'primary',
  textDecoration: 'none',
  [theme.breakpoints.down('sm')]: {
    // targeting mobile devices
    margin: 3
  }
}))

const MonthButton = styled(Button, { shouldForwardProp: (prop: string) => prop !== 'plans' })<{ plans: string }>(({ plans, theme }) => ({
  backgroundColor: plans === 'monthly' ? theme.palette.primary.main : theme.palette.primary.contrastText,
  borderColor: theme.palette.primary.contrastText,
  borderRadius: '8px',
  boxShadow: plans === 'monthly' ? '0px 0.482333px 0.964667px rgba(16, 24, 40, 0.05)' : 'none',
  color: plans === 'monthly' ? theme.palette.primary.contrastText : '#000',
  fontWeight: plans === 'monthly' ? 500 : 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: plans === 'monthly' ? theme.palette.primary.main : theme.palette.primary.contrastText,
    borderColor: plans === 'monthly' ? theme.palette.primary.contrastText : theme.palette.primary.main,
    color: plans === 'monthly' ? theme.palette.primary.contrastText : theme.palette.primary.main,
    boxShadow: '0px 0.482333px 0.964667px rgba(16, 24, 40, 0.05)'
  }
}))

const YearButton = styled(Button, { shouldForwardProp: (prop: string) => prop !== 'plans' })<{ plans: string }>(({ plans, theme }) => ({
  backgroundColor: plans === 'yearly' ? theme.palette.primary.main : theme.palette.primary.contrastText,
  boxShadow: plans === 'yearly' ? '0px 0.482333px 0.964667px rgba(16, 24, 40, 0.05)' : 'none',
  borderColor: theme.palette.primary.contrastText,
  borderRadius: '8px',
  color: plans === 'yearly' ? theme.palette.primary.contrastText : '#000',
  fontWeight: plans === 'yearly' ? 500 : 'bold',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: plans === 'yearly' ? theme.palette.primary.main : theme.palette.primary.contrastText,
    borderColor: plans === 'yearly' ? theme.palette.primary.contrastText : theme.palette.primary.main,
    color: plans === 'yearly' ? theme.palette.primary.contrastText : theme.palette.primary.main,
    boxShadow: '0px 0.482333px 0.964667px rgba(16, 24, 40, 0.05)'
  }
}))

const TooltipSx: SxProps<Theme> = {
  '& .MuiTooltip-tooltip': {
    backgroundColor: 'black',
    borderRadius: '1.5rem',
    fontSize: '.85rem',
    lineHeight: 1.8,
    maxWidth: '18rem',
    padding: '1rem'
  },
  '& .MuiTooltip-arrow': {
    color: 'black'
  }
}

interface YearlyProps {
  plan?: string
  isYearly?: boolean
}

interface MonthlyProps {
  plan?: string
  isMonthly?: boolean
}

interface Props {
  plan?: string
  planCycle?: string
}

function BillingURL(id: 'storage' | 'sharing' | 'premium', type: 'monthly' | 'yearly' | 'trial') {
  const data = {
    id,
    type
  }

  const url = config.appUrl.billing
  const querystring = new URLSearchParams(data).toString()
  return [url, querystring].join('?')
}

function FeatureList({ plan }: Readonly<{ plan: 'storage' | 'sharing' | 'premium' }>) {
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [openFeatureList, setOpenFeatureList] = useState(false)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    setOpen(!open)
  }

  const handleToggle = () => {
    setOpenFeatureList(!openFeatureList)
  }

  const viewList = isMobile ? openFeatureList : true

  return (
    <>
      {isMobile && (
        <IconButton
          disableRipple
          onClick={handleToggle}
          aria-label={!openFeatureList ? 'Expand' : 'Collapse'}
          sx={{
            color: 'primary.main',
            paddingTop: '30px',
            paddingBottom: '30px',
            height: '20px',
            width: '20opx'
          }}
        >
          <CardStorage>View Plan Details</CardStorage>
          {openFeatureList ? <KeyboardArrowDown fontSize="small" /> : <KeyboardArrowUp fontSize="small" />}
        </IconButton>
      )}
      {viewList && (
        <List
          sx={{
            display: 'inline-block',
            marginBottom: isMobile ? 1 : 4,
            width: '100%',
            maxWidth: '300px',
            paddingTop: isMobile ? '0px' : '30px'
          }}
          dense
          disablePadding
        >
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Mobile auto backup" />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Album creation" />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Sorting tools" />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Compression-free guarantee" />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              <Check color="primary" />
            </ListItemAvatar>
            <ListItemText primary="Carbon-neutral memory management" />
          </ListItem>

          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              {plan !== 'storage' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              primary={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: plan !== 'storage' ? '#000000DE' : theme.palette.customError[500],
                    fontWeight: plan === 'sharing' ? 'bold' : 'inherit',
                    minHeight: '37px'
                  }}
                >
                  Group sharing{' '}
                  {plan !== 'storage' && (
                    <Tooltip
                      arrow
                      open={open}
                      PopperProps={{ sx: TooltipSx }}
                      title="Group Buckets lets you invite a select group to share photos & videos using one simple link, giving you total control and privacy."
                    >
                      <IconButton color="primary" style={{ zIndex: 100 }} onClick={handleClick}>
                        <InfoOutlined fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              }
            />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              {plan !== 'storage' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              sx={{
                '& .MuiTypography-root': { fontWeight: plan === 'sharing' ? 'bold' : 'normal', color: plan !== 'storage' ? 'inherit' : 'error.main' }
              }}
              primary="Personal & social sharing"
            />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              {plan !== 'storage' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              sx={{
                '& .MuiTypography-root': { fontWeight: plan === 'sharing' ? 'bold' : 'normal', color: plan !== 'storage' ? 'inherit' : 'error.main' }
              }}
              primary="Photo editing tools"
            />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px'
              }}
            >
              {plan !== 'storage' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              sx={{
                '& .MuiTypography-root': {
                  fontWeight: plan === 'sharing' ? 'bold' : 'normal',
                  color: plan !== 'storage' ? 'inherit' : 'error.main'
                }
              }}
              primary="Video playback"
            />
          </ListItem>
          <ListItem sx={{ py: 0 }} dense>
            <ListItemAvatar
              sx={{
                minWidth: 34,
                paddingTop: '5px',
                paddingBottom: 1
              }}
            >
              {plan === 'premium' ? <Check color="primary" /> : <Close color="error" />}
            </ListItemAvatar>
            <ListItemText
              sx={{
                '& .MuiTypography-root': {
                  fontWeight: plan === 'premium' ? 'bold' : 'normal',
                  color: plan === 'premium' ? 'inherit' : 'error.main',
                  paddingBottom: 1
                }
              }}
              primary="Embed Links for hosting"
            />
          </ListItem>
        </List>
      )}
    </>
  )
}

function MonthlyPlans({ plan, isMonthly }: Readonly<MonthlyProps>) {
  const { trackEvent } = useGAEvent()
  return (
    <StyledGrid container spacing={2}>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          <CardHead>My Bucket</CardHead>
          <CardStorage>1 TB of basic storage</CardStorage>
          <CardPrice>$5</CardPrice>
          <CardTerm>per month</CardTerm>
          {plan === 'storage' && isMonthly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-monthly-storage"
                fullWidth
                href={BillingURL('storage', 'monthly')}
                variant="contained"
                onClick={() => {
                  trackEvent('clicked_purchase_plan', { planName: 'storage', planCycle: 'monthly', newPlanPage: true })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="storage" />
        </StyledCard>
        <BlackLink
          href={BillingURL('storage', 'yearly')}
          onClick={() => {
            trackEvent('clicked_purchase_plan', { planName: 'storage', planCycle: 'yearly', newPlanPage: true })
          }}
        >
          <Box component="span">Save another $10 by paying yearly</Box>
        </BlackLink>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard sx={{ border: '1px solid blue', position: 'relative' }}>
          <Recommended>Most Popular</Recommended>
          <CardHead>Group Buckets</CardHead>
          <CardStorage>1 TB of storage + sharing</CardStorage>
          <CardPrice>$8</CardPrice>
          <CardTerm>per month</CardTerm>
          {plan === 'sharing' && isMonthly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-monthly-sharing"
                fullWidth
                href={BillingURL('sharing', plan === undefined ? 'trial' : 'monthly')}
                variant="contained"
                sx={{ background: 'linear-gradient(175deg, #202CD3 5.3%, #02E39F 102.34%)' }}
                onClick={() => {
                  trackEvent('clicked_purchase_plan', {
                    planName: 'sharing',
                    planCycle: plan === undefined ? 'trial' : 'monthly',
                    newPlanPage: true
                  })
                }}
              >
                {plan === undefined ? 'Start 7-day Free Trial' : 'Purchase'}
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="sharing" />
        </StyledCard>
        <BlackLink
          href={BillingURL('sharing', 'monthly')}
          onClick={() => {
            trackEvent('clicked_purchase_plan', {
              planName: 'sharing',
              planCycle: plan === undefined ? 'monthly' : 'yearly',
              newPlanPage: true
            })
          }}
        >
          <Box component="span">{plan === undefined ? 'Or purchase a monthly plan here' : 'Save another $16 by paying yearly'}</Box>
        </BlackLink>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          <CardHead>Hosting</CardHead>
          <CardStorage>Embedded sharing</CardStorage>
          <CardPrice>$13</CardPrice>
          <CardTerm>per month</CardTerm>
          {plan === 'expert' && isMonthly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-monthly-sharing"
                fullWidth
                href={BillingURL('premium', 'monthly')}
                variant="contained"
                onClick={() => {
                  trackEvent('clicked_purchase_plan', {
                    planName: 'premium',
                    planCycle: 'monthly',
                    newPlanPage: true
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="premium" />
        </StyledCard>
        <BlackLink
          href={BillingURL('premium', 'yearly')}
          onClick={() => {
            trackEvent('clicked_purchase_plan', {
              planName: 'premium',
              planCycle: 'yearly',
              newPlanPage: true
            })
          }}
        >
          <Box component="span">Save another $16 by paying yearly</Box>
        </BlackLink>
      </Grid>
    </StyledGrid>
  )
}

MonthlyPlans.defaultProps = {
  plan: undefined,
  isMonthly: false
}

function YearlyPlans({ plan, isYearly }: Readonly<YearlyProps>) {
  const { trackEvent } = useGAEvent()
  return (
    <StyledGrid container spacing={2} display="flex" justifyContent="center" alignItems="stretch">
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          <CardHead>My Bucket</CardHead>
          <CardStorage>1 TB of basic storage</CardStorage>
          <CardPrice>$50</CardPrice>
          <CardTerm>per year</CardTerm>
          {plan === 'storage' && isYearly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-yearly-storage"
                fullWidth
                href={BillingURL('storage', 'yearly')}
                variant="contained"
                onClick={() => {
                  trackEvent('clicked_purchase_plan', {
                    planName: 'storage',
                    planCycle: 'yearly',
                    newPlanPage: true
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="storage" />
        </StyledCard>
        <CardSavings>
          You <Box component="span">save $10</Box> vs a monthly payment
        </CardSavings>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          <CardHead>Group Buckets</CardHead>
          <CardStorage>1 TB of storage + sharing</CardStorage>
          <CardPrice>$80</CardPrice>
          <CardTerm>per year</CardTerm>
          {plan === 'sharing' && isYearly === true ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-yearly-sharing"
                fullWidth
                href={BillingURL('sharing', 'yearly')}
                variant="contained"
                onClick={() => {
                  trackEvent('clicked_purchase_plan', {
                    planName: 'sharing',
                    planCycle: 'yearly',
                    newPlanPage: true
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="sharing" />
        </StyledCard>
        <CardSavings>
          You <Box component="span">save $16</Box> vs a monthly payment
        </CardSavings>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
        <StyledCard>
          <CardHead>Hosting</CardHead>
          <CardStorage>Embedded sharing</CardStorage>
          <CardPrice>$140</CardPrice>
          <CardTerm>per year</CardTerm>
          {plan === 'expert' && isYearly ? (
            <PaddingActions>
              <CurrentPlan>Current Plan</CurrentPlan>
            </PaddingActions>
          ) : (
            <PaddingActions>
              <StyledButton
                data-test="plans-monthly-sharing"
                fullWidth
                href={BillingURL('premium', 'yearly')}
                variant="contained"
                onClick={() => {
                  trackEvent('clicked_purchase_plan', {
                    planName: 'premium',
                    planCycle: 'yearly',
                    newPlanPage: true
                  })
                }}
              >
                Purchase
              </StyledButton>
            </PaddingActions>
          )}
          <FeatureList plan="premium" />
        </StyledCard>
        <CardSavings>
          You <Box component="span">save $16</Box> vs a monthly payment
        </CardSavings>
      </Grid>
    </StyledGrid>
  )
}

YearlyPlans.defaultProps = {
  plan: undefined,
  isYearly: false
}

export default function Plans({ plan, planCycle }: Readonly<Props>) {
  const { trackEvent } = useGAEvent()
  const cadence = planCycle === 'annual' ? 'yearly' : 'monthly'
  const [plans, setPlans] = useState<'monthly' | 'yearly'>(cadence)

  const handleClick = (show: 'monthly' | 'yearly') => () => {
    setPlans(show)
  }

  // capture event for page view
  useEffectOnce(() => {
    trackEvent('plans_page_view', { newPlanPage: true, id: plan === undefined ? 'select_plan' : 'change_plan' })
  })

  return (
    <PlansSection component="section" id="plansBlock">
      <GenericHeader title={plan === undefined ? 'Select a Plan' : 'Change your Plan'} />
      <Box
        sx={{
          border: '1px solid #dadada',
          borderRadius: '8px',
          padding: { xs: '4px 6px', md: '6px 8px', lg: '8px 10px', xl: '10px 12px' },
          minWidth: { xs: '200px', md: '200px', lg: '250px', xl: '300px' },
          marginTop: '20px',
          marginBottom: '20px',
          display: 'inline-flex',
          gap: '10px',
          [pbTheme.breakpoints.up('xl')]: {
            marginBottom: '50px'
          }
        }}
      >
        <Tooltip
          arrow
          PopperProps={{ sx: TooltipSx }}
          title="A monthly billing schedule allows you to be flexible with your plan, we'll automatically renew your subscription each month for you until you decide to cancel."
        >
          <MonthButton
            plans={plans}
            onClick={handleClick('monthly')}
            color="primary"
            variant={plans === 'monthly' ? 'contained' : 'outlined'}
            data-test="plans-monthly-toggle"
            sx={{
              padding: { xs: '0px 20px', md: '0px 30px', lg: '0px 30px', xl: '5px 35px' },
              fontSize: { xs: '0.875rem', md: '1rem', lg: '1.25rem' },
              minWidth: { xs: '100px', sm: '125px', md: '150px', lg: '175px', xl: '200px' }
            }}
          >
            Monthly
          </MonthButton>
        </Tooltip>
        <Tooltip
          arrow
          PopperProps={{ sx: TooltipSx }}
          title="An annual billing schedule helps you get the best price on your subscription and you don't have to worry about a monthly renewal."
        >
          <YearButton
            plans={plans}
            onClick={handleClick('yearly')}
            color="primary"
            variant={plans === 'yearly' ? 'contained' : 'outlined'}
            data-test="plans-years-toggle"
            sx={{
              padding: { xs: '0px 20px', md: '0px 30px', lg: '0px 30px', xl: '5px 35px' },
              fontSize: { xs: '0.875rem', md: '1rem', lg: '1.25rem' },
              minWidth: { xs: '100px', sm: '125px', md: '150px', lg: '175px', xl: '200px' }
            }}
          >
            Yearly
          </YearButton>
        </Tooltip>
      </Box>
      <div style={{ display: 'grid' }}>
        <Fade in={plans === 'monthly'} unmountOnExit>
          <div>
            <MonthlyPlans key="monthly" plan={plan} isMonthly={planCycle?.toLowerCase().includes('month')} />
          </div>
        </Fade>
        <Fade in={plans === 'yearly'} unmountOnExit>
          <div>
            <YearlyPlans
              key="yearly"
              plan={plan}
              isYearly={planCycle?.toLowerCase().includes('annual') ?? planCycle?.toLowerCase().includes('year')}
            />
          </div>
        </Fade>
      </div>
    </PlansSection>
  )
}

Plans.defaultProps = {
  plan: undefined,
  planCycle: undefined
}
