import { useQuery } from '@apollo/client'
import { MoreHoriz } from '@mui/icons-material'
import { IconButton, Menu, MenuItem, Tooltip, styled } from '@mui/material'
import { getAuth } from 'firebase/auth'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { BucketType, BucketsByUserIdDocument, PermissionPolicy } from '../../../../../graphql/generated'
import { drawerVar, modalVar } from '../../../../../providers/apollo/cache'
import logger from '../../../../../utils/logger'
import useAlerts from '../../../hooks/useAlerts'
import useBucket from '../../../hooks/useBucket'
import useProfilePlan from '../../../hooks/useProfilePlan'
import useUserSubscription from '../../../hooks/useUserSubcription'

interface Props {
  permissions?: PermissionPolicy | null
  bucketId?: string
}

const DropdownButton = styled(IconButton)(({ theme }) => ({
  borderRadius: '4px',
  border: `1px solid ${theme.palette.secondary.main}`,
  height: '32px',
  width: '32px',
  '&:hover': {
    background: theme.palette.primary.highlight
  }
})) as typeof IconButton

export default function BucketMenu({ permissions, bucketId }: Readonly<Props>) {
  const { createAlert } = useAlerts()
  const { bucket } = useBucket()
  const { canCreateBucket } = useProfilePlan()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const userId = getAuth().currentUser?.uid
  const { isSubscriptionActive } = useUserSubscription()

  const disabled = !isSubscriptionActive
  const hideTooltip = !disabled

  useQuery(BucketsByUserIdDocument, {
    skip: !userId,
    variables: { userId: userId! },
    onError: (e) => {
      e.graphQLErrors.forEach((error) => {
        createAlert(error.message, 'error', error)
      })

      if (!e.graphQLErrors) {
        logger.error(e)
        createAlert('We are having trouble retrieving your buckets. Please try again later.')
      }
    }
  })

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openCreateModal = (value: 'createBucket') => () => {
    modalVar(value)
    handleClose()
    drawerVar(null)
  }

  const openInviteModal = () => {
    const modal = bucket?.bucketType === BucketType.Group ? 'bucketInvite' : 'bucketUpgrade'
    modalVar(modal)
    drawerVar(null)
    handleClose()
  }

  return (
    <>
      <DropdownButton onClick={handleOpen} data-test="bucket-menu">
        <MoreHoriz fontSize="small" />
      </DropdownButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {permissions?.invitationCreate && !bucket?.isBanned && (
          <MenuItem onClick={openInviteModal} data-test="bucket-menu-invite-members">
            Invite Members
          </MenuItem>
        )}
        {permissions?.userList && bucketId && !bucket?.isBanned && (
          <MenuItem component={Link} to={`/bucket/${bucketId}/manage`} data-test="bucket-menu-settings">
            Bucket Settings
          </MenuItem>
        )}
        <MenuItem component={Link} to="/buckets" data-test="bucket-menu-manage-buckets">
          View All Buckets
        </MenuItem>
        {canCreateBucket && (
          <Tooltip
            PopperProps={{
              disablePortal: hideTooltip
            }}
            disableFocusListener={hideTooltip}
            disableHoverListener={hideTooltip}
            disableTouchListener={hideTooltip}
            title="You need to have an active subscription to create a bucket."
          >
            <div data-test="create-bucket">
              <MenuItem disabled={disabled} onClick={openCreateModal('createBucket')}>
                Create New Bucket
              </MenuItem>
            </div>
          </Tooltip>
        )}
      </Menu>
    </>
  )
}

BucketMenu.defaultProps = {
  permissions: null,
  bucketId: ''
}
