import { useReactiveVar } from '@apollo/client'
import { AwsRum, AwsRumConfig } from 'aws-rum-web'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useAnalytics } from 'use-analytics'
import { rumConfig } from '../config'
import { authenticatedUserVar } from '../providers/apollo/cache'
import logger from '../utils/logger'

export default function AnalyticsTracking() {
  const location = useLocation()
  const { page } = useAnalytics()
  const rum = useRef<AwsRum>()
  const user = useReactiveVar(authenticatedUserVar)

  useEffect(() => {
    try {
      if (!rumConfig.applicationId) {
        return
      }

      const config: AwsRumConfig = {
        pagesToExclude: [
          /https:\/\/app\.photobucket\.com\//,
          /https:\/\/next\.photobucket\.com\//,
          /https:\/\/app\.photobucket\.com\//,
          /https:\/\/next\.photobucket\.com\//,
          /https:\/\/photobucket\.com\//
        ],
        sessionSampleRate: 0.25,
        guestRoleArn: rumConfig.guestRoleArn,
        identityPoolId: rumConfig.identityPoolId,
        endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
        telemetries: ['performance', 'errors', 'http'],
        allowCookies: true,
        enableXRay: true
      }

      const awsRum = new AwsRum(rumConfig.applicationId, rumConfig.version, rumConfig.region, config)
      awsRum.enable()
      rum.current = awsRum
    } catch (error) {
      logger.error(error)
    }
  }, [])

  useEffect(() => {
    /* Track a user */
    if (user?.profile) {
      rum.current?.addSessionAttributes({ userId: user.profile.id })
    }
  }, [user?.profile])

  useEffect(() => {
    /* Track a page view */
    rum.current?.recordPageView({ pageId: location.pathname })
    page()
  }, [location.pathname, page])

  return null
}
