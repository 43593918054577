import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'
import { IconButton, Tooltip } from '@mui/material'
import { BucketMedia } from '../../../../../graphql/generated'
import { drawerVar } from '../../../../../providers/apollo/cache'
import useSelectedMedia from '../../../hooks/useSelectedMedia'
import useBucket from '../../../hooks/useBucket'
import useBucketMigrationProgress from '../../../hooks/useBucketMigrationProgress'

interface Props {
  media?: BucketMedia | null
}
export default function MediaMove({ media }: Props) {
  const { selectedMedia } = useSelectedMedia()
  const { bucketUser, bucket } = useBucket()
  const bucketId = bucket?.id
  const mediaMovePermission = bucketUser?.permissions?.mediaUpdate
  const mediaIds: string[] = media ? [media.id] : Array.from(selectedMedia.keys())
  const isMigrationInProgressForBucket = useBucketMigrationProgress()
  let title = 'Move Media'
  if (isMigrationInProgressForBucket) {
    title = 'Media move is disabled during the migration process'
  } else if (!mediaMovePermission) {
    title = 'Your role for this bucket does not allow you to move media.'
  }

  const handleClick = () => {
    if (!bucketId || !mediaIds.length) return
    drawerVar('bucketMediaMove')
  }

  return (
    <Tooltip title={title} arrow>
      <div>
        <IconButton
          color="inherit"
          aria-label="Move Media"
          disabled={!selectedMedia.size || !mediaMovePermission || isMigrationInProgressForBucket}
          onClick={handleClick}
          id="move-media"
          data-test="move-media"
        >
          <DriveFileMoveOutlinedIcon />
        </IconButton>
      </div>
    </Tooltip>
  )
}

MediaMove.defaultProps = {
  media: null
}
