import CssBaseline from '@mui/material/CssBaseline'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import AnalyticsTracking from './components/AnalyticsTracking'
import Pendo from './components/Pendo'
import theme from './config/theme'
import './expanded-theme'
import './global.css'
import './initFirebase'
import AdminProvider from './providers/AdminProvider'
import ErrorBoundaryProvider from './providers/ErrorBoundary'
import FeatureTogglesProvider from './providers/FeatureToggles'
import FirebaseBlockingProvider from './providers/FirebaseProvider'
import GoogleTagManagerProvider from './providers/GoogleTagManagerProvider'
import ApolloProvider from './providers/apollo/Provider'
import Router from './views/Router'

declare global {
  interface Window {
    AppleID: AppleSignInAPI.AppleID
    dataLayer?: Record<string, any>[]
  }
}

export default function App() {
  return (
    <GoogleTagManagerProvider>
      <ErrorBoundaryProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <FeatureTogglesProvider>
                <FirebaseBlockingProvider>
                  <ApolloProvider>
                    <AdminProvider>
                      <Pendo />
                      <CssBaseline />
                      <AnalyticsTracking />
                      <Router />
                    </AdminProvider>
                  </ApolloProvider>
                </FirebaseBlockingProvider>
              </FeatureTogglesProvider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </ErrorBoundaryProvider>
    </GoogleTagManagerProvider>
  )
}
