import { useLazyQuery } from '@apollo/client'
import { getAuth } from 'firebase/auth'
import rg4js from 'raygun4js'
import { Architecture, BucketDocument, BucketFragmentFragmentDoc, BucketStatus } from '../../../graphql/generated'
import cache from '../../../providers/apollo/cache'

export default function useBucketRedirect() {
  const [fetchBucket] = useLazyQuery(BucketDocument, { fetchPolicy: 'cache-first' })

  async function redirectToBucket(bucketId?: string) {
    if (!bucketId) return

    // TODO: delete this when userId is no longer supported as bucketId
    if (bucketId === getAuth().currentUser?.uid) {
      rg4js('trackEvent', {
        type: 'redirect',
        path: `/u/${getAuth().currentUser?.uid}`
      })
      window.location.href = `/u/${getAuth().currentUser?.uid}`
      return
    }

    // attempt to prevent a network call by hitting the cache
    let bucket = cache.readFragment({
      id: `Bucket:${bucketId}`,
      fragment: BucketFragmentFragmentDoc
    })

    // fetch bucket if not in cache
    if (!bucket) {
      const result = await fetchBucket({ variables: { bucketId } })
      if (result.data?.bucket) {
        bucket = result.data.bucket
      }
    }

    // prevents the user from accessing a bucket they don't have access to
    if (!bucket || bucket.isBanned || bucket.bucketStatus === BucketStatus.Deleted) {
      rg4js('trackEvent', {
        type: 'redirect',
        path: `buckets`
      })
      window.location.href = '/buckets'
      return
    }

    // redirects user to legacy url
    // TODO: delete this when legacy architecture is deprecated
    if (bucket?.architecture !== Architecture.Ddb) {
      rg4js('trackEvent', {
        type: 'redirect',
        path: `/u/${getAuth().currentUser?.uid}`
      })
      window.location.href = `/u/${getAuth().currentUser?.uid}`
      return
    }

    // redirects user to new architecture
    rg4js('trackEvent', {
      type: 'redirect',
      path: `/bucket/${bucketId}`
    })
    window.location.href = `/bucket/${bucketId}`
  }

  return { redirectToBucket }
}
