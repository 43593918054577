import { useReactiveVar } from '@apollo/client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import MenuIcon from '@mui/icons-material/Menu'
import { Box, Link } from '@mui/material'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useMatch, useNavigate } from 'react-router-dom'
import { drawerVar } from '../../../../providers/apollo/cache'
import LogoSvg from '../../../../svg/Logo'
import useBucket from '../../hooks/useBucket'

function Logo() {
  const theme = useTheme()
  const { bucket } = useBucket()
  const isInvite = useMatch('/invite/:inviteId')
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const color = theme.palette.mode === 'dark' ? 'white' : undefined
  const width = isDesktop ? '160px' : '100px'
  const url = bucket?.id ? `/bucket/${bucket?.id}` : '/'

  // Without link
  if (isInvite) {
    return (
      <Box aria-label="Photobucket Logo" id="appbar-logo" data-test="appbar-logo" sx={{ alignSelf: 'center' }}>
        <LogoSvg width={width} color={color} />
      </Box>
    )
  }

  // With link
  return (
    <Link aria-label="Photobucket Homepage" href={url} id="appbar-logo" data-test="appbar-logo" sx={{ alignSelf: 'center' }}>
      <LogoSvg width={width} color={color} />
    </Link>
  )
}

interface Props {
  displayDrawerToggle?: boolean
}
export default function LogoMenu({ displayDrawerToggle }: Props) {
  const theme = useTheme()
  const navigate = useNavigate()
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'))
  const isEditor = useMatch('/bucket/:bucketId/media/:mediaId/editor')
  const drawer = useReactiveVar(drawerVar)
  const isDrawerOpen = drawer === 'main' || isEditor

  // All the buttons depend on the number of medias selected rather than the actual data of the media
  // Therefore we will do one operation here to convert to an array rather than perform the same operation in every button

  const handleClick = () => {
    drawerVar(isDrawerOpen ? null : 'main')
    if (isEditor) {
      navigate(-1)
    }
  }

  if (!displayDrawerToggle) {
    return <Logo />
  }

  return (
    <>
      <Hidden lgUp>
        <IconButton
          color="inherit"
          onClick={handleClick}
          id="appbar-drawer-toggle"
          disableRipple
          disableTouchRipple
          aria-label={isDrawerOpen ? 'Close Menu' : 'Open Menu'}
          data-test="appbar-drawer-toggle"
          size="large"
        >
          {!isDrawerOpen && <MenuIcon />}
          {isDrawerOpen && <ArrowBackIcon />}
        </IconButton>
      </Hidden>
      {!isMobile && <Logo />}
    </>
  )
}

LogoMenu.defaultProps = {
  displayDrawerToggle: true
}
