import { useQuery } from '@apollo/client'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme
} from '@mui/material'
import { getAuth } from 'firebase/auth'
import { omit } from 'lodash'
import { ChangeEvent, useState } from 'react'
import Loader from '../../../../components/Loader'
import { Gender, UsageReasonInput, UserProfileDocument, UserProfileFragmentFragmentDoc } from '../../../../graphql/generated'
import { countries } from '../../../account/views/settings/utils/countries'
import useUserProfileUpdate from '../../hooks/useUserProfileUpdate'
import cache from '../../../../providers/apollo/cache'

interface Fields {
  firstName: string | null
  lastName: string | null
  country: string | null
  state: string | null
  zipCode: string | null
}

type Field = keyof Fields

interface Errors extends Fields {
  general?: string | null
  gender?: string | null
}

export default function Profile() {
  const theme = useTheme()
  const userId = getAuth().currentUser?.uid
  const profile = cache.readFragment({ fragment: UserProfileFragmentFragmentDoc, id: `UserProfile:${userId}` })
  const [usageReason, setUsageReason] = useState<UsageReasonInput>({})
  const [gender, setGender] = useState<Gender | undefined | null>(undefined)
  const [fields, setFields] = useState<Partial<Fields>>({})
  const [errors, setErrors] = useState<Partial<Errors>>({})
  const results = useQuery(UserProfileDocument, {
    onCompleted: (res) => {
      const { firstName, lastName, gender: genderRes, country, state, usageReason: usageReasonRes, zipCode } = res.userProfile ?? {}
      setGender(genderRes)
      setFields({
        firstName,
        lastName,
        country,
        state,
        zipCode
      })
      setUsageReason(omit(usageReasonRes, '__typename'))
    }
  })
  const { mutation, loading } = useUserProfileUpdate()
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedFields = { ...fields }
    const field = event.target.name as Field
    updatedFields[field] = event.target.value
    setFields(updatedFields)

    const updatedErrors = { ...errors }
    delete updatedErrors[field]
    delete updatedErrors.general
    setErrors(updatedErrors)
  }
  const handleUpdateFields = (name: string, value: string) => {
    const updatedFields = { ...fields }
    const field = name as Field
    updatedFields[field] = value
    setFields(updatedFields)

    const updatedErrors = { ...errors }
    delete updatedErrors[field]
    delete updatedErrors.general
    setErrors(updatedErrors)
  }
  const handleSelection = (event: SelectChangeEvent) => {
    const value = event.target.value as Gender
    if (![Gender.Male, Gender.Female].includes(value)) {
      return
    }
    setGender(value)
    if (errors.gender) {
      setErrors({ ...errors, gender: null })
    }
  }
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const variables = {
      data: {
        ...fields,
        gender,
        usageReason
      }
    }
    mutation({ variables })
  }
  const handleSelectChange = (event: SelectChangeEvent) => {
    handleUpdateFields(event.target.name, event.target.value)
  }
  const handleCheckChange = (reason: keyof UsageReasonInput) => {
    const currentValue = usageReason[reason]
    return () => setUsageReason({ ...usageReason, [reason]: !currentValue })
  }
  if (!results.called || results.loading) {
    return <Loader />
  }
  return (
    <form onSubmit={handleSubmit}>
      <Typography>Name</Typography>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {profile?.name}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <TextField
            disabled={loading}
            fullWidth
            id="profile-first-name"
            name="firstName"
            label="First Name"
            value={fields.firstName ?? ''}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <TextField
            disabled={loading}
            fullWidth
            id="profile-last-name"
            name="lastName"
            label="Last Name"
            value={fields.lastName ?? ''}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={5}>
          <FormControl fullWidth>
            <InputLabel id="location-label">Location</InputLabel>
            <Select
              disabled={loading}
              labelId="location-label"
              label="Location"
              value={fields?.country ?? ''}
              name="country"
              onChange={handleSelectChange}
            >
              {countries.map((country) => (
                <MenuItem key={country.value} value={country.value}>
                  {country.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled={loading}
            fullWidth
            id="profile-state"
            name="state"
            label="State/Province"
            value={fields.state ?? ''}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <TextField
            disabled={loading}
            fullWidth
            id="profile-zip-code"
            name="zipCode"
            label="Zip Code"
            value={fields.zipCode ?? ''}
            variant="outlined"
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }} spacing={2}>
        <Grid item xs={12} md={3}>
          <FormControl fullWidth sx={{ minWidth: theme.spacing(15) }}>
            <InputLabel id="profile-gender-label">Gender</InputLabel>
            <Select
              disabled={loading}
              labelId="profile-gender-label"
              name="gender"
              id="profile-gender"
              placeholder="Not selected"
              value={gender ?? ''}
              label="Gender"
              onChange={handleSelection}
            >
              <MenuItem value={Gender.Male}>Male</MenuItem>
              <MenuItem value={Gender.Female}>Female</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid sx={{ mt: 2 }} container spacing={2}>
        <Grid item xs={12} md={5}>
          <FormGroup id="usage-reason-checkboxes">
            <FormLabel component="legend">Reason for using Photobucket</FormLabel>
            <FormControlLabel
              onChange={handleCheckChange('personal')}
              control={<Checkbox disabled={loading} checked={usageReason.personal === true} />}
              label="Personal"
            />
            <FormControlLabel
              onChange={handleCheckChange('business')}
              control={<Checkbox disabled={loading} checked={usageReason.business === true} />}
              label="Business"
            />
            <FormControlLabel
              onChange={handleCheckChange('storage')}
              control={<Checkbox disabled={loading} checked={usageReason.storage === true} />}
              label="Storage"
            />
            <FormControlLabel
              onChange={handleCheckChange('hosting')}
              control={<Checkbox disabled={loading} checked={usageReason.hosting === true} />}
              label="Hosting"
            />
            <FormControlLabel
              onChange={handleCheckChange('sharing')}
              control={<Checkbox disabled={loading} checked={usageReason.sharing === true} />}
              label="Share Images with Family/Friends"
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Button sx={{ mt: 2 }} variant="contained" disabled={loading} type="submit">
        Submit
      </Button>
    </form>
  )
}
