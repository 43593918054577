import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  BigInt: any
}

export type AccessLevel = Private | Public | Restricted

export enum AccessLevelEnum {
  Anybody = 'ANYBODY',
  NoAccess = 'NO_ACCESS',
  Restricted = 'RESTRICTED'
}

export type AddTagsResult = {
  __typename?: 'AddTagsResult'
  blacklisted: Array<Maybe<Scalars['String']>>
  imageId: Scalars['String']
}

export type Album = {
  __typename?: 'Album'
  albumCoverId?: Maybe<Scalars['String']>
  /** @deprecated Use id */
  albumId: Scalars['ID']
  albums: Array<Maybe<Album>>
  creationDate: Scalars['String']
  description?: Maybe<Scalars['String']>
  directLink: Scalars['String']
  id: Scalars['ID']
  imageCount: Scalars['Int']
  images: Array<Maybe<Image>>
  nestedAlbumsCount: Scalars['Int']
  parentAlbumId?: Maybe<Scalars['String']>
  privacyMode: AlbumPrivacyMode
  sorting?: Maybe<Sorting>
  title?: Maybe<Scalars['String']>
  totalUserImageCount: Scalars['Int']
  totalUserUsedSpace: Scalars['Float']
  username: Scalars['String']
}

export type AlbumAlbumsArgs = {
  pageSize?: InputMaybe<Scalars['Int']>
}

export type AlbumImagesArgs = {
  pageSize?: InputMaybe<Scalars['Int']>
}

export type AlbumCounters = {
  __typename?: 'AlbumCounters'
  imageCount: Scalars['Int']
  imageCountIncludeSubAlbums: Scalars['Int']
  nestedAlbumsCount: Scalars['Int']
  totalUserImageCount: Scalars['Int']
  totalUserUsedSpace: Scalars['Float']
  usedSpace: Scalars['Float']
}

export type AlbumDetails = {
  __typename?: 'AlbumDetails'
  albumCoverId?: Maybe<Scalars['String']>
  /** @deprecated Use id */
  albumId: Scalars['ID']
  creationDate: Scalars['String']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  imageCount: Scalars['Int']
  imageCountIncludeSubAlbums?: Maybe<Scalars['Int']>
  nestedAlbumsCount: Scalars['Int']
  parentAlbumId?: Maybe<Scalars['String']>
  privacyMode: AlbumPrivacyMode
  sorting?: Maybe<Sorting>
  title?: Maybe<Scalars['String']>
  totalUserImageCount: Scalars['Int']
  totalUserUsedSpace: Scalars['Float']
  usedSpace: Scalars['Float']
  username: Scalars['String']
}

export type AlbumForEditor = {
  __typename?: 'AlbumForEditor'
  /** @deprecated Use id */
  albumId: Scalars['ID']
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  owner: Scalars['String']
  passwords: Array<Maybe<AlbumPassword>>
  privacy: AlbumPrivacyMode
  title?: Maybe<Scalars['String']>
}

export type AlbumLinks = {
  __typename?: 'AlbumLinks'
  sharing: Array<Maybe<SharingLink>>
}

export type AlbumPassword = {
  __typename?: 'AlbumPassword'
  id: Scalars['String']
  password: Scalars['String']
  title?: Maybe<Scalars['String']>
}

export type AlbumPasswordInput = {
  id: Scalars['String']
  password: Scalars['String']
  title?: InputMaybe<Scalars['String']>
}

export enum AlbumPrivacyMode {
  Deleted = 'DELETED',
  PasswordProtected = 'PASSWORD_PROTECTED',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type AlbumScrollPointers = {
  __typename?: 'AlbumScrollPointers'
  albums?: Maybe<Array<AlbumTreeItemV2>>
  scrollPointers?: Maybe<Array<ScrollPointer>>
}

export type AlbumTreeItem = {
  __typename?: 'AlbumTreeItem'
  /** @deprecated Use id */
  albumId: Scalars['ID']
  id: Scalars['ID']
  imageCount: Scalars['Int']
  parentAlbumId?: Maybe<Scalars['String']>
  privacyMode: AlbumPrivacyMode
  title?: Maybe<Scalars['String']>
}

export type AlbumTreeItemV2 = {
  __typename?: 'AlbumTreeItemV2'
  /** @deprecated Use id */
  albumId: Scalars['ID']
  hasNestedAlbums: Scalars['Boolean']
  id: Scalars['ID']
  imageCount: Scalars['Int']
  parentAlbumId?: Maybe<Scalars['String']>
  privacyMode: AlbumPrivacyMode
  title?: Maybe<Scalars['String']>
}

export type AlbumV2 = {
  __typename?: 'AlbumV2'
  albumCoverId?: Maybe<Scalars['String']>
  albums: Array<Maybe<AlbumV2>>
  counters: AlbumCounters
  creationDate: Scalars['String']
  description?: Maybe<Scalars['String']>
  directLink: Scalars['String']
  id: Scalars['ID']
  images: Array<Maybe<Image>>
  links: AlbumLinks
  owner: Scalars['String']
  parentAlbumId?: Maybe<Scalars['String']>
  passwords: Array<Maybe<AlbumPassword>>
  privacyMode: AlbumPrivacyMode
  sorting: AlbumV2Sorting
  title: Scalars['String']
}

export type AlbumV2AlbumsArgs = {
  pageSize: Scalars['Int']
}

export type AlbumV2ImagesArgs = {
  pageSize: Scalars['Int']
}

export type AlbumV2Sorting = Sorting | TrashSorter

export type AlbumsSettings = {
  __typename?: 'AlbumsSettings'
  /** Sort rule that should be used by default for image retrieving */
  defaultSortRule: Sorting
  easyLinkingMode: Scalars['Boolean']
  linkBackOption: Scalars['Boolean']
  links: MediaLinksSettings
  showImageTitle: Scalars['Boolean']
}

export type AlbumsSettingsInput = {
  defaultSortRule?: InputMaybe<Sorter>
  easyLinkingMode: Scalars['Boolean']
  linkBackOption: Scalars['Boolean']
  links: MediaLinksSettingsInput
  showImageTitle?: InputMaybe<Scalars['Boolean']>
}

export enum Architecture {
  Ddb = 'DDB',
  Kafka = 'KAFKA',
  Rds = 'RDS'
}

export type AuthenticatedUser = {
  __typename?: 'AuthenticatedUser'
  albumsSettings: AlbumsSettings
  autoBackupSettings: AutoBackupSettings
  birthDate?: Maybe<Scalars['String']>
  bucketConversion?: Maybe<BucketConversion>
  country?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  defaultAlbum: Scalars['String']
  email: Scalars['String']
  emailStatus: EmailStatus
  /** @deprecated Not in use */
  fbId?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isPasswordExpired: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  migratedAt?: Maybe<Scalars['String']>
  migrationNotified?: Maybe<Scalars['Boolean']>
  migrationStatus?: Maybe<UserMigrationStatus>
  mobileAlbum?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  sex?: Maybe<Sex>
  stateOrProvince?: Maybe<Scalars['String']>
  subscription: UserSubscription
  token: Scalars['String']
  tokenType: TokenType
  usageReasons: UsageReasons
  /** @deprecated Use id */
  username: Scalars['ID']
  zip?: Maybe<Scalars['String']>
}

export type AutoBackupInput = {
  backupPhotos: Scalars['Boolean']
  backupVideos: Scalars['Boolean']
  /** If specified, backup photos starting this data value, in seconds */
  photosTimeframe?: InputMaybe<Scalars['Int']>
  useCellular: Scalars['Boolean']
  /** If specified, backup videos starting this data value, in seconds */
  videosTimeframe?: InputMaybe<Scalars['Int']>
}

export type AutoBackupSettings = {
  __typename?: 'AutoBackupSettings'
  backupPhotos: Scalars['Boolean']
  backupVideos: Scalars['Boolean']
  photosTimeframe?: Maybe<Scalars['Int']>
  useCellular: Scalars['Boolean']
  videosTimeframe?: Maybe<Scalars['Int']>
}

export type BtIds = {
  __typename?: 'BTIds'
  di: Scalars['String']
  legacy: Scalars['String']
}

export type BatchS3UploadStatuses = {
  __typename?: 'BatchS3UploadStatuses'
  /** List of items found in S3 */
  found: Array<Maybe<S3Item>>
  /** List of md5 values that were not found in S3 */
  missing: Array<Maybe<Scalars['String']>>
}

export enum BillingAccount {
  BtDi = 'BT_DI',
  BtLegacy = 'BT_LEGACY',
  Itunes = 'ITUNES',
  Paddle = 'PADDLE',
  Pp = 'PP',
  Pp_2 = 'PP_2',
  PpMicro = 'PP_MICRO',
  Unknown = 'UNKNOWN',
  Vindicia = 'VINDICIA'
}

export type Bucket = {
  __typename?: 'Bucket'
  architecture?: Maybe<Architecture>
  bucketSettings?: Maybe<BucketSettings>
  bucketStatus?: Maybe<BucketStatus>
  bucketType: BucketType
  bucketUser?: Maybe<BucketUser>
  bucketUserCount?: Maybe<Scalars['Int']>
  counters?: Maybe<BucketCounters>
  defaultBucket?: Maybe<Scalars['Boolean']>
  defaultSortRule: BucketSortingField
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isAdmin?: Maybe<Scalars['Boolean']>
  isBanned?: Maybe<Scalars['Boolean']>
  isConverted?: Maybe<Scalars['Boolean']>
  ownerId?: Maybe<Scalars['String']>
  plan?: Maybe<Plan>
  subscriptionStatus?: Maybe<SubscriptionStatus>
  title: Scalars['String']
}

export type BucketActivitiesByDate = {
  __typename?: 'BucketActivitiesByDate'
  activities?: Maybe<Array<BucketActivity>>
  bucketId: Scalars['String']
  date: Scalars['String']
}

export type BucketActivitiesResults = {
  __typename?: 'BucketActivitiesResults'
  items: Array<BucketActivitiesByDate>
  nextToken?: Maybe<Scalars['String']>
}

export type BucketActivity = BucketJoinedMembersActivity | BucketUploadedMediaActivity

export type BucketActivityUser = {
  __typename?: 'BucketActivityUser'
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  userId: Scalars['ID']
}

export type BucketAlbum = {
  __typename?: 'BucketAlbum'
  bucketId: Scalars['ID']
  counters?: Maybe<BucketAlbumCounters>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  parentId?: Maybe<Scalars['ID']>
  subAlbumCount?: Maybe<Scalars['Int']>
  thumbnails?: Maybe<Array<Maybe<BucketMedia>>>
  title: Scalars['String']
}

export type BucketAlbumCounters = {
  __typename?: 'BucketAlbumCounters'
  totalMedia?: Maybe<Scalars['Int']>
  totalSubalbums?: Maybe<Scalars['Int']>
}

export type BucketAlbumInput = {
  description?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['ID']>
  parentId?: InputMaybe<Scalars['ID']>
  title?: InputMaybe<Scalars['String']>
}

export type BucketAlbumSorter = {
  field: BucketAlbumSortingField
  order: BucketSortingOrder
}

export enum BucketAlbumSortingField {
  Date = 'DATE',
  Title = 'TITLE'
}

export type BucketAlbumsMoveCopyBetweenBucketsInput = {
  action: BucketMediaMoveCopyBetweenBucketsAction
  destinationAlbumId?: InputMaybe<Scalars['ID']>
  destinationBucketId: Scalars['ID']
}

export type BucketAlbumsMoveInput = {
  deleteAlbum?: InputMaybe<Scalars['Boolean']>
  deleteMedia?: InputMaybe<Scalars['Boolean']>
  destinationAlbumId?: InputMaybe<Scalars['ID']>
  moveMediaToTrash?: InputMaybe<Scalars['Boolean']>
}

export type BucketAlbumsResults = {
  __typename?: 'BucketAlbumsResults'
  items: Array<BucketAlbum>
  nextToken?: Maybe<Scalars['String']>
}

export type BucketAuthToken = {
  __typename?: 'BucketAuthToken'
  token: Scalars['String']
  type: BucketAuthTokenType
}

export enum BucketAuthTokenType {
  CustomLoginToken = 'CUSTOM_LOGIN_TOKEN'
}

export type BucketAutoBackupSettings = {
  __typename?: 'BucketAutoBackupSettings'
  backupPhotos?: Maybe<Scalars['Boolean']>
  backupVideos?: Maybe<Scalars['Boolean']>
  bucketId?: Maybe<Scalars['String']>
  photosTimeframe?: Maybe<Scalars['Int']>
  useCellular?: Maybe<Scalars['Boolean']>
  videosTimeframe?: Maybe<Scalars['Int']>
}

export type BucketAutoBackupSettingsInput = {
  backupPhotos?: InputMaybe<Scalars['Boolean']>
  backupVideos?: InputMaybe<Scalars['Boolean']>
  bucketId?: InputMaybe<Scalars['String']>
  photosTimeframe?: InputMaybe<Scalars['Int']>
  useCellular?: InputMaybe<Scalars['Boolean']>
  videosTimeframe?: InputMaybe<Scalars['Int']>
}

export type BucketBulkDownload = {
  __typename?: 'BucketBulkDownload'
  archives?: Maybe<Array<DownloadLink>>
  createdAt: Scalars['String']
  id: Scalars['ID']
  scheduledDeletionAt?: Maybe<Scalars['String']>
  userId: Scalars['ID']
}

export type BucketBulkDownloadResults = {
  __typename?: 'BucketBulkDownloadResults'
  items?: Maybe<Array<BucketBulkDownload>>
  nextToken?: Maybe<Scalars['String']>
}

export type BucketConversion = {
  __typename?: 'BucketConversion'
  completedAt?: Maybe<Scalars['String']>
  conversionType: BucketConversionType
  startedAt: Scalars['String']
  status: BucketConversionStatus
}

export enum BucketConversionStatus {
  Completed = 'COMPLETED',
  Started = 'STARTED'
}

export enum BucketConversionType {
  MyBucketToGroupBucket = 'MY_BUCKET_TO_GROUP_BUCKET',
  MyBucketToMyBucket = 'MY_BUCKET_TO_MY_BUCKET'
}

export type BucketCounters = {
  __typename?: 'BucketCounters'
  totalAlbumCount?: Maybe<Scalars['Int']>
  totalMedia?: Maybe<Scalars['Int']>
  totalMediaInTrash?: Maybe<Scalars['Int']>
  totalMediaMinusTrash?: Maybe<Scalars['Int']>
  totalSize?: Maybe<Scalars['Float']>
  totalSizeInTrash?: Maybe<Scalars['Float']>
  totalSizeMinusTrash?: Maybe<Scalars['Float']>
}

export type BucketCredentials = {
  __typename?: 'BucketCredentials'
  accessKeyId: Scalars['String']
  secretAccessKey: Scalars['String']
  sessionToken: Scalars['String']
}

export enum BucketGalleryGridLayout {
  Grid = 'GRID'
}

export type BucketGallerySettings = {
  __typename?: 'BucketGallerySettings'
  cropMode?: Maybe<Scalars['Boolean']>
  filterBy?: Maybe<BucketUserFilterBy>
  flatten?: Maybe<Scalars['Boolean']>
  groupedBy?: Maybe<BucketMediaGroupedBy>
  layout?: Maybe<BucketGalleryGridLayout>
  showImageTitle?: Maybe<Scalars['Boolean']>
  sortBy?: Maybe<BucketUserSortBy>
  zoom?: Maybe<Scalars['Int']>
}

export type BucketGallerySettingsInput = {
  cropMode?: InputMaybe<Scalars['Boolean']>
  filterBy?: InputMaybe<BucketUserFilterByInput>
  flatten?: InputMaybe<Scalars['Boolean']>
  groupedBy?: InputMaybe<BucketMediaGroupedBy>
  layout?: InputMaybe<BucketGalleryGridLayout>
  showImageTitle?: InputMaybe<Scalars['Boolean']>
  sortBy?: InputMaybe<BucketUserSortByInput>
  zoom?: InputMaybe<Scalars['Int']>
}

export type BucketGenerateDownloadLinks = {
  __typename?: 'BucketGenerateDownloadLinks'
  requestId: Scalars['ID']
  requestedAt: Scalars['String']
}

export type BucketInput = {
  bucketSettings?: InputMaybe<BucketSettingsInput>
  bucketType?: InputMaybe<BucketType>
  description?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['ID']>
}

export type BucketInvitation = {
  __typename?: 'BucketInvitation'
  bucketId: Scalars['ID']
  bucketTitle?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  invitationStatus?: Maybe<BucketInvitationStatus>
  updatedAt?: Maybe<Scalars['String']>
  userRole: BucketUserRole
}

export type BucketInvitationDeleted = {
  __typename?: 'BucketInvitationDeleted'
  id: Scalars['ID']
  status: BucketInvitationStatus
}

export enum BucketInvitationRestriction {
  Open = 'OPEN',
  Restricted = 'RESTRICTED'
}

export enum BucketInvitationStatus {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type BucketInvitationsResults = {
  __typename?: 'BucketInvitationsResults'
  items: Array<BucketInvitation>
  nextToken?: Maybe<Scalars['String']>
}

export type BucketJoinedMembersActivity = {
  __typename?: 'BucketJoinedMembersActivity'
  timestamp: Scalars['String']
  user: BucketActivityUser
}

export type BucketMedia = {
  __typename?: 'BucketMedia'
  aiTags?: Maybe<Array<Scalars['String']>>
  albumId?: Maybe<Scalars['String']>
  bucketId: Scalars['String']
  createdAt?: Maybe<Scalars['String']>
  dateTaken?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  fileSize?: Maybe<Scalars['Float']>
  filename: Scalars['String']
  height?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  imageUrl?: Maybe<Scalars['String']>
  isBanned?: Maybe<Scalars['Boolean']>
  isFavorite?: Maybe<Scalars['Boolean']>
  isMobile?: Maybe<Scalars['Boolean']>
  isVideo?: Maybe<Scalars['Boolean']>
  livePhoto?: Maybe<BucketMediaLivePhoto>
  mediaType: Scalars['String']
  moderationStatus?: Maybe<BucketMediaModerationStatus>
  originalFilename?: Maybe<Scalars['String']>
  scheduledDeletionAt?: Maybe<Scalars['String']>
  signedUrl?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
  userTags?: Maybe<Array<Scalars['String']>>
  width?: Maybe<Scalars['Int']>
}

export enum BucketMediaContentOwner {
  Me = 'ME',
  Others = 'OTHERS'
}

export type BucketMediaFilter = {
  isFavorite?: InputMaybe<Scalars['Boolean']>
  mediaType?: InputMaybe<BucketMediaTypeFilter>
  owner?: InputMaybe<BucketMediaContentOwner>
  source?: InputMaybe<BucketMediaSourceFilter>
  status?: InputMaybe<BucketMediaStatus>
}

export enum BucketMediaGroupedBy {
  AllPhotos = 'ALL_PHOTOS',
  Day = 'DAY',
  Month = 'MONTH',
  Year = 'YEAR'
}

export type BucketMediaInput = {
  albumId?: InputMaybe<Scalars['String']>
  dateTaken?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  fileSize?: InputMaybe<Scalars['Float']>
  height?: InputMaybe<Scalars['Int']>
  livePhoto?: InputMaybe<BucketMediaLivePhotoInput>
  mediaType?: InputMaybe<Scalars['String']>
  originalFilename?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  userTags?: InputMaybe<Array<Scalars['String']>>
  width?: InputMaybe<Scalars['Int']>
}

export type BucketMediaLivePhoto = {
  __typename?: 'BucketMediaLivePhoto'
  fileSize?: Maybe<Scalars['Float']>
  filename?: Maybe<Scalars['String']>
}

export type BucketMediaLivePhotoInput = {
  fileSize: Scalars['Float']
  filename: Scalars['String']
}

export enum BucketMediaModerationStatus {
  Approved = 'APPROVED',
  Awaiting = 'AWAITING',
  Copyrightviolation = 'COPYRIGHTVIOLATION',
  Pbviolation = 'PBVIOLATION'
}

export enum BucketMediaMoveCopyBetweenBucketsAction {
  Copy = 'COPY',
  Move = 'MOVE'
}

export type BucketMediaMoveCopyBetweenBucketsInput = {
  action: BucketMediaMoveCopyBetweenBucketsAction
  destinationAlbumId?: InputMaybe<Scalars['ID']>
  destinationBucketId: Scalars['ID']
}

export type BucketMediaMoveInput = {
  deleteMedia?: InputMaybe<Scalars['Boolean']>
  destinationAlbumId?: InputMaybe<Scalars['ID']>
  moveMediaToTrash?: InputMaybe<Scalars['Boolean']>
}

export type BucketMediaResults = {
  __typename?: 'BucketMediaResults'
  items: Array<BucketMedia>
  nextToken?: Maybe<Scalars['String']>
}

export type BucketMediaSorter = {
  field: BucketSortingField
  order: BucketSortingOrder
}

export enum BucketMediaSourceFilter {
  Mobile = 'MOBILE',
  Web = 'WEB'
}

export enum BucketMediaStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Trash = 'TRASH'
}

export enum BucketMediaTypeFilter {
  Image = 'IMAGE',
  Video = 'VIDEO'
}

export type BucketMediaUnassignedCounters = {
  __typename?: 'BucketMediaUnassignedCounters'
  mediaCount?: Maybe<Scalars['Int']>
}

export type BucketMediaUpdateInput = {
  description?: InputMaybe<Scalars['String']>
  isFavorite?: InputMaybe<Scalars['Boolean']>
  livePhoto?: InputMaybe<BucketMediaLivePhotoInput>
  title?: InputMaybe<Scalars['String']>
  userTags?: InputMaybe<Array<Scalars['String']>>
}

export type BucketSettings = {
  __typename?: 'BucketSettings'
  easyLinkingMode?: Maybe<Scalars['Boolean']>
  linkBackToAlbums?: Maybe<Scalars['Boolean']>
  shareOptions?: Maybe<ShareOptions>
  showImageTitle?: Maybe<Scalars['Boolean']>
}

export type BucketSettingsInput = {
  easyLinkingMode?: InputMaybe<Scalars['Boolean']>
  linkBackToAlbums?: InputMaybe<Scalars['Boolean']>
  shareOptions?: InputMaybe<ShareOptionsInput>
  showImageTitle?: InputMaybe<Scalars['Boolean']>
}

export type BucketShare = {
  __typename?: 'BucketShare'
  albumId?: Maybe<Scalars['String']>
  bucketId: Scalars['ID']
  createdAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  mediaIds?: Maybe<Array<Scalars['String']>>
  password?: Maybe<Scalars['String']>
  passwordProtected?: Maybe<Scalars['Boolean']>
  scheduledDeletionAt?: Maybe<Scalars['String']>
  shareStatus: BucketShareStatus
  shareType?: Maybe<BucketShareType>
  thumbnails?: Maybe<Array<BucketMedia>>
  title?: Maybe<Scalars['String']>
  totalMedia?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type BucketShareCreateInput = {
  albumId?: InputMaybe<Scalars['String']>
  mediaIds?: InputMaybe<Array<Scalars['String']>>
  password?: InputMaybe<Scalars['String']>
  scheduledDeletionAt?: InputMaybe<Scalars['String']>
  shareType?: InputMaybe<BucketShareType>
  title?: InputMaybe<Scalars['String']>
}

export enum BucketShareStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Expired = 'EXPIRED'
}

export enum BucketShareType {
  Album = 'ALBUM',
  Media = 'MEDIA'
}

export type BucketSharesResults = {
  __typename?: 'BucketSharesResults'
  items: Array<BucketShare>
  nextToken?: Maybe<Scalars['String']>
}

export enum BucketSortingField {
  Date = 'DATE',
  DateTaken = 'DATE_TAKEN',
  Title = 'TITLE'
}

export enum BucketSortingOrder {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum BucketStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Dormant = 'DORMANT',
  MigrationInProgress = 'MIGRATION_IN_PROGRESS',
  MigrationRequested = 'MIGRATION_REQUESTED'
}

export type BucketTransfer = {
  __typename?: 'BucketTransfer'
  bucketId: Scalars['ID']
  createdAt?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  transferStatus: BucketTransferStatus
  updatedAt?: Maybe<Scalars['String']>
}

export enum BucketTransferStatus {
  Accepted = 'ACCEPTED',
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export enum BucketType {
  Group = 'GROUP',
  Mybucket = 'MYBUCKET'
}

export type BucketUploadedMediaActivity = {
  __typename?: 'BucketUploadedMediaActivity'
  albums: Array<BucketAlbum>
  count: Scalars['Int']
  media: Array<BucketMedia>
  timestamp: Scalars['String']
  user: BucketActivityUser
  window: TimeWindow
}

export type BucketUser = {
  __typename?: 'BucketUser'
  bucketId: Scalars['ID']
  id: Scalars['ID']
  permissions?: Maybe<PermissionPolicy>
  updated?: Maybe<Scalars['String']>
  userProfile?: Maybe<UserProfile>
  userRole: BucketUserRole
  userStatus: BucketUserStatus
}

export type BucketUserFilterBy = {
  __typename?: 'BucketUserFilterBy'
  isFavorite?: Maybe<Scalars['Boolean']>
  mediaType?: Maybe<BucketMediaTypeFilter>
  owner?: Maybe<BucketMediaContentOwner>
  sharedByOthers?: Maybe<Scalars['Boolean']>
  source?: Maybe<BucketMediaSourceFilter>
}

export type BucketUserFilterByInput = {
  isFavorite?: InputMaybe<Scalars['Boolean']>
  mediaType?: InputMaybe<BucketMediaTypeFilter>
  owner?: InputMaybe<BucketMediaContentOwner>
  sharedByOthers?: InputMaybe<Scalars['Boolean']>
  source?: InputMaybe<BucketMediaSourceFilter>
}

export type BucketUserInput = {
  userRole?: InputMaybe<BucketUserRole>
  userStatus?: InputMaybe<BucketUserStatus>
}

export enum BucketUserRole {
  Admin = 'ADMIN',
  Contributor = 'CONTRIBUTOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER'
}

export type BucketUserSortBy = {
  __typename?: 'BucketUserSortBy'
  field?: Maybe<BucketSortingField>
  sortingOrder?: Maybe<BucketSortingOrder>
}

export type BucketUserSortByInput = {
  field?: InputMaybe<BucketSortingField>
  sortingOrder?: InputMaybe<BucketSortingOrder>
}

export enum BucketUserStatus {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Deactivated = 'DEACTIVATED',
  Deleted = 'DELETED',
  Invited = 'INVITED'
}

export type BucketUsersResults = {
  __typename?: 'BucketUsersResults'
  items: Array<BucketUser>
  nextToken?: Maybe<Scalars['String']>
}

export type BucketsByUserIdResults = {
  __typename?: 'BucketsByUserIdResults'
  items: Array<Bucket>
  nextToken?: Maybe<Scalars['String']>
}

export enum BulkDownloadStatus {
  Active = 'ACTIVE',
  Requested = 'REQUESTED'
}

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export enum CaptchaSource {
  Hcaptcha = 'HCAPTCHA',
  Recaptcha = 'RECAPTCHA',
  RecaptchaAndroid = 'RECAPTCHA_ANDROID',
  RecaptchaEnterprise = 'RECAPTCHA_ENTERPRISE'
}

export type CustomPlanProperties = {
  __typename?: 'CustomPlanProperties'
  hostingIncluded?: Maybe<Scalars['Boolean']>
  mediaLimitCount?: Maybe<Scalars['Int']>
  planName?: Maybe<Scalars['String']>
  planTitle?: Maybe<Scalars['String']>
  price?: Maybe<Scalars['Float']>
  storageLimitBytes?: Maybe<Scalars['Float']>
}

export type CustomToken = {
  __typename?: 'CustomToken'
  customToken?: Maybe<Scalars['String']>
}

export type Dtp = {
  __typename?: 'DTP'
  createdAt: Scalars['String']
  id: Scalars['ID']
  requests: DtpRequests
  updatedAt?: Maybe<Scalars['String']>
  userId: Scalars['ID']
}

export type DtpRequest = {
  __typename?: 'DTPRequest'
  bucketId: Scalars['ID']
  createdAt: Scalars['String']
  requester: DtpRequester
  updatedAt?: Maybe<Scalars['String']>
}

export enum DtpRequester {
  Capture = 'capture',
  Facebook = 'facebook',
  Print = 'print'
}

export type DtpRequests = {
  __typename?: 'DTPRequests'
  capture?: Maybe<DtpRequest>
  facebook?: Maybe<DtpRequest>
  print?: Maybe<DtpRequest>
}

export enum DateSortingField {
  Date = 'DATE',
  DateTaken = 'DATE_TAKEN'
}

export type DeletionState = {
  __typename?: 'DeletionState'
  deletedAt: Scalars['String']
}

export type DownloadLink = {
  __typename?: 'DownloadLink'
  size: Scalars['Float']
  totalMediaCount: Scalars['Int']
  url: Scalars['String']
}

export enum EmailStatus {
  Invalid = 'INVALID',
  Valid = 'VALID'
}

export type Exif = {
  __typename?: 'Exif'
  altitude?: Maybe<Scalars['String']>
  altitudeRef?: Maybe<Scalars['String']>
  cameraBrand?: Maybe<Scalars['String']>
  cameraModel?: Maybe<Scalars['String']>
  eastOrWestLongitude?: Maybe<Scalars['String']>
  latitude?: Maybe<Scalars['String']>
  longitude?: Maybe<Scalars['String']>
  northOrSouthLatitude?: Maybe<Scalars['String']>
  orientation?: Maybe<OrientationType>
}

export type FacebookTransferResults = {
  __typename?: 'FacebookTransferResults'
  bucketId: Scalars['ID']
  createdAt?: Maybe<Scalars['String']>
  id: Scalars['ID']
  userId: Scalars['String']
}

export type ForeignUser = {
  __typename?: 'ForeignUser'
  defaultAlbum?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** @deprecated Use id */
  username: Scalars['ID']
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type HostedImage = {
  __typename?: 'HostedImage'
  bandwidth: Scalars['BigInt']
  id: Scalars['ID']
  /** @deprecated Use id */
  imageId: Scalars['ID']
  lastView: Scalars['String']
  originalImage: ImageFile
  views: Scalars['Int']
}

export type HostedImageResult = {
  __typename?: 'HostedImageResult'
  images: Array<Maybe<HostedImage>>
  scrollPointer?: Maybe<Scalars['String']>
}

export type HostingLink = {
  __typename?: 'HostingLink'
  /** Unique short uuid + file resolution. Example: dMwPGy8poaMb3E4C2gYDva.jpg. This value also is a part of DynamoDB table */
  id: Scalars['ID']
  /** Link to the file that can be shared on external resources */
  link: Scalars['String']
  /** Indicates whether this link can be resolved */
  linkStatus: LinkStatus
  /** Link type. EMAIL links are not included to the hosting calculations */
  linkType: HostingLinkType
}

export enum HostingLinkType {
  /** These links should be shared only via email. We do not count them on hosting calculations */
  Email = 'EMAIL',
  /** Any other type, included into the hosting */
  Other = 'OTHER'
}

export type HostingThreshold = {
  __typename?: 'HostingThreshold'
  maxHostingThreshold: Scalars['Int']
  percentageOfCapacity: Scalars['Float']
}

export type Image = {
  __typename?: 'Image'
  albumId: Scalars['String']
  albumName: Scalars['String']
  attributes?: Maybe<Array<Maybe<Scalars['String']>>>
  /** @deprecated Clarifai was disabled */
  clarifaiTags: Array<Maybe<Scalars['String']>>
  comments: MediaCommentsPaginable
  dateTaken?: Maybe<Scalars['String']>
  deletionState?: Maybe<DeletionState>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** @deprecated Use originalImage */
  image: ImageFile
  /** @deprecated Use id */
  imageId: Scalars['ID']
  isBlurred: Scalars['Boolean']
  isMobileUpload: Scalars['Boolean']
  isVideoType: Scalars['Boolean']
  /** Hosting and sharing links data. If link was expired or deactivated, it will not be provided back */
  links: Links
  livePhoto?: Maybe<ImageFile>
  nsfw?: Maybe<Scalars['Boolean']>
  originalFilename: Scalars['String']
  originalImage: ImageFile
  /** @deprecated As we switched to CDN */
  postProcess?: Maybe<ImageProcessStatus>
  status: Array<Maybe<MediaStatus>>
  /** @deprecated Use originalImage */
  thumbnailImage: ImageFile
  title: Scalars['String']
  uploadDate: Scalars['String']
  userTags: Array<Maybe<Scalars['String']>>
  username: Scalars['String']
}

export type ImageCommentsArgs = {
  desc: Scalars['Boolean']
  pageSize: Scalars['Int']
}

export type ImageFile = {
  __typename?: 'ImageFile'
  exif?: Maybe<Exif>
  height: Scalars['Int']
  isLandscape?: Maybe<Scalars['Boolean']>
  size: Scalars['Float']
  url: Scalars['String']
  width: Scalars['Int']
}

export enum ImageProcessStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Need = 'NEED'
}

export type ImageReferrer = {
  __typename?: 'ImageReferrer'
  bandwidth: Scalars['BigInt']
  lastView: Scalars['String']
  referrer: Scalars['String']
  views: Scalars['Int']
}

export type ImageScrollPointer = {
  __typename?: 'ImageScrollPointer'
  items: Array<Maybe<Image>>
  scrollPointer?: Maybe<Scalars['String']>
}

export type ImageSearchResult = {
  __typename?: 'ImageSearchResult'
  hasMore: Scalars['Boolean']
  result?: Maybe<Array<Maybe<Image>>>
  total?: Maybe<Scalars['Int']>
}

export type ImagesByAttributesSearchResult = {
  __typename?: 'ImagesByAttributesSearchResult'
  items?: Maybe<Array<Image>>
  scrollPointer?: Maybe<Scalars['String']>
  total: Scalars['Int']
}

export type ImagesBySharingIdResult = {
  __typename?: 'ImagesBySharingIdResult'
  items?: Maybe<Array<SharedImage>>
  scrollPointer?: Maybe<Scalars['String']>
  sharingType: SharingType
}

export type LegacyUser = {
  __typename?: 'LegacyUser'
  email: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isTrial: Scalars['Boolean']
  lastName?: Maybe<Scalars['String']>
  /** @deprecated Use id */
  username: Scalars['ID']
}

export type LinkSettings = {
  __typename?: 'LinkSettings'
  directLink?: Maybe<Scalars['Boolean']>
  htmlLarge?: Maybe<Scalars['Boolean']>
  htmlThumb?: Maybe<Scalars['Boolean']>
  imgLarge?: Maybe<Scalars['Boolean']>
  imgThumb?: Maybe<Scalars['Boolean']>
}

export type LinkSettingsInput = {
  directLink?: InputMaybe<Scalars['Boolean']>
  htmlLarge?: InputMaybe<Scalars['Boolean']>
  htmlThumb?: InputMaybe<Scalars['Boolean']>
  imgLarge?: InputMaybe<Scalars['Boolean']>
  imgThumb?: InputMaybe<Scalars['Boolean']>
}

export enum LinkStatus {
  /** Link is active and media is accessible */
  Active = 'ACTIVE',
  /** Link is inactive if it was removed by user, or user plan is expired */
  Inactive = 'INACTIVE'
}

export type LinkTuple = {
  /** Unique id. For both sharing and hosting links it's short uuid. Example: s6gQzaNNC7Ku6nXzNbqve1 */
  hashId: Scalars['ID']
  /** Unique media id, provided on upload. Immutable */
  imageId: Scalars['ID']
}

export type Links = {
  __typename?: 'Links'
  /** Set of hosting links. These are not app links, the behavior for the end user is like direct link to the media. Hosting ids are stored in DynamoDB */
  hosting: Array<Maybe<HostingLink>>
  /** Set of sharing links. These are app links. Any image can be shared multiple times */
  sharing: Array<Maybe<SharingLink>>
}

export type Md5InputTuple = {
  /** Filename, to filter S3 bucket data based on this prefix */
  fileName: Scalars['String']
  /** ETag value to find */
  md5: Scalars['String']
}

export type MarketingPreference = {
  __typename?: 'MarketingPreference'
  optedIn?: Maybe<Scalars['Boolean']>
  updatedAt?: Maybe<Scalars['String']>
}

export type MarketingPreferenceInput = {
  optedIn?: InputMaybe<Scalars['Boolean']>
  updatedAt?: InputMaybe<Scalars['String']>
}

export type MediaComment = {
  __typename?: 'MediaComment'
  commenter: Scalars['String']
  content: Scalars['String']
  createdAt: Scalars['String']
  id: Scalars['ID']
  mediaId: Scalars['String']
}

export type MediaCommentsPaginable = {
  __typename?: 'MediaCommentsPaginable'
  comments?: Maybe<Array<MediaComment>>
  scrollPointer?: Maybe<Scalars['String']>
}

export type MediaLinksSettings = {
  __typename?: 'MediaLinksSettings'
  direct: Scalars['Boolean']
  email: Scalars['Boolean']
  htmlCode: Scalars['Boolean']
  htmlThumb: Scalars['Boolean']
  img: Scalars['Boolean']
  imgThumb: Scalars['Boolean']
}

export type MediaLinksSettingsInput = {
  direct: Scalars['Boolean']
  email: Scalars['Boolean']
  htmlCode: Scalars['Boolean']
  htmlThumb: Scalars['Boolean']
  img: Scalars['Boolean']
  imgThumb: Scalars['Boolean']
}

export type MediaPreview = {
  __typename?: 'MediaPreview'
  albumId: Scalars['ID']
  imageId: Scalars['ID']
  url: Scalars['String']
}

export enum MediaStatus {
  CommunityReport = 'COMMUNITY_REPORT',
  ForceNsfw = 'FORCE_NSFW',
  ForcePrivate = 'FORCE_PRIVATE',
  ReportedDmca = 'REPORTED_DMCA',
  ReportedNsfw = 'REPORTED_NSFW'
}

export type MigrationProgressStatisticType = {
  __typename?: 'MigrationProgressStatisticType'
  processedImagesCount?: Maybe<Scalars['Int']>
  status?: Maybe<UserMigrationStatus>
  totalImagesCount?: Maybe<Scalars['Int']>
}

export enum ModalNotificationType {
  ChangedToFreePlan = 'CHANGED_TO_FREE_PLAN',
  LiveMigrationCompleted = 'LIVE_MIGRATION_COMPLETED',
  LiveMigrationStarted = 'LIVE_MIGRATION_STARTED'
}

export type MonthViewData = {
  __typename?: 'MonthViewData'
  day: Scalars['Int']
  items: Array<BucketMedia>
  mediaCount: Scalars['Int']
}

export type MonthViewItem = {
  __typename?: 'MonthViewItem'
  localDate: Scalars['String']
  previewStats: Array<Maybe<PreviewStatsItem>>
  viewMoreStats: ViewMore
}

export type MonthViewResult = {
  __typename?: 'MonthViewResult'
  data: Array<Maybe<MonthViewData>>
  date: Scalars['String']
  totalMedia: Scalars['Int']
}

export type MonthsView = {
  __typename?: 'MonthsView'
  items: Array<Maybe<MonthViewItem>>
  scrollPointer?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addUserTags: AddTagsResult
  albumCreate: AlbumV2
  albumUpdate: AlbumV2
  /** Create new shared item */
  albumsAddSharingLink: Array<AlbumV2>
  /** Deactivate sharing link by sharing id. Images will not be longer accessible by sharing links, even for the image owner */
  albumsDeactivateSharingLink: Array<AlbumV2>
  albumsDelete: Array<Scalars['ID']>
  /** Duplicate sharing group - all albums with their images for provided hashId are going to be preselected for the new sharing link */
  albumsDuplicateSharingLink: Array<AlbumV2>
  albumsMoveToTrash: Array<Scalars['ID']>
  /** Update permissions for the specific user if link is restricted, password if public, or expiration date for any */
  albumsUpdateSharingLink: Array<AlbumV2>
  /** @deprecated Mocked, use imagesBan v2 mutation */
  banImages: Scalars['Boolean']
  bucketAlbumCreate?: Maybe<BucketAlbum>
  bucketAlbumUpdate?: Maybe<BucketAlbum>
  bucketAlbumsMove?: Maybe<Array<Scalars['ID']>>
  bucketAlbumsMoveCopyBetweenBuckets?: Maybe<Scalars['Boolean']>
  bucketBulkMediaUpdate?: Maybe<Array<Scalars['ID']>>
  bucketConvert: AuthenticatedUser
  bucketCreate?: Maybe<Bucket>
  bucketDowngrade?: Maybe<Scalars['Boolean']>
  bucketDtpRequesterAdd?: Maybe<Dtp>
  /** @deprecated use bucketDtpRequesterAdd instead */
  bucketFacebookTransferCreate?: Maybe<FacebookTransferResults>
  bucketInvitationAccept?: Maybe<BucketUser>
  bucketInvitationCreate?: Maybe<Array<BucketInvitation>>
  bucketInvitationResend?: Maybe<BucketInvitation>
  bucketInvitationReset?: Maybe<Array<BucketInvitationDeleted>>
  bucketInvitationUpdate?: Maybe<BucketInvitation>
  bucketLoginSuccess?: Maybe<UserProfile>
  bucketMediaCreate?: Maybe<BucketMedia>
  bucketMediaMove?: Maybe<Array<Scalars['ID']>>
  bucketMediaMoveCopyBetweenBuckets?: Maybe<Scalars['Boolean']>
  bucketMediaUpdate?: Maybe<BucketMedia>
  bucketRegister: BucketAuthToken
  bucketShareCreate?: Maybe<BucketShare>
  bucketSharePasswordUpdate?: Maybe<BucketShare>
  bucketSharePasswordValidate: Scalars['Boolean']
  bucketShareUpdate?: Maybe<BucketShare>
  bucketTransferOwnerAccept?: Maybe<Bucket>
  bucketTransferOwnerCreate?: Maybe<BucketTransfer>
  bucketTrashEmpty?: Maybe<Scalars['Boolean']>
  bucketUpdate?: Maybe<Bucket>
  bucketUserUpdate?: Maybe<BucketUser>
  bucketViewsCreate?: Maybe<Scalars['Boolean']>
  /** @deprecated Mocked */
  cancelNextBilling: Scalars['Boolean']
  /** @deprecated Mocked */
  cancelSubscription: Scalars['Boolean']
  changeEmail: Scalars['Boolean']
  changePassword: Scalars['Boolean']
  checkLoginAvailability: Scalars['Boolean']
  commentAdd: MediaComment
  commentDelete: Scalars['ID']
  /** @deprecated Use albumCreate v2 mutation. Used by DTP */
  createAlbum: Album
  /** @deprecated Mocked */
  createPrintShopAccount?: Maybe<PrintShopResponse>
  /** @deprecated Use albumsDelete v2 mutation */
  deleteAlbums: Scalars['Boolean']
  deleteAlbumsWithContentSaving: Scalars['Boolean']
  /** @deprecated Use imagesDelete v2 mutation */
  deleteImages: Scalars['Boolean']
  /** Not in use, but operational */
  deleteLivePhoto: Array<Scalars['ID']>
  /** @deprecated Mocked */
  deleteUser: Scalars['Boolean']
  emailConfirmationAccept?: Maybe<Scalars['Boolean']>
  /** @deprecated Sunsetting. Use userProfileCreate */
  emailConfirmationResend?: Maybe<Scalars['Boolean']>
  emptyTrash: Scalars['Boolean']
  /** @deprecated Mocked */
  hideClarifaiTags: Scalars['Boolean']
  imageUpdate: Image
  /** Create hosting links for set of images, as a result set of updated states is provided back. Every media file may have several hosting links */
  imagesAddHostingLinks: Array<Image>
  /** Create new shared item */
  imagesAddSharingLink: Array<Image>
  /** Admin mutation, should be moved under admin subgraph. scrollPointer provided by the server is always null */
  imagesBan: ImageScrollPointer
  /** Deactivate hosting links by image and hosting ids. Images will not be longer accessible by hosting links */
  imagesDeactivateHostingLinks: Array<Image>
  /** Deactivate sharing links by sharing id. Images will not be longer accessible by sharing links, even for the image owner */
  imagesDeactivateSharingLink: Array<Image>
  imagesDelete: Array<Scalars['ID']>
  /** Update permissions for the specific user if link is restricted, password if public, or expiration date for any */
  imagesDuplicateSharingLink: Array<Image>
  imagesMove: Array<Image>
  imagesMoveToTrash: Array<Scalars['ID']>
  /** Admin mutation, should be moved under admin subgraph. scrollPointer provided by the server is always null */
  imagesUnban: ImageScrollPointer
  imagesUpdateAttributes: Array<Image>
  /** Duplicate sharing group - all images for provided hashId are going to be preselected for the new sharing link */
  imagesUpdateSharingLink: Array<Image>
  /** @deprecated Mocked */
  legacyLogin?: Maybe<AuthenticatedUser>
  /** @deprecated Use userLogin mutation instead */
  login?: Maybe<AuthenticatedUser>
  /** @deprecated Used by outdated mobile clients only */
  logout: Scalars['Boolean']
  /** @deprecated Mocked, use userLogin mutation */
  migrateUser: Scalars['Boolean']
  moveAlbums: Scalars['Boolean']
  /** @deprecated Use imagesMove v2 mutation */
  moveImages: Scalars['Boolean']
  /** @deprecated Mocked */
  notifyMigrationProgress: Scalars['Boolean']
  readNotifications: Scalars['Boolean']
  /** @deprecated Mocked */
  register?: Maybe<AuthenticatedUser>
  /** @deprecated Use userLogin instead, used by outdated mobile clients only */
  registerSimplified?: Maybe<AuthenticatedUser>
  removeUserTags: Scalars['Boolean']
  /** @deprecated Mocked */
  reportImage: Scalars['Boolean']
  resendActivationCode: Scalars['Boolean']
  resetPassword: Scalars['Boolean']
  /** @deprecated Use userAlbumsSettingsUpdate v2 mutation */
  saveAlbumsSettings: Scalars['Boolean']
  /** @deprecated Use firebase instead */
  sendResetPasswordCode: Scalars['Boolean']
  /** @deprecated Mocked */
  setAlbumCover: Scalars['Boolean']
  shareByEmail: Scalars['Boolean']
  /** @deprecated Mocked */
  showClarifaiTags: Scalars['Boolean']
  /** @deprecated Mocked, use userLogin mutation */
  startUserMigration: Scalars['Boolean']
  /** Not in use, but operational */
  turnOffAllHostingLinks: Scalars['Boolean']
  /** Not in use, but operational */
  turnOffHostingLinks: Array<Maybe<Image>>
  /** @deprecated Mocked */
  unSetAlbumCover: Scalars['Boolean']
  /** @deprecated Mocked, use imagesUnban v2 mutation */
  unbanImages: Scalars['Boolean']
  /** @deprecated Use albumUpdate v2 mutation */
  updateAlbum: Scalars['Boolean']
  /** @deprecated Use imageUpdate v2 mutation */
  updateImage: Scalars['Boolean']
  /** @deprecated Mocked */
  updatePaymentMethod: Scalars['Boolean']
  /** @deprecated Mocked */
  updateSortRule: Scalars['Boolean']
  /** @deprecated Mocked */
  updateSubscription: UserSubscription
  /** @deprecated Mocked */
  updateSubscriptionRecurring: Scalars['Boolean']
  /** @deprecated Use firebase instead */
  updateToken: Scalars['String']
  /** @deprecated Use userUpdate v2 mutation */
  updateUser: Scalars['Boolean']
  userAlbumsSettingsUpdate: AuthenticatedUser
  userAutoBackupSettingUpdate: AuthenticatedUser
  userLegacyPasswordValidation: Scalars['Boolean']
  userLogin: AuthenticatedUser
  userMigrate?: Maybe<UserProfile>
  userPasswordUpdate: Scalars['Boolean']
  userProfileCreate?: Maybe<UserProfile>
  userProfileUpdate?: Maybe<UserProfile>
  userToFirebase: Scalars['Boolean']
  /** @deprecated Data is in FB already */
  userToFirebaseSilent: Scalars['Boolean']
  userUpdate: AuthenticatedUser
  verifyEmail?: Maybe<AuthenticatedUser>
  /** @deprecated Mocked */
  verifyLegacyAuthCookie: Scalars['Boolean']
}

export type MutationAddUserTagsArgs = {
  imageIds?: InputMaybe<Array<Scalars['String']>>
  tags?: InputMaybe<Array<Scalars['String']>>
}

export type MutationAlbumCreateArgs = {
  parentAlbumId: Scalars['String']
  title: Scalars['String']
}

export type MutationAlbumUpdateArgs = {
  addPasswords?: InputMaybe<Array<InputMaybe<AlbumPasswordInput>>>
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  privacyMode?: InputMaybe<AlbumPrivacyMode>
  removePasswords?: InputMaybe<Array<InputMaybe<AlbumPasswordInput>>>
  title?: InputMaybe<Scalars['String']>
}

export type MutationAlbumsAddSharingLinkArgs = {
  accessLevel: AccessLevelEnum
  albumIds: Array<Scalars['ID']>
  expirationDate?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  restrictedInput?: InputMaybe<RestrictedInput>
}

export type MutationAlbumsDeactivateSharingLinkArgs = {
  hashId: Scalars['ID']
}

export type MutationAlbumsDeleteArgs = {
  ids: Array<InputMaybe<Scalars['String']>>
}

export type MutationAlbumsDuplicateSharingLinkArgs = {
  accessLevel: AccessLevelEnum
  expirationDate?: InputMaybe<Scalars['String']>
  hashId: Scalars['ID']
  password?: InputMaybe<Scalars['String']>
  restrictedInput?: InputMaybe<RestrictedInput>
}

export type MutationAlbumsMoveToTrashArgs = {
  albumIds: Array<Scalars['String']>
}

export type MutationAlbumsUpdateSharingLinkArgs = {
  expirationDate?: InputMaybe<Scalars['String']>
  hashId: Scalars['ID']
  password?: InputMaybe<Scalars['String']>
  restrictedInput?: InputMaybe<RestrictedInput>
}

export type MutationBanImagesArgs = {
  imageIds: Array<Scalars['String']>
}

export type MutationBucketAlbumCreateArgs = {
  bucketId: Scalars['ID']
  data?: InputMaybe<BucketAlbumInput>
}

export type MutationBucketAlbumUpdateArgs = {
  albumId: Scalars['ID']
  bucketId: Scalars['ID']
  data: BucketAlbumInput
}

export type MutationBucketAlbumsMoveArgs = {
  albumIds: Array<Scalars['ID']>
  bucketId: Scalars['ID']
  data?: InputMaybe<BucketAlbumsMoveInput>
}

export type MutationBucketAlbumsMoveCopyBetweenBucketsArgs = {
  albumIds: Array<Scalars['ID']>
  bucketId: Scalars['ID']
  data: BucketAlbumsMoveCopyBetweenBucketsInput
}

export type MutationBucketBulkMediaUpdateArgs = {
  bucketId: Scalars['ID']
  data: BucketMediaUpdateInput
  mediaIds: Array<Scalars['ID']>
}

export type MutationBucketConvertArgs = {
  conversionType?: InputMaybe<BucketConversionType>
}

export type MutationBucketCreateArgs = {
  data: BucketInput
}

export type MutationBucketDowngradeArgs = {
  bucketId: Scalars['ID']
  consent: Scalars['Boolean']
}

export type MutationBucketDtpRequesterAddArgs = {
  bucketId: Scalars['ID']
  requester: DtpRequester
}

export type MutationBucketFacebookTransferCreateArgs = {
  bucketId: Scalars['ID']
}

export type MutationBucketInvitationAcceptArgs = {
  invitationId: Scalars['ID']
}

export type MutationBucketInvitationCreateArgs = {
  bucketId: Scalars['ID']
  emails?: InputMaybe<Array<Scalars['String']>>
  userRole?: InputMaybe<BucketUserRole>
}

export type MutationBucketInvitationResendArgs = {
  bucketId: Scalars['ID']
  invitationId: Scalars['ID']
}

export type MutationBucketInvitationResetArgs = {
  bucketId: Scalars['ID']
}

export type MutationBucketInvitationUpdateArgs = {
  bucketId: Scalars['ID']
  invitationId: Scalars['ID']
  status: BucketInvitationStatus
}

export type MutationBucketLoginSuccessArgs = {
  isAdmin?: InputMaybe<Scalars['Boolean']>
}

export type MutationBucketMediaCreateArgs = {
  bucketId: Scalars['ID']
  data?: InputMaybe<BucketMediaInput>
  filename: Scalars['String']
  mediaId: Scalars['ID']
}

export type MutationBucketMediaMoveArgs = {
  bucketId: Scalars['ID']
  data?: InputMaybe<BucketMediaMoveInput>
  mediaIds: Array<Scalars['ID']>
}

export type MutationBucketMediaMoveCopyBetweenBucketsArgs = {
  bucketId: Scalars['ID']
  data: BucketMediaMoveCopyBetweenBucketsInput
  mediaIds: Array<Scalars['ID']>
}

export type MutationBucketMediaUpdateArgs = {
  bucketId: Scalars['ID']
  data: BucketMediaUpdateInput
  mediaId: Scalars['ID']
}

export type MutationBucketRegisterArgs = {
  data?: InputMaybe<NewAccountInput>
}

export type MutationBucketShareCreateArgs = {
  bucketId: Scalars['ID']
  data: BucketShareCreateInput
}

export type MutationBucketSharePasswordUpdateArgs = {
  bucketId: Scalars['ID']
  password: Scalars['String']
  shareId: Scalars['ID']
}

export type MutationBucketSharePasswordValidateArgs = {
  password: Scalars['String']
  shareId: Scalars['ID']
}

export type MutationBucketShareUpdateArgs = {
  albumId?: InputMaybe<Scalars['String']>
  bucketId: Scalars['ID']
  password?: InputMaybe<Scalars['String']>
  scheduledDeletionAt?: InputMaybe<Scalars['String']>
  shareId: Scalars['ID']
  shareType?: InputMaybe<BucketShareType>
  status: BucketShareStatus
  title?: InputMaybe<Scalars['String']>
}

export type MutationBucketTransferOwnerAcceptArgs = {
  transferId: Scalars['ID']
}

export type MutationBucketTransferOwnerCreateArgs = {
  bucketId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationBucketTrashEmptyArgs = {
  bucketId: Scalars['ID']
}

export type MutationBucketUpdateArgs = {
  bucketId: Scalars['ID']
  data: BucketInput
}

export type MutationBucketUserUpdateArgs = {
  bucketId: Scalars['ID']
  data: BucketUserInput
  userId: Scalars['ID']
}

export type MutationBucketViewsCreateArgs = {
  bucketId: Scalars['ID']
  daysPreviewCount?: Scalars['Int']
  monthPreviewCount?: Scalars['Int']
}

export type MutationChangeEmailArgs = {
  newEmail: Scalars['String']
}

export type MutationChangePasswordArgs = {
  confirmNewPassword: Scalars['String']
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationCheckLoginAvailabilityArgs = {
  email?: InputMaybe<Scalars['String']>
  username?: InputMaybe<Scalars['String']>
}

export type MutationCommentAddArgs = {
  content: Scalars['String']
  mediaId: Scalars['String']
  password?: InputMaybe<Scalars['String']>
}

export type MutationCommentDeleteArgs = {
  commentId: Scalars['String']
  mediaId: Scalars['String']
}

export type MutationCreateAlbumArgs = {
  parentAlbumId: Scalars['String']
  title: Scalars['String']
}

export type MutationCreatePrintShopAccountArgs = {
  email: Scalars['String']
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationDeleteAlbumsArgs = {
  ids: Array<InputMaybe<Scalars['String']>>
}

export type MutationDeleteAlbumsWithContentSavingArgs = {
  ids: Array<InputMaybe<Scalars['String']>>
}

export type MutationDeleteImagesArgs = {
  albumId?: InputMaybe<Scalars['String']>
  imageIds: Array<InputMaybe<Scalars['String']>>
}

export type MutationDeleteLivePhotoArgs = {
  imageIds: Array<Scalars['String']>
}

export type MutationEmailConfirmationAcceptArgs = {
  id: Scalars['ID']
}

export type MutationHideClarifaiTagsArgs = {
  imageIds?: InputMaybe<Array<Scalars['String']>>
  notAccurate?: InputMaybe<Array<Scalars['String']>>
  notImportant?: InputMaybe<Array<Scalars['String']>>
}

export type MutationImageUpdateArgs = {
  description?: InputMaybe<Scalars['String']>
  imageId: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  userTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MutationImagesAddHostingLinksArgs = {
  imageIds: Array<Scalars['ID']>
  linkType: HostingLinkType
}

export type MutationImagesAddSharingLinkArgs = {
  accessLevel: AccessLevelEnum
  expirationDate?: InputMaybe<Scalars['String']>
  imageIds: Array<Scalars['ID']>
  password?: InputMaybe<Scalars['String']>
  restrictedInput?: InputMaybe<RestrictedInput>
}

export type MutationImagesBanArgs = {
  imageIds: Array<Scalars['String']>
}

export type MutationImagesDeactivateHostingLinksArgs = {
  links: Array<LinkTuple>
}

export type MutationImagesDeactivateSharingLinkArgs = {
  hashId: Scalars['ID']
}

export type MutationImagesDeleteArgs = {
  albumId?: InputMaybe<Scalars['String']>
  imageIds: Array<InputMaybe<Scalars['String']>>
}

export type MutationImagesDuplicateSharingLinkArgs = {
  accessLevel: AccessLevelEnum
  expirationDate?: InputMaybe<Scalars['String']>
  hashId: Scalars['ID']
  password?: InputMaybe<Scalars['String']>
  restrictedInput?: InputMaybe<RestrictedInput>
}

export type MutationImagesMoveArgs = {
  imageIds: Array<Scalars['String']>
  targetAlbumId: Scalars['String']
}

export type MutationImagesMoveToTrashArgs = {
  imageIds: Array<Scalars['String']>
}

export type MutationImagesUnbanArgs = {
  imageIds: Array<Scalars['String']>
}

export type MutationImagesUpdateAttributesArgs = {
  add?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  delete?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  imageIds: Array<Scalars['String']>
}

export type MutationImagesUpdateSharingLinkArgs = {
  expirationDate?: InputMaybe<Scalars['String']>
  hashId: Scalars['ID']
  password?: InputMaybe<Scalars['String']>
  restrictedInput?: InputMaybe<RestrictedInput>
}

export type MutationLegacyLoginArgs = {
  cookie: Scalars['String']
}

export type MutationLoginArgs = {
  captchaSource: CaptchaSource
  captchaToken: Scalars['String']
  login: Scalars['String']
  password: Scalars['String']
}

export type MutationMigrateUserArgs = {
  code: Scalars['String']
  password: Scalars['String']
  username: Scalars['String']
}

export type MutationMoveAlbumsArgs = {
  albumIds: Array<Scalars['String']>
  targetAlbumId: Scalars['String']
}

export type MutationMoveImagesArgs = {
  imageIds: Array<Scalars['String']>
  sourceAlbumId?: InputMaybe<Scalars['String']>
  targetAlbumId: Scalars['String']
}

export type MutationReadNotificationsArgs = {
  notifications: Array<ModalNotificationType>
}

export type MutationRegisterArgs = {
  addons: Array<InputMaybe<Scalars['String']>>
  birthDate: Scalars['String']
  captchaToken: Scalars['String']
  email: Scalars['String']
  firstName: Scalars['String']
  isTrial?: InputMaybe<Scalars['Boolean']>
  lastName: Scalars['String']
  password: Scalars['String']
  planId: Scalars['String']
  sex?: InputMaybe<Sex>
  userTypes: Array<InputMaybe<Scalars['String']>>
  username: Scalars['String']
}

export type MutationRegisterSimplifiedArgs = {
  addons: Array<InputMaybe<Scalars['String']>>
  captchaSource: CaptchaSource
  captchaToken: Scalars['String']
  email: Scalars['String']
  isTrial?: InputMaybe<Scalars['Boolean']>
  password: Scalars['String']
  planId: Scalars['String']
  username: Scalars['String']
}

export type MutationRemoveUserTagsArgs = {
  imageIds?: InputMaybe<Array<Scalars['String']>>
  tags?: InputMaybe<Array<Scalars['String']>>
}

export type MutationReportImageArgs = {
  comment?: InputMaybe<Scalars['String']>
  imageId: Scalars['String']
  reportType: ViolationType
  reporterEmail?: InputMaybe<Scalars['String']>
}

export type MutationResetPasswordArgs = {
  code: Scalars['String']
  confirmNewPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationSaveAlbumsSettingsArgs = {
  settings: AlbumsSettingsInput
}

export type MutationSendResetPasswordCodeArgs = {
  login: Scalars['String']
}

export type MutationSetAlbumCoverArgs = {
  albumId: Scalars['String']
  imageId: Scalars['String']
}

export type MutationShareByEmailArgs = {
  author: Scalars['String']
  captchaSource: CaptchaSource
  captchaToken: Scalars['String']
  directUrl: Scalars['String']
  fullscreenUrl: Scalars['String']
  mediaType: ShareByEmailMediaType
  message: Scalars['String']
  name: Scalars['String']
  to: Array<InputMaybe<Scalars['String']>>
}

export type MutationShowClarifaiTagsArgs = {
  imageIds?: InputMaybe<Array<Scalars['String']>>
  tags?: InputMaybe<Array<Scalars['String']>>
}

export type MutationStartUserMigrationArgs = {
  login: Scalars['String']
  password: Scalars['String']
}

export type MutationTurnOffHostingLinksArgs = {
  imageIds: Array<Scalars['String']>
}

export type MutationUnSetAlbumCoverArgs = {
  albumId: Scalars['String']
}

export type MutationUnbanImagesArgs = {
  imageIds: Array<Scalars['String']>
}

export type MutationUpdateAlbumArgs = {
  addPasswords?: InputMaybe<Array<InputMaybe<AlbumPasswordInput>>>
  description?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  privacyMode?: InputMaybe<AlbumPrivacyMode>
  removePasswords?: InputMaybe<Array<InputMaybe<AlbumPasswordInput>>>
  title?: InputMaybe<Scalars['String']>
}

export type MutationUpdateImageArgs = {
  albumId?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  imageId: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  userTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
}

export type MutationUpdatePaymentMethodArgs = {
  deviceData: Scalars['String']
  nonce: Scalars['String']
}

export type MutationUpdateSortRuleArgs = {
  id: Scalars['String']
  sortBy: Sorter
}

export type MutationUpdateSubscriptionArgs = {
  addons: Array<InputMaybe<Scalars['String']>>
  isTrial?: InputMaybe<Scalars['Boolean']>
  planId: Scalars['String']
  promocode?: InputMaybe<Scalars['String']>
}

export type MutationUpdateSubscriptionRecurringArgs = {
  recurring: Scalars['Boolean']
}

export type MutationUpdateUserArgs = {
  birthDate?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  sex?: InputMaybe<Sex>
  stateOrProvince?: InputMaybe<Scalars['String']>
  usageReasons?: InputMaybe<UsageReasonsInput>
  zip?: InputMaybe<Scalars['String']>
}

export type MutationUserAlbumsSettingsUpdateArgs = {
  settings: AlbumsSettingsInput
}

export type MutationUserAutoBackupSettingUpdateArgs = {
  settings: AutoBackupInput
}

export type MutationUserLegacyPasswordValidationArgs = {
  password: Scalars['String']
  userId: Scalars['String']
}

export type MutationUserLoginArgs = {
  token: Scalars['String']
}

export type MutationUserPasswordUpdateArgs = {
  password: Scalars['String']
}

export type MutationUserProfileCreateArgs = {
  data: UserProfileInput
  invitationId?: InputMaybe<Scalars['String']>
  isConverted?: InputMaybe<Scalars['Boolean']>
}

export type MutationUserProfileUpdateArgs = {
  data: UserProfileInput
}

export type MutationUserToFirebaseArgs = {
  login: Scalars['String']
  password: Scalars['String']
}

export type MutationUserToFirebaseSilentArgs = {
  login: Scalars['String']
}

export type MutationUserUpdateArgs = {
  birthDate?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  phoneNumber?: InputMaybe<Scalars['String']>
  sex?: InputMaybe<Sex>
  stateOrProvince?: InputMaybe<Scalars['String']>
  usageReasons?: InputMaybe<UsageReasonsInput>
  zip?: InputMaybe<Scalars['String']>
}

export type MutationVerifyEmailArgs = {
  code: Scalars['String']
}

export type MutationVerifyLegacyAuthCookieArgs = {
  cookie: Scalars['String']
}

export type NewAccountInput = {
  email: Scalars['String']
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  password: Scalars['String']
  skipFirebaseAccountCreation?: InputMaybe<Scalars['Boolean']>
  username?: InputMaybe<Scalars['String']>
}

export enum OrientationType {
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT',
  LeftBottom = 'LEFT_BOTTOM',
  LeftTop = 'LEFT_TOP',
  RightBottom = 'RIGHT_BOTTOM',
  RightTop = 'RIGHT_TOP',
  TopLeft = 'TOP_LEFT',
  TopRight = 'TOP_RIGHT',
  Unknown = 'UNKNOWN'
}

export type PendingSubscription = {
  __typename?: 'PendingSubscription'
  account: BillingAccount
  addons: Array<Maybe<Scalars['String']>>
  id: Scalars['String']
  planId: Scalars['String']
  startDate: Scalars['String']
}

export type PermissionPolicy = {
  __typename?: 'PermissionPolicy'
  albumCreate?: Maybe<Scalars['Boolean']>
  albumDelete?: Maybe<Scalars['Boolean']>
  albumGet?: Maybe<Scalars['Boolean']>
  albumUpdate?: Maybe<Scalars['Boolean']>
  bucketCreate?: Maybe<Scalars['Boolean']>
  bucketDelete?: Maybe<Scalars['Boolean']>
  bucketGet?: Maybe<Scalars['Boolean']>
  bucketLeave?: Maybe<Scalars['Boolean']>
  bucketUpdate?: Maybe<Scalars['Boolean']>
  invitationCreate?: Maybe<Scalars['Boolean']>
  invitationDelete?: Maybe<Scalars['Boolean']>
  invitationGet?: Maybe<Scalars['Boolean']>
  invitationUpdate?: Maybe<Scalars['Boolean']>
  mediaCreate?: Maybe<Scalars['Boolean']>
  mediaDelete?: Maybe<Scalars['Boolean']>
  mediaGet?: Maybe<Scalars['Boolean']>
  mediaUpdate?: Maybe<Scalars['Boolean']>
  userCreate?: Maybe<Scalars['Boolean']>
  userDelete?: Maybe<Scalars['Boolean']>
  userGet?: Maybe<Scalars['Boolean']>
  userList?: Maybe<Scalars['Boolean']>
  userUpdate?: Maybe<Scalars['Boolean']>
}

export type Plan = {
  __typename?: 'Plan'
  apple_id?: Maybe<Scalars['String']>
  billing_interval?: Maybe<Scalars['String']>
  /** @deprecated Use is_favorites_enabled */
  favorites?: Maybe<Scalars['Boolean']>
  /** @deprecated Use is_hosting_enabled */
  hosting?: Maybe<Scalars['Boolean']>
  /** @deprecated Use is_image_editor_enabled */
  image_editor?: Maybe<Scalars['Boolean']>
  is_create_bucket_enabled?: Maybe<Scalars['Boolean']>
  is_favorites_enabled?: Maybe<Scalars['Boolean']>
  is_hosting_enabled?: Maybe<Scalars['Boolean']>
  is_image_editor_enabled?: Maybe<Scalars['Boolean']>
  is_modify_albums_enabled?: Maybe<Scalars['Boolean']>
  is_search_enabled?: Maybe<Scalars['Boolean']>
  is_sharing_enabled?: Maybe<Scalars['Boolean']>
  is_sharing_plan?: Maybe<Scalars['Boolean']>
  is_sort_by_title_enabled?: Maybe<Scalars['Boolean']>
  is_sort_by_upload_date_enabled?: Maybe<Scalars['Boolean']>
  is_storage_plan?: Maybe<Scalars['Boolean']>
  is_user_tags_enabled?: Maybe<Scalars['Boolean']>
  is_video_player_enabled?: Maybe<Scalars['Boolean']>
  media_limit?: Maybe<Scalars['Int']>
  /** @deprecated Use is_modify_albums_enabled */
  modify_albums?: Maybe<Scalars['Boolean']>
  paddle_id?: Maybe<Scalars['String']>
  paypal_id?: Maybe<Scalars['String']>
  photobucket_id?: Maybe<Scalars['String']>
  plan_name: Scalars['String']
  /** @deprecated Use is_search_enabled */
  search?: Maybe<Scalars['Boolean']>
  /** @deprecated Use is_sharing_enabled */
  sharing?: Maybe<Scalars['Boolean']>
  /** @deprecated Use is_sort_by_title_enabled */
  sort_by_title?: Maybe<Scalars['Boolean']>
  /** @deprecated Use is_sort_by_upload_date_enabled */
  sort_by_upload_date?: Maybe<Scalars['Boolean']>
  storage_limit?: Maybe<Scalars['Float']>
  subscription_tier?: Maybe<Scalars['String']>
  trial_days?: Maybe<Scalars['Int']>
  /** @deprecated Use is_user_tags_enabled */
  user_tags?: Maybe<Scalars['Boolean']>
  /** @deprecated Use is_video_player_enabled */
  video_player?: Maybe<Scalars['Boolean']>
  vindicia_id?: Maybe<Scalars['String']>
}

export type PreviewStatsItem = {
  __typename?: 'PreviewStatsItem'
  dayOfMonth: Scalars['String']
  mediaCount: Scalars['Int']
  previewImage: Image
}

export type PrintShopResponse = {
  __typename?: 'PrintShopResponse'
  message: Scalars['String']
  success: Scalars['Boolean']
}

/** Restrict for everyone */
export type Private = {
  __typename?: 'Private'
  kind: AccessLevelEnum
}

export type ProfileCounters = {
  __typename?: 'ProfileCounters'
  totalMedia?: Maybe<Scalars['Int']>
  totalSize?: Maybe<Scalars['Float']>
}

export type ProfileMigrationMetadata = {
  __typename?: 'ProfileMigrationMetadata'
  approximateMediaCount?: Maybe<Scalars['Int']>
  approximateMediaSize?: Maybe<Scalars['Float']>
  bucketId?: Maybe<Scalars['String']>
  completedAt?: Maybe<Scalars['String']>
  hasKafkaBucket?: Maybe<Scalars['Boolean']>
  migratedMediaCount?: Maybe<Scalars['Int']>
  migrationStatus: ProfileMigrationStatus
  startedAt?: Maybe<Scalars['String']>
}

export enum ProfileMigrationStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  NotNeeded = 'NOT_NEEDED',
  Required = 'REQUIRED'
}

export enum ProfileStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Dormant = 'DORMANT'
}

/** Media is public and accessibly by any unauthorized user, or only by password, if it was set */
export type Public = {
  __typename?: 'Public'
  kind: AccessLevelEnum
  passwordProtected: Scalars['Boolean']
}

export type Query = {
  __typename?: 'Query'
  /** @deprecated Mocked, use albumRead v2 query */
  album?: Maybe<Album>
  albumRead?: Maybe<AlbumV2>
  albumsReadAll: Array<AlbumV2>
  bucket?: Maybe<Bucket>
  bucketActivities?: Maybe<BucketActivitiesResults>
  bucketAlbum?: Maybe<BucketAlbum>
  bucketAlbums?: Maybe<BucketAlbumsResults>
  bucketBulkDownloads?: Maybe<BucketBulkDownloadResults>
  bucketCredentials?: Maybe<BucketCredentials>
  bucketGenerateDownloadLinks?: Maybe<BucketGenerateDownloadLinks>
  bucketInvitation?: Maybe<BucketInvitation>
  bucketInvitationQuery?: Maybe<BucketInvitationsResults>
  bucketInvitations?: Maybe<BucketInvitationsResults>
  bucketMedia?: Maybe<BucketMedia>
  bucketMediaByAlbumId?: Maybe<BucketMediaResults>
  bucketMediaByIds?: Maybe<Array<BucketMedia>>
  bucketMediaByShareId?: Maybe<BucketMediaResults>
  bucketMediaByShareIds?: Maybe<Array<BucketMedia>>
  bucketMediaPublic?: Maybe<BucketMedia>
  bucketMediaSearch?: Maybe<BucketMediaResults>
  bucketMediaUnorganizedDetails?: Maybe<BucketMediaUnassignedCounters>
  bucketShareById?: Maybe<BucketShare>
  bucketShares?: Maybe<BucketSharesResults>
  bucketUser?: Maybe<BucketUser>
  bucketUsers?: Maybe<BucketUsersResults>
  bucketViewMonthly?: Maybe<Array<Maybe<MonthViewResult>>>
  bucketViewYearly?: Maybe<Array<Maybe<YearViewResult>>>
  bucketsByUserId?: Maybe<BucketsByUserIdResults>
  /** @deprecated Mocked */
  checkMigrationAvailability: Scalars['Boolean']
  /** @deprecated Use v2 graph */
  checkNoDateTakenAvailablePresence: Scalars['Boolean']
  /** @deprecated Mocked */
  checkPaymentMethodPresence: Scalars['Boolean']
  emailAvailable?: Maybe<Scalars['Boolean']>
  /** @deprecated Use v2 graph */
  getAlbumDetails: AlbumDetails
  getAlbumForEditor: AlbumForEditor
  /** @deprecated Mocked, use getAlbumImagesV2 v1 query */
  getAlbumImages: Array<Maybe<Image>>
  getAlbumImagesV2: ImageScrollPointer
  /** @deprecated Use v2 graph */
  getAlbumPasswords: Array<Maybe<AlbumPassword>>
  /** @deprecated Deprecated, use albumsReadAll instead */
  getAllAlbums: Array<Maybe<AlbumTreeItem>>
  getAllPublicAlbums: Array<Maybe<AlbumTreeItem>>
  /** @deprecated Use v2 graph */
  getAllowedSubscriptionPlans: Array<Maybe<UserSubscriptionPlan>>
  /** @deprecated Mocked */
  getBraintreeClientToken: Scalars['String']
  getDirectLinks: Array<Maybe<Scalars['String']>>
  getDirectVideoLinks: Array<Maybe<Scalars['String']>>
  getForeignUser: ForeignUser
  /** @deprecated Use v2 graph */
  getImageNextBlockScrollPointer?: Maybe<Scalars['String']>
  getImageScrollPointerByDate?: Maybe<Scalars['String']>
  /** @deprecated Use v2 graph */
  getImageScrollPointerById?: Maybe<Scalars['String']>
  getImagesFromAlbumAndSubAlbums: ImageScrollPointer
  getMediaComments: MediaCommentsPaginable
  getMigrationProgressStatistic?: Maybe<MigrationProgressStatisticType>
  /** @deprecated Mocked */
  getMigrationSettings?: Maybe<LegacyUser>
  /** @deprecated Mocked, use monthsView v2 query */
  getMonthsView: MonthsView
  /** @deprecated Use v2 graph */
  getNoDateTakenAvailableMedia: ImageScrollPointer
  /** @deprecated Mocked */
  getNoDateTakenAvailableMonthsView: MonthsView
  /** @deprecated Use v2 graph */
  getPlan: UserSubscriptionPlan
  getProfile?: Maybe<AuthenticatedUser>
  getPublicAlbumDetails: AlbumDetails
  /** @deprecated Mocked, use getPublicAlbumImagesV2 v2 query */
  getPublicAlbumImages: Array<Maybe<Image>>
  getPublicAlbumImagesV2: ImageScrollPointer
  getPublicImage?: Maybe<Image>
  /** @deprecated Mocked */
  getPublicImagesFromAlbumAndSubAlbums: ImageScrollPointer
  /** @deprecated Mocked, use getPublicSubAlbumsV2 v1 query */
  getPublicSubAlbums: Array<Maybe<Album>>
  getPublicSubAlbumsV2: SubAlbumsScrollPointer
  /** @deprecated Mocked, use getSubAlbumsV2 v1 query */
  getSubAlbums: Array<Maybe<Album>>
  getSubAlbumsV2: SubAlbumsScrollPointer
  /** @deprecated Use v2 graph */
  getSubscription: UserSubscription
  /** @deprecated Use v2 graph */
  getSubscriptionPlans: Array<Maybe<UserSubscriptionPlan>>
  getSupportedFileTypes: SupportedFileTypes
  getTrashAlbumDetails: AlbumDetails
  getTrashImages: ImageScrollPointer
  getUserImages: UserImagesScrollPointer
  getUserTags?: Maybe<Array<Maybe<Scalars['String']>>>
  hostedImages: HostedImageResult
  image?: Maybe<Image>
  /** Not in use, but operational */
  imageReferrers: Array<Maybe<ImageReferrer>>
  /** Check if there is any image in user bucket with provided md5 (ETag) value and similar file name */
  imagesCheckUploadStatus?: Maybe<BatchS3UploadStatuses>
  imagesReadByAttributes: ImagesByAttributesSearchResult
  imagesReadBySharingId: ImagesBySharingIdResult
  /** Get items for the user activity feed, SHARED BY ME section */
  itemsSharedByMe: SharedResult
  /** Get items for the user activity feed, SHARED WITH ME section */
  itemsSharedWithMe: SharedResult
  monthsScrollPointer: Scalars['String']
  monthsView: MonthsView
  /** @deprecated Mocked */
  retryChargePayment: Scalars['Boolean']
  searchImages: ImageSearchResult
  searchShutterStock?: Maybe<ShutterStockConnection>
  /** @deprecated Mocked */
  sharedImage?: Maybe<SharedImage>
  userFirebaseCustomToken?: Maybe<CustomToken>
  userId: Scalars['ID']
  userLoginRule?: Maybe<UserLoginRuleResponse>
  userProfile?: Maybe<UserProfile>
  userSessionCookie?: Maybe<SessionCookie>
  userStateStatus: UserStateStatus
  /** Generate session cookie based on provided id token */
  userTokenExchange: TokenExchange
  yearsView: YearsView
}

export type QueryAlbumArgs = {
  id: Scalars['String']
  page: Scalars['Int']
  sortBy: Sorter
}

export type QueryAlbumReadArgs = {
  id: Scalars['String']
}

export type QueryAlbumsReadAllArgs = {
  sortBy: Sorter
}

export type QueryBucketArgs = {
  bucketId: Scalars['ID']
}

export type QueryBucketActivitiesArgs = {
  bucketId: Scalars['ID']
  limit: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
  timezone?: InputMaybe<Scalars['String']>
}

export type QueryBucketAlbumArgs = {
  albumId: Scalars['ID']
  bucketId: Scalars['ID']
}

export type QueryBucketAlbumsArgs = {
  albumId?: InputMaybe<Scalars['ID']>
  bucketId: Scalars['ID']
  limit?: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
  sorter?: InputMaybe<BucketAlbumSorter>
}

export type QueryBucketBulkDownloadsArgs = {
  bucketId: Scalars['ID']
  limit?: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
  status: BulkDownloadStatus
}

export type QueryBucketCredentialsArgs = {
  bucketId: Scalars['ID']
  userId: Scalars['ID']
}

export type QueryBucketGenerateDownloadLinksArgs = {
  albums?: InputMaybe<Array<Scalars['ID']>>
  bucketId: Scalars['ID']
  media?: InputMaybe<Array<Scalars['ID']>>
}

export type QueryBucketInvitationArgs = {
  invitationId: Scalars['ID']
}

export type QueryBucketInvitationQueryArgs = {
  bucketId: Scalars['ID']
  userRole?: InputMaybe<BucketUserRole>
}

export type QueryBucketInvitationsArgs = {
  bucketId: Scalars['ID']
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryBucketMediaArgs = {
  bucketId: Scalars['ID']
  mediaId: Scalars['ID']
}

export type QueryBucketMediaByAlbumIdArgs = {
  albumId?: InputMaybe<Scalars['ID']>
  bucketId: Scalars['ID']
  filterBy?: InputMaybe<BucketMediaFilter>
  limit?: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<BucketMediaSorter>
}

export type QueryBucketMediaByIdsArgs = {
  bucketId: Scalars['ID']
  mediaIds: Array<Scalars['ID']>
}

export type QueryBucketMediaByShareIdArgs = {
  filterBy?: InputMaybe<BucketMediaFilter>
  limit?: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
  shareId: Scalars['ID']
  sortBy?: InputMaybe<BucketMediaSorter>
}

export type QueryBucketMediaByShareIdsArgs = {
  mediaIds: Array<Scalars['ID']>
  shareId: Scalars['ID']
}

export type QueryBucketMediaPublicArgs = {
  mediaId: Scalars['ID']
  shareId: Scalars['ID']
}

export type QueryBucketMediaSearchArgs = {
  bucketId: Scalars['ID']
  limit?: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
  query: Scalars['String']
}

export type QueryBucketMediaUnorganizedDetailsArgs = {
  bucketId: Scalars['ID']
}

export type QueryBucketShareByIdArgs = {
  shareId: Scalars['ID']
}

export type QueryBucketSharesArgs = {
  bucketId: Scalars['ID']
  limit?: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryBucketUserArgs = {
  bucketId: Scalars['ID']
  userId: Scalars['ID']
}

export type QueryBucketUsersArgs = {
  bucketId: Scalars['ID']
  limit?: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
}

export type QueryBucketViewMonthlyArgs = {
  bucketId: Scalars['ID']
  limit: Scalars['Int']
  page: Scalars['Int']
  topDays: Scalars['Int']
}

export type QueryBucketViewYearlyArgs = {
  bucketId: Scalars['ID']
  limit: Scalars['Int']
  page: Scalars['Int']
  topMonths: Scalars['Int']
}

export type QueryBucketsByUserIdArgs = {
  nextToken?: InputMaybe<Scalars['String']>
  userId: Scalars['ID']
}

export type QueryCheckMigrationAvailabilityArgs = {
  login: Scalars['String']
}

export type QueryCheckNoDateTakenAvailablePresenceArgs = {
  albumId?: InputMaybe<Scalars['String']>
  includeSubAlbums?: InputMaybe<Scalars['Boolean']>
}

export type QueryEmailAvailableArgs = {
  email: Scalars['String']
}

export type QueryGetAlbumDetailsArgs = {
  id: Scalars['String']
}

export type QueryGetAlbumForEditorArgs = {
  id: Scalars['String']
}

export type QueryGetAlbumImagesArgs = {
  albumId: Scalars['String']
  page: Scalars['Int']
  pageSize?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Sorter>
}

export type QueryGetAlbumImagesV2Args = {
  albumId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  scrollPointer?: InputMaybe<Scalars['String']>
  skipNoDateAvailable?: InputMaybe<Scalars['Boolean']>
  sortBy?: InputMaybe<Sorter>
}

export type QueryGetAlbumPasswordsArgs = {
  id: Scalars['String']
}

export type QueryGetAllAlbumsArgs = {
  sortBy: Sorter
}

export type QueryGetAllPublicAlbumsArgs = {
  owner: Scalars['String']
  sortBy: Sorter
}

export type QueryGetDirectLinksArgs = {
  ids: Array<InputMaybe<Scalars['String']>>
  password?: InputMaybe<Scalars['String']>
}

export type QueryGetDirectVideoLinksArgs = {
  ids: Array<InputMaybe<Scalars['String']>>
  password?: InputMaybe<Scalars['String']>
}

export type QueryGetForeignUserArgs = {
  username: Scalars['String']
}

export type QueryGetImageNextBlockScrollPointerArgs = {
  currentScrollPointer: Scalars['String']
  desc: Scalars['Boolean']
  lookupForAlbum?: InputMaybe<Scalars['Boolean']>
  sortingField: SortingField
}

export type QueryGetImageScrollPointerByDateArgs = {
  date: Scalars['String']
  forEndOfPeriod: Scalars['Boolean']
  sortingField: SortingField
}

export type QueryGetImageScrollPointerByIdArgs = {
  imageId: Scalars['String']
  sortingField: SortingField
}

export type QueryGetImagesFromAlbumAndSubAlbumsArgs = {
  albumId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  scrollPointer?: InputMaybe<Scalars['String']>
  skipNoDateAvailable?: InputMaybe<Scalars['Boolean']>
  sortBy?: InputMaybe<Sorter>
}

export type QueryGetMediaCommentsArgs = {
  desc: Scalars['Boolean']
  mediaId: Scalars['String']
  pageSize: Scalars['Int']
  password?: InputMaybe<Scalars['String']>
  scrollPointer?: InputMaybe<Scalars['String']>
}

export type QueryGetMigrationSettingsArgs = {
  code: Scalars['String']
  username: Scalars['String']
}

export type QueryGetMonthsViewArgs = {
  desc: Scalars['Boolean']
  pageSize: Scalars['Int']
  scrollPointer?: InputMaybe<Scalars['String']>
  skipNoDateAvailable?: InputMaybe<Scalars['Boolean']>
  sortingField?: InputMaybe<SortingField>
  topDays: Scalars['Int']
  username: Scalars['String']
}

export type QueryGetNoDateTakenAvailableMediaArgs = {
  albumId?: InputMaybe<Scalars['String']>
  includeSubAlbums?: InputMaybe<Scalars['Boolean']>
  pageSize: Scalars['Int']
  scrollPointer?: InputMaybe<Scalars['String']>
}

export type QueryGetNoDateTakenAvailableMonthsViewArgs = {
  topDays: Scalars['Int']
  username: Scalars['String']
}

export type QueryGetPlanArgs = {
  id: Scalars['String']
}

export type QueryGetPublicAlbumDetailsArgs = {
  albumId: Scalars['String']
  owner: Scalars['String']
  password?: InputMaybe<Scalars['String']>
}

export type QueryGetPublicAlbumImagesArgs = {
  albumId: Scalars['String']
  page: Scalars['Int']
  pageSize?: InputMaybe<Scalars['Int']>
  password?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Sorter>
}

export type QueryGetPublicAlbumImagesV2Args = {
  albumId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  password?: InputMaybe<Scalars['String']>
  scrollPointer?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Sorter>
}

export type QueryGetPublicImageArgs = {
  imageId: Scalars['String']
  password?: InputMaybe<Scalars['String']>
}

export type QueryGetPublicImagesFromAlbumAndSubAlbumsArgs = {
  albumId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  password?: InputMaybe<Scalars['String']>
  scrollPointer?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Sorter>
}

export type QueryGetPublicSubAlbumsArgs = {
  albumId: Scalars['String']
  page: Scalars['Int']
  pageSize?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Sorter>
  username: Scalars['String']
}

export type QueryGetPublicSubAlbumsV2Args = {
  albumId: Scalars['String']
  nestedImagesCount?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  scrollPointer?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Sorter>
  username: Scalars['String']
}

export type QueryGetSubAlbumsArgs = {
  albumId: Scalars['String']
  page: Scalars['Int']
  pageSize?: InputMaybe<Scalars['Int']>
  sortBy?: InputMaybe<Sorter>
}

export type QueryGetSubAlbumsV2Args = {
  albumId: Scalars['String']
  nestedImagesCount?: InputMaybe<Scalars['Int']>
  pageSize?: InputMaybe<Scalars['Int']>
  scrollPointer?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Sorter>
}

export type QueryGetTrashImagesArgs = {
  pageSize: Scalars['Int']
  scrollPointer?: InputMaybe<Scalars['String']>
  trashSorter: TrashSorterInput
}

export type QueryGetUserImagesArgs = {
  pageSize: Scalars['Int']
  scrollDirection: ScrollDirection
  scrollPointer?: InputMaybe<Scalars['String']>
  skipNoDateAvailable?: InputMaybe<Scalars['Boolean']>
  sortBy: Sorter
}

export type QueryGetUserTagsArgs = {
  tagPattern: Scalars['String']
}

export type QueryHostedImagesArgs = {
  pageSize: Scalars['Int']
  scrollPointer?: InputMaybe<Scalars['String']>
}

export type QueryImageArgs = {
  id: Scalars['String']
}

export type QueryImageReferrersArgs = {
  imageId: Scalars['ID']
}

export type QueryImagesCheckUploadStatusArgs = {
  md5Tuple: Array<Md5InputTuple>
}

export type QueryImagesReadByAttributesArgs = {
  attributes: Array<Scalars['String']>
  desc: Scalars['Boolean']
  pageSize: Scalars['Int']
  scrollPointer?: InputMaybe<Scalars['String']>
  sortBy: SortingField
}

export type QueryImagesReadBySharingIdArgs = {
  id: Scalars['ID']
  pageSize: Scalars['Int']
  password?: InputMaybe<Scalars['String']>
  scrollPointer?: InputMaybe<Scalars['String']>
}

export type QueryItemsSharedByMeArgs = {
  archived: Scalars['Boolean']
  pageSize: Scalars['Int']
  previewsCountAlbum: Scalars['Int']
  previewsCountImages: Scalars['Int']
  scrollPointer?: InputMaybe<Scalars['String']>
}

export type QueryItemsSharedWithMeArgs = {
  pageSize: Scalars['Int']
  previewsCountAlbum: Scalars['Int']
  previewsCountImages: Scalars['Int']
  scrollPointer?: InputMaybe<Scalars['String']>
}

export type QueryMonthsScrollPointerArgs = {
  imageId: Scalars['ID']
  sortingField: DateSortingField
}

export type QueryMonthsViewArgs = {
  pagination: ViewPagination
  scroll?: InputMaybe<Scroll>
  topDays: Scalars['Int']
}

export type QuerySearchImagesArgs = {
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
  query: Scalars['String']
}

export type QuerySearchShutterStockArgs = {
  page?: InputMaybe<Scalars['Int']>
  per_page?: InputMaybe<Scalars['Int']>
  query?: InputMaybe<Scalars['String']>
}

export type QuerySharedImageArgs = {
  id: Scalars['String']
}

export type QueryUserIdArgs = {
  login: Scalars['String']
}

export type QueryUserLoginRuleArgs = {
  planId: UserPlanId
  storageStatistics: UserStorageStatistics
  userId: Scalars['ID']
}

export type QueryYearsViewArgs = {
  pagination: ViewPagination
  scrollPointer?: InputMaybe<Scalars['String']>
  topMonths: Scalars['Int']
}

/** Only authenticated user from the list can access media by sharing link */
export type Restricted = {
  __typename?: 'Restricted'
  kind: AccessLevelEnum
  permissions: Array<SharingLinkPermissionTuple>
}

export type RestrictedInput = {
  notifyUsers: Scalars['Boolean']
  permissions: Array<SharingLinkPermissionInputTuple>
}

export enum RestrictionEnum {
  Favorites = 'FAVORITES',
  Hosting = 'HOSTING',
  ImageEditor = 'IMAGE_EDITOR',
  ModifyAlbums = 'MODIFY_ALBUMS',
  Search = 'SEARCH',
  Sharing = 'SHARING',
  SortByTitle = 'SORT_BY_TITLE',
  SortByUploadDate = 'SORT_BY_UPLOAD_DATE',
  UserTags = 'USER_TAGS',
  VideoPlayer = 'VIDEO_PLAYER'
}

export type S3Item = {
  __typename?: 'S3Item'
  /** S3 modification date in ISO format. Example: 2022-12-01T12:17:35.000Z */
  creationDate: Scalars['String']
  /** S3 filename value, part of S3 KEY */
  fileName?: Maybe<Scalars['String']>
  /** S3 object ETag value */
  md5Sum: Scalars['String']
}

export type Scroll = {
  direction: ScrollDirection
  scrollPointer: Scalars['String']
}

export enum ScrollDirection {
  Down = 'DOWN',
  Up = 'UP'
}

export type ScrollPointer = {
  __typename?: 'ScrollPointer'
  id: Scalars['String']
  scrollPointer?: Maybe<Scalars['String']>
}

export type SessionCookie = {
  __typename?: 'SessionCookie'
  sessionCookie?: Maybe<Scalars['String']>
}

export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE',
  NoAnswer = 'NO_ANSWER'
}

export enum ShareByEmailMediaType {
  Album = 'ALBUM',
  Image = 'IMAGE',
  Slideshow = 'SLIDESHOW'
}

export type ShareOptions = {
  __typename?: 'ShareOptions'
  directLink?: Maybe<Scalars['Boolean']>
  htmlCode?: Maybe<Scalars['Boolean']>
  htmlThumb?: Maybe<Scalars['Boolean']>
  img?: Maybe<Scalars['Boolean']>
  imgThumb?: Maybe<Scalars['Boolean']>
}

export type ShareOptionsInput = {
  directLink?: InputMaybe<Scalars['Boolean']>
  htmlCode?: InputMaybe<Scalars['Boolean']>
  htmlThumb?: InputMaybe<Scalars['Boolean']>
  img?: InputMaybe<Scalars['Boolean']>
  imgThumb?: InputMaybe<Scalars['Boolean']>
}

export type SharedImage = {
  __typename?: 'SharedImage'
  /** @deprecated Clarifai was disabled */
  clarifaiTags: Array<Maybe<Scalars['String']>>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** @deprecated Use originalImage */
  image: ImageFile
  /** @deprecated Use id */
  imageId: Scalars['ID']
  isVideoType?: Maybe<Scalars['Boolean']>
  nsfw: Scalars['Boolean']
  originalFilename: Scalars['String']
  originalImage: ImageFile
  /** @deprecated As we switched to CDN */
  postProcess?: Maybe<ImageProcessStatus>
  /** @deprecated Use originalImage */
  thumbnailImage: ImageFile
  title: Scalars['String']
  uploadDate: Scalars['String']
  userTags: Array<Maybe<Scalars['String']>>
  username: Scalars['String']
}

export type SharedItem = {
  __typename?: 'SharedItem'
  /** Set of permissions */
  accessLevel: AccessLevel
  /** Once specific date is reached, sharing link will be marked as expired and will not be longer provided to the client and media will not be longer accessible. Format: ISO 8601, YYYY-MM-DD */
  expirationDate?: Maybe<Scalars['String']>
  /** Unique link id. Part of URL */
  id: Scalars['ID']
  /** Amount of images in shared album, or image count if sharing type is MEDIA */
  itemsCount: Scalars['Int']
  /** App link itself */
  link: Scalars['String']
  /** User created sharing link */
  owner: Scalars['ID']
  /** Media previews to create grid */
  previews: Array<MediaPreview>
  /** State creation date */
  sharingDate: Scalars['String']
  /** To distinguish shared album from shared images */
  sharingType: SharingType
}

export type SharedResult = {
  __typename?: 'SharedResult'
  items: Array<Maybe<SharedItem>>
  scrollPointer?: Maybe<Scalars['String']>
}

export type SharingLink = {
  __typename?: 'SharingLink'
  accessLevel: AccessLevel
  /** Once specific date is reached, sharing link will be marked as expired and will not be longer provided to the client and media will not be longer accessible. Format: ISO 8601, YYYY-MM-DD */
  expirationDate?: Maybe<Scalars['String']>
  /** Unique link id. Part of URL */
  id: Scalars['ID']
  /** App link itself */
  link: Scalars['String']
  /** Indicates whether this link can be resolved */
  linkStatus: LinkStatus
}

export type SharingLinkPermissionInputTuple = {
  identifier: Scalars['String']
  userRole: UserRole
}

export type SharingLinkPermissionTuple = {
  __typename?: 'SharingLinkPermissionTuple'
  identifier: Scalars['String']
  userRole: UserRole
}

export type SharingLinksRestrictedTuple = {
  identifiers: Array<Scalars['String']>
  notify: Scalars['Boolean']
  userRole: UserRole
}

export enum SharingType {
  Album = 'ALBUM',
  Media = 'MEDIA'
}

export type ShutterStockAsset = {
  __typename?: 'ShutterStockAsset'
  huge_thumb?: Maybe<ShutterStockImage>
  large_thumb?: Maybe<ShutterStockImage>
  preview?: Maybe<ShutterStockImage>
  small_thumb?: Maybe<ShutterStockImage>
}

export type ShutterStockConnection = {
  __typename?: 'ShutterStockConnection'
  data?: Maybe<Array<Maybe<ShutterStockNode>>>
  page?: Maybe<Scalars['Int']>
  per_page?: Maybe<Scalars['Int']>
  search_id?: Maybe<Scalars['ID']>
  total_count?: Maybe<Scalars['Int']>
}

export type ShutterStockContributor = {
  __typename?: 'ShutterStockContributor'
  id?: Maybe<Scalars['ID']>
}

export type ShutterStockImage = {
  __typename?: 'ShutterStockImage'
  height?: Maybe<Scalars['Int']>
  url?: Maybe<Scalars['String']>
  width?: Maybe<Scalars['Int']>
}

export type ShutterStockNode = {
  __typename?: 'ShutterStockNode'
  affiliate_url?: Maybe<Scalars['String']>
  aspect?: Maybe<Scalars['Float']>
  assets?: Maybe<ShutterStockAsset>
  contributor?: Maybe<ShutterStockContributor>
  description?: Maybe<Scalars['String']>
  has_model_release?: Maybe<Scalars['Boolean']>
  id?: Maybe<Scalars['ID']>
  image_type?: Maybe<Scalars['String']>
  media_type?: Maybe<Scalars['String']>
}

export type Sorter = {
  desc?: InputMaybe<Scalars['Boolean']>
  field?: InputMaybe<SortingField>
}

export type Sorting = {
  __typename?: 'Sorting'
  desc?: Maybe<Scalars['Boolean']>
  field?: Maybe<SortingField>
}

export enum SortingField {
  Date = 'DATE',
  DateTaken = 'DATE_TAKEN',
  Title = 'TITLE'
}

export type SubAlbum = {
  __typename?: 'SubAlbum'
  /** @deprecated Use id */
  albumId: Scalars['ID']
  hasNestedAlbums: Scalars['Boolean']
  id: Scalars['ID']
  imageCount: Scalars['Int']
  images: Array<Image>
  nestedAlbumsCount?: Maybe<Scalars['Int']>
  parentAlbumId?: Maybe<Scalars['String']>
  privacyMode: AlbumPrivacyMode
  title?: Maybe<Scalars['String']>
}

export type SubAlbumsScrollPointer = {
  __typename?: 'SubAlbumsScrollPointer'
  items: Array<Maybe<SubAlbum>>
  scrollPointer?: Maybe<Scalars['String']>
}

export type Subscription = {
  __typename?: 'Subscription'
  createdAt: Scalars['String']
  customPlanProperties?: Maybe<CustomPlanProperties>
  expirationDate?: Maybe<Scalars['String']>
  firstBillingDate?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isActive?: Maybe<Scalars['Boolean']>
  isCanceled?: Maybe<Scalars['Boolean']>
  isTrial?: Maybe<Scalars['Boolean']>
  lastBillingDate?: Maybe<Scalars['String']>
  planName?: Maybe<Scalars['String']>
  recurring?: Maybe<Scalars['Boolean']>
  subscription?: Maybe<SubscriptionInfo>
  trialExpDate?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId: Scalars['String']
}

export enum SubscriptionAccount {
  Apple = 'APPLE',
  BraintreeDi = 'BRAINTREE_DI',
  BraintreeLegacy = 'BRAINTREE_LEGACY',
  Paddle = 'PADDLE',
  Paypal = 'PAYPAL',
  Paypal_2 = 'PAYPAL_2',
  Unknown = 'UNKNOWN',
  Vindicia = 'VINDICIA'
}

export type SubscriptionInfo = {
  __typename?: 'SubscriptionInfo'
  account?: Maybe<SubscriptionAccount>
  id?: Maybe<Scalars['ID']>
  status?: Maybe<SubscriptionStatus>
}

export type SubscriptionRestriction = {
  __typename?: 'SubscriptionRestriction'
  key: RestrictionEnum
  value: Scalars['Boolean']
}

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Deactivated = 'DEACTIVATED',
  Expired = 'EXPIRED',
  PastDue = 'PAST_DUE',
  Pending = 'PENDING',
  Predeactivated = 'PREDEACTIVATED'
}

export type SupportedFileTypes = {
  __typename?: 'SupportedFileTypes'
  imageTypes: Array<Maybe<Scalars['String']>>
  maxImageFileSizeInBytes: Scalars['Float']
  maxVideoFileSizeInBytes: Scalars['Float']
  videoTypes: Array<Maybe<Scalars['String']>>
}

export type Threshold = {
  __typename?: 'Threshold'
  maxSpace?: Maybe<Scalars['Float']>
  usedSpace: Scalars['Float']
}

export type TimeWindow = {
  __typename?: 'TimeWindow'
  end?: Maybe<Scalars['String']>
  start: Scalars['String']
}

export type TokenExchange = {
  __typename?: 'TokenExchange'
  customToken: Scalars['String']
  sessionCookie: Scalars['String']
}

export enum TokenType {
  CustomAuthToken = 'CUSTOM_AUTH_TOKEN',
  IdToken = 'ID_TOKEN',
  Jwt = 'JWT'
}

export type TrashSorter = {
  __typename?: 'TrashSorter'
  desc: Scalars['Boolean']
}

export type TrashSorterInput = {
  desc: Scalars['Boolean']
}

export type UsageReason = {
  __typename?: 'UsageReason'
  business?: Maybe<Scalars['Boolean']>
  hosting?: Maybe<Scalars['Boolean']>
  personal?: Maybe<Scalars['Boolean']>
  sharing?: Maybe<Scalars['Boolean']>
  storage?: Maybe<Scalars['Boolean']>
}

export type UsageReasonInput = {
  business?: InputMaybe<Scalars['Boolean']>
  hosting?: InputMaybe<Scalars['Boolean']>
  personal?: InputMaybe<Scalars['Boolean']>
  sharing?: InputMaybe<Scalars['Boolean']>
  storage?: InputMaybe<Scalars['Boolean']>
}

export type UsageReasons = {
  __typename?: 'UsageReasons'
  bussiness: Scalars['Boolean']
  hosting: Scalars['Boolean']
  personal: Scalars['Boolean']
  personalSharing: Scalars['Boolean']
  storage: Scalars['Boolean']
}

export type UsageReasonsInput = {
  bussiness: Scalars['Boolean']
  hosting: Scalars['Boolean']
  personal: Scalars['Boolean']
  personalSharing: Scalars['Boolean']
  storage: Scalars['Boolean']
}

export type UserImagesScrollPointer = {
  __typename?: 'UserImagesScrollPointer'
  items: Array<Maybe<Image>>
  scrollPointer?: Maybe<Scalars['String']>
}

export type UserLoginRuleResponse = {
  __typename?: 'UserLoginRuleResponse'
  discount?: Maybe<Scalars['String']>
  displayHeadline?: Maybe<Scalars['String']>
  displayMessage?: Maybe<Scalars['String']>
  isNewsBarDisplayed?: Maybe<Scalars['Boolean']>
  newsBarMessage?: Maybe<Scalars['String']>
  planId: UserPlanId
  redirectUri?: Maybe<Scalars['String']>
  upgradePlan?: Maybe<Scalars['String']>
  userId: Scalars['String']
}

export enum UserMigrationStatus {
  Completed = 'COMPLETED',
  Dormant = 'DORMANT',
  InProgress = 'IN_PROGRESS',
  Need = 'NEED',
  Synced = 'SYNCED'
}

export enum UserPlanId {
  Beginner = 'BEGINNER',
  Expert = 'EXPERT',
  Free = 'FREE',
  Intermediate = 'INTERMEDIATE',
  Vault = 'VAULT'
}

export type UserProfile = {
  __typename?: 'UserProfile'
  autoBackupSettings?: Maybe<BucketAutoBackupSettings>
  counters?: Maybe<ProfileCounters>
  country?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  currentBucketId?: Maybe<Scalars['String']>
  desktopSettings?: Maybe<BucketGallerySettings>
  /** This `email` field should only be used for display purposes. For functional purposes use the firebase auth sdk as the source of truth. */
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  gender?: Maybe<Gender>
  groupBucketUploadConsent?: Maybe<Scalars['Boolean']>
  id: Scalars['ID']
  isQuickLinksEnabled?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  latestTermsAccepted?: Maybe<Scalars['Boolean']>
  legacyBucketId?: Maybe<Scalars['String']>
  linkSettings?: Maybe<LinkSettings>
  migrationMetadata?: Maybe<ProfileMigrationMetadata>
  mobileSettings?: Maybe<BucketGallerySettings>
  name?: Maybe<Scalars['String']>
  optIntoMarketingEmails?: Maybe<MarketingPreference>
  plan?: Maybe<Plan>
  state?: Maybe<Scalars['String']>
  subscription?: Maybe<Subscription>
  updatedAt?: Maybe<Scalars['String']>
  usageReason?: Maybe<UsageReason>
  userStatus?: Maybe<ProfileStatus>
  zipCode?: Maybe<Scalars['String']>
}

export type UserProfileInput = {
  autoBackupSettings?: InputMaybe<BucketAutoBackupSettingsInput>
  country?: InputMaybe<Scalars['String']>
  currentBucketId?: InputMaybe<Scalars['String']>
  desktopSettings?: InputMaybe<BucketGallerySettingsInput>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Gender>
  groupBucketUploadConsent?: InputMaybe<Scalars['Boolean']>
  isQuickLinksEnabled?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  linkSettings?: InputMaybe<LinkSettingsInput>
  mobileSettings?: InputMaybe<BucketGallerySettingsInput>
  optIntoMarketingEmails?: InputMaybe<MarketingPreferenceInput>
  state?: InputMaybe<Scalars['String']>
  termsAccepted?: InputMaybe<Scalars['Boolean']>
  usageReason?: InputMaybe<UsageReasonInput>
  zipCode?: InputMaybe<Scalars['String']>
}

export enum UserRole {
  Commenter = 'COMMENTER',
  NoAccess = 'NO_ACCESS',
  Owner = 'OWNER',
  Viewer = 'VIEWER'
}

export type UserStateStatus = {
  __typename?: 'UserStateStatus'
  firebase: Scalars['Boolean']
  kafka: Scalars['Boolean']
  rds: Scalars['Boolean']
  userId?: Maybe<Scalars['String']>
}

export type UserStorageStatistics = {
  maxBandwidth?: InputMaybe<Scalars['Float']>
  maxMediaCount?: InputMaybe<Scalars['Float']>
  maxMediaSpace?: InputMaybe<Scalars['Float']>
  usedBandwidth?: InputMaybe<Scalars['Float']>
  usedMediaCount?: InputMaybe<Scalars['Float']>
  usedMediaSpace?: InputMaybe<Scalars['Float']>
}

export type UserSubscription = {
  __typename?: 'UserSubscription'
  account?: Maybe<BillingAccount>
  /** @deprecated Not used with current payment provider */
  addons: Array<Maybe<Scalars['String']>>
  /** @deprecated Moved to another payment gateway */
  checkPaymentMethodPresence?: Maybe<Scalars['Boolean']>
  expDate?: Maybe<Scalars['String']>
  firstBillingDate?: Maybe<Scalars['String']>
  isTrial: Scalars['Boolean']
  lastBillingDate?: Maybe<Scalars['String']>
  notifications: Array<Maybe<ModalNotificationType>>
  /** @deprecated Not used with current payment provider */
  pendingSubscription?: Maybe<PendingSubscription>
  plan: UserSubscriptionPlan
  planId: Scalars['String']
  recurring?: Maybe<Scalars['Boolean']>
  restrictions?: Maybe<Array<SubscriptionRestriction>>
  subscriptionStatus?: Maybe<UserSubscriptionStatus>
  thresholdDetails?: Maybe<Threshold>
  totalImagesCount: Scalars['Int']
  totalUserUsedSpace: Scalars['Float']
  trialExpDate?: Maybe<Scalars['String']>
  userId: Scalars['ID']
}

export type UserSubscriptionPlanArgs = {
  login?: InputMaybe<Scalars['String']>
}

export type UserSubscriptionPlan = {
  __typename?: 'UserSubscriptionPlan'
  /** @deprecated Not used with current payment provider */
  addons: Array<Maybe<UserSubscriptionPlanAddon>>
  ads: Scalars['Boolean']
  allowed: Scalars['Boolean']
  billingCycleMonths?: Maybe<Scalars['Int']>
  /** @deprecated Not used with current payment provider */
  bt: BtIds
  currency: Scalars['String']
  /** @deprecated Not used with current payment provider */
  defaultAddons: Array<Maybe<UserSubscriptionPlanAddon>>
  downloadOrigin: Scalars['Boolean']
  hostingThreshold?: Maybe<HostingThreshold>
  imageEditorAllowed: Scalars['Boolean']
  keepOriginalImage: Scalars['Boolean']
  linking: Scalars['Boolean']
  maxImagesCount?: Maybe<Scalars['Int']>
  maxSpace?: Maybe<Scalars['Float']>
  nsfwUpload: Scalars['Boolean']
  passwordProtectedAlbumsAllowed: Scalars['Boolean']
  planId: Scalars['String']
  price: Scalars['Float']
  privateAlbumsAllowed: Scalars['Boolean']
  sharePrivate: Scalars['Boolean']
  title: Scalars['String']
  trialPeriod: Scalars['Int']
}

export type UserSubscriptionPlanAddon = {
  __typename?: 'UserSubscriptionPlanAddon'
  billingCycleMonths: Scalars['Int']
  braintreeId: Scalars['String']
  currency: Scalars['String']
  price: Scalars['Float']
  title: Scalars['String']
}

export enum UserSubscriptionStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Deactivated = 'DEACTIVATED',
  Expired = 'EXPIRED',
  PastDue = 'PAST_DUE',
  Pending = 'PENDING',
  Predeactivated = 'PREDEACTIVATED',
  Unrecognized = 'UNRECOGNIZED'
}

export type ViewMore = {
  __typename?: 'ViewMore'
  itemsCount: Scalars['Int']
  mediaCount: Scalars['Int']
}

export type ViewPagination = {
  desc: Scalars['Boolean']
  pageSize: Scalars['Int']
  sortingField: DateSortingField
}

export enum ViolationType {
  Community = 'COMMUNITY',
  Terms = 'TERMS'
}

export type YearViewData = {
  __typename?: 'YearViewData'
  items: Array<BucketMedia>
  mediaCount: Scalars['Int']
  month: Scalars['Int']
}

export type YearViewResult = {
  __typename?: 'YearViewResult'
  data: Array<Maybe<YearViewData>>
  totalMedia: Scalars['Int']
  year: Scalars['Int']
}

export type YearsPreviewStatsItem = {
  __typename?: 'YearsPreviewStatsItem'
  mediaCount: Scalars['Int']
  month: Scalars['String']
  previewImage: Image
}

export type YearsView = {
  __typename?: 'YearsView'
  items: Array<Maybe<YearsViewItem>>
  scrollPointer?: Maybe<Scalars['String']>
}

export type YearsViewItem = {
  __typename?: 'YearsViewItem'
  previewStats: Array<Maybe<YearsPreviewStatsItem>>
  year: Scalars['String']
}

export type BucketBulkDownloadFragmentFragment = {
  __typename?: 'BucketBulkDownload'
  id: string
  userId: string
  createdAt: string
  scheduledDeletionAt?: string | null
  archives?: Array<{ __typename?: 'DownloadLink'; url: string; totalMediaCount: number; size: number }> | null
}

export type AddUserTagsMutationVariables = Exact<{
  imageIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type AddUserTagsMutation = { __typename?: 'Mutation'; addUserTags: { __typename?: 'AddTagsResult'; blacklisted: Array<string | null> } }

export type AlbumCreateMutationVariables = Exact<{
  parentAlbumId: Scalars['String']
  title: Scalars['String']
}>

export type AlbumCreateMutation = {
  __typename?: 'Mutation'
  albumCreate: {
    __typename?: 'AlbumV2'
    id: string
    title: string
    privacyMode: AlbumPrivacyMode
    parentAlbumId?: string | null
    description?: string | null
    owner: string
    counters: { __typename?: 'AlbumCounters'; imageCountIncludeSubAlbums: number; imageCount: number; nestedAlbumsCount: number }
  }
}

export type AlbumFragmentFragment = {
  __typename?: 'AlbumV2'
  id: string
  title: string
  privacyMode: AlbumPrivacyMode
  parentAlbumId?: string | null
  description?: string | null
  owner: string
  counters: { __typename?: 'AlbumCounters'; imageCountIncludeSubAlbums: number; imageCount: number; nestedAlbumsCount: number }
}

export type AlbumUpdateMutationVariables = Exact<{
  id: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  privacyMode?: InputMaybe<AlbumPrivacyMode>
  addPasswords?: InputMaybe<Array<InputMaybe<AlbumPasswordInput>> | InputMaybe<AlbumPasswordInput>>
  removePasswords?: InputMaybe<Array<InputMaybe<AlbumPasswordInput>> | InputMaybe<AlbumPasswordInput>>
}>

export type AlbumUpdateMutation = {
  __typename?: 'Mutation'
  albumUpdate: {
    __typename?: 'AlbumV2'
    id: string
    title: string
    privacyMode: AlbumPrivacyMode
    parentAlbumId?: string | null
    description?: string | null
    owner: string
    counters: { __typename?: 'AlbumCounters'; imageCountIncludeSubAlbums: number; imageCount: number; nestedAlbumsCount: number }
  }
}

export type AlbumsMoveToTrashMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String']
}>

export type AlbumsMoveToTrashMutation = { __typename?: 'Mutation'; albumsMoveToTrash: Array<string> }

export type AlbumsReadAllQueryVariables = Exact<{
  sortBy: Sorter
}>

export type AlbumsReadAllQuery = {
  __typename?: 'Query'
  albumsReadAll: Array<{
    __typename?: 'AlbumV2'
    id: string
    title: string
    privacyMode: AlbumPrivacyMode
    parentAlbumId?: string | null
    description?: string | null
    owner: string
    counters: { __typename?: 'AlbumCounters'; imageCountIncludeSubAlbums: number; imageCount: number; nestedAlbumsCount: number }
  }>
}

export type AuthenticatedUserFragmentFragment = {
  __typename?: 'AuthenticatedUser'
  id: string
  token: string
  tokenType: TokenType
  email: string
  phoneNumber?: string | null
  username: string
  birthDate?: string | null
  defaultAlbum: string
  mobileAlbum?: string | null
  firstName?: string | null
  lastName?: string | null
  country?: string | null
  zip?: string | null
  sex?: Sex | null
  stateOrProvince?: string | null
  createdAt?: string | null
  emailStatus: EmailStatus
  isPasswordExpired: boolean
  migrationStatus?: UserMigrationStatus | null
  albumsSettings: {
    __typename?: 'AlbumsSettings'
    easyLinkingMode: boolean
    showImageTitle: boolean
    linkBackOption: boolean
    defaultSortRule: { __typename?: 'Sorting'; desc?: boolean | null; field?: SortingField | null }
    links: {
      __typename?: 'MediaLinksSettings'
      email: boolean
      direct: boolean
      htmlCode: boolean
      htmlThumb: boolean
      imgThumb: boolean
      img: boolean
    }
  }
  usageReasons: { __typename?: 'UsageReasons'; personal: boolean; bussiness: boolean; storage: boolean; hosting: boolean; personalSharing: boolean }
  subscription: {
    __typename?: 'UserSubscription'
    expDate?: string | null
    subscriptionStatus?: UserSubscriptionStatus | null
    recurring?: boolean | null
    account?: BillingAccount | null
    totalUserUsedSpace: number
    totalImagesCount: number
    isTrial: boolean
    notifications: Array<ModalNotificationType | null>
    trialExpDate?: string | null
    firstBillingDate?: string | null
    lastBillingDate?: string | null
    restrictions?: Array<{ __typename?: 'SubscriptionRestriction'; key: RestrictionEnum; value: boolean }> | null
    thresholdDetails?: { __typename?: 'Threshold'; usedSpace: number; maxSpace?: number | null } | null
    plan: {
      __typename?: 'UserSubscriptionPlan'
      maxImagesCount?: number | null
      maxSpace?: number | null
      planId: string
      title: string
      hostingThreshold?: { __typename?: 'HostingThreshold'; maxHostingThreshold: number } | null
    }
  }
}

export type BanImagesMutationVariables = Exact<{
  imageIds: Array<Scalars['String']> | Scalars['String']
}>

export type BanImagesMutation = {
  __typename?: 'Mutation'
  imagesBan: {
    __typename?: 'ImageScrollPointer'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    } | null>
  }
}

export type BucketQueryVariables = Exact<{
  bucketId: Scalars['ID']
}>

export type BucketQuery = {
  __typename?: 'Query'
  bucket?: {
    __typename?: 'Bucket'
    id: string
    architecture?: Architecture | null
    description?: string | null
    bucketStatus?: BucketStatus | null
    bucketType: BucketType
    bucketUserCount?: number | null
    isConverted?: boolean | null
    defaultBucket?: boolean | null
    defaultSortRule: BucketSortingField
    title: string
    ownerId?: string | null
    subscriptionStatus?: SubscriptionStatus | null
    isAdmin?: boolean | null
    isBanned?: boolean | null
    bucketSettings?: {
      __typename?: 'BucketSettings'
      easyLinkingMode?: boolean | null
      linkBackToAlbums?: boolean | null
      showImageTitle?: boolean | null
      shareOptions?: {
        __typename?: 'ShareOptions'
        directLink?: boolean | null
        htmlCode?: boolean | null
        htmlThumb?: boolean | null
        img?: boolean | null
        imgThumb?: boolean | null
      } | null
    } | null
    counters?: {
      __typename?: 'BucketCounters'
      totalMedia?: number | null
      totalMediaInTrash?: number | null
      totalMediaMinusTrash?: number | null
      totalSize?: number | null
      totalSizeInTrash?: number | null
      totalSizeMinusTrash?: number | null
      totalAlbumCount?: number | null
    } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
  } | null
}

export type BucketActivitiesQueryVariables = Exact<{
  bucketId: Scalars['ID']
  limit: Scalars['Int']
  timezone?: InputMaybe<Scalars['String']>
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketActivitiesQuery = {
  __typename?: 'Query'
  bucketActivities?: {
    __typename?: 'BucketActivitiesResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketActivitiesByDate'
      bucketId: string
      date: string
      activities?: Array<
        | {
            __typename?: 'BucketJoinedMembersActivity'
            timestamp: string
            user: { __typename?: 'BucketActivityUser'; userId: string; firstName?: string | null; lastName?: string | null }
          }
        | {
            __typename?: 'BucketUploadedMediaActivity'
            count: number
            timestamp: string
            user: { __typename?: 'BucketActivityUser'; userId: string; firstName?: string | null; lastName?: string | null }
            albums: Array<{
              __typename?: 'BucketAlbum'
              bucketId: string
              description?: string | null
              id: string
              parentId?: string | null
              subAlbumCount?: number | null
              title: string
              counters?: { __typename?: 'BucketAlbumCounters'; totalSubalbums?: number | null; totalMedia?: number | null } | null
              thumbnails?: Array<{
                __typename?: 'BucketMedia'
                aiTags?: Array<string> | null
                albumId?: string | null
                bucketId: string
                createdAt?: string | null
                dateTaken?: string | null
                description?: string | null
                filename: string
                fileSize?: number | null
                height?: number | null
                id: string
                imageUrl?: string | null
                isVideo?: boolean | null
                isMobile?: boolean | null
                isBanned?: boolean | null
                isFavorite?: boolean | null
                mediaType: string
                moderationStatus?: BucketMediaModerationStatus | null
                originalFilename?: string | null
                scheduledDeletionAt?: string | null
                signedUrl?: string | null
                title?: string | null
                userId?: string | null
                userTags?: Array<string> | null
                width?: number | null
                livePhoto?: { __typename?: 'BucketMediaLivePhoto'; filename?: string | null; fileSize?: number | null } | null
              } | null> | null
            }>
            media: Array<{
              __typename?: 'BucketMedia'
              aiTags?: Array<string> | null
              albumId?: string | null
              bucketId: string
              createdAt?: string | null
              dateTaken?: string | null
              description?: string | null
              filename: string
              fileSize?: number | null
              height?: number | null
              id: string
              imageUrl?: string | null
              isVideo?: boolean | null
              isMobile?: boolean | null
              isBanned?: boolean | null
              isFavorite?: boolean | null
              mediaType: string
              moderationStatus?: BucketMediaModerationStatus | null
              originalFilename?: string | null
              scheduledDeletionAt?: string | null
              signedUrl?: string | null
              title?: string | null
              userId?: string | null
              userTags?: Array<string> | null
              width?: number | null
              livePhoto?: { __typename?: 'BucketMediaLivePhoto'; filename?: string | null; fileSize?: number | null } | null
            }>
            window: { __typename?: 'TimeWindow'; start: string; end?: string | null }
          }
      > | null
    }>
  } | null
}

export type BucketAlbumQueryVariables = Exact<{
  bucketId: Scalars['ID']
  albumId: Scalars['ID']
}>

export type BucketAlbumQuery = {
  __typename?: 'Query'
  bucket?: {
    __typename?: 'Bucket'
    id: string
    architecture?: Architecture | null
    description?: string | null
    bucketStatus?: BucketStatus | null
    bucketType: BucketType
    bucketUserCount?: number | null
    isConverted?: boolean | null
    defaultBucket?: boolean | null
    defaultSortRule: BucketSortingField
    title: string
    ownerId?: string | null
    subscriptionStatus?: SubscriptionStatus | null
    isAdmin?: boolean | null
    isBanned?: boolean | null
    bucketSettings?: {
      __typename?: 'BucketSettings'
      easyLinkingMode?: boolean | null
      linkBackToAlbums?: boolean | null
      showImageTitle?: boolean | null
      shareOptions?: {
        __typename?: 'ShareOptions'
        directLink?: boolean | null
        htmlCode?: boolean | null
        htmlThumb?: boolean | null
        img?: boolean | null
        imgThumb?: boolean | null
      } | null
    } | null
    counters?: {
      __typename?: 'BucketCounters'
      totalMedia?: number | null
      totalMediaInTrash?: number | null
      totalMediaMinusTrash?: number | null
      totalSize?: number | null
      totalSizeInTrash?: number | null
      totalSizeMinusTrash?: number | null
      totalAlbumCount?: number | null
    } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
  } | null
  bucketAlbum?: {
    __typename?: 'BucketAlbum'
    id: string
    title: string
    description?: string | null
    subAlbumCount?: number | null
    bucketId: string
    parentId?: string | null
    counters?: { __typename?: 'BucketAlbumCounters'; totalSubalbums?: number | null; totalMedia?: number | null } | null
    thumbnails?: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
    } | null> | null
  } | null
}

export type BucketAlbumCreateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  data?: InputMaybe<BucketAlbumInput>
}>

export type BucketAlbumCreateMutation = {
  __typename?: 'Mutation'
  bucketAlbumCreate?: {
    __typename?: 'BucketAlbum'
    id: string
    title: string
    description?: string | null
    subAlbumCount?: number | null
    bucketId: string
    parentId?: string | null
    counters?: { __typename?: 'BucketAlbumCounters'; totalSubalbums?: number | null; totalMedia?: number | null } | null
    thumbnails?: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
    } | null> | null
  } | null
}

export type BucketAlbumFragmentFragment = {
  __typename?: 'BucketAlbum'
  id: string
  title: string
  description?: string | null
  subAlbumCount?: number | null
  bucketId: string
  parentId?: string | null
  counters?: { __typename?: 'BucketAlbumCounters'; totalSubalbums?: number | null; totalMedia?: number | null } | null
  thumbnails?: Array<{
    __typename?: 'BucketMedia'
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    dateTaken?: string | null
    description?: string | null
    filename: string
    fileSize?: number | null
    height?: number | null
    id: string
    imageUrl?: string | null
    isVideo?: boolean | null
    mediaType: string
    scheduledDeletionAt?: string | null
    title?: string | null
    userId?: string | null
    userTags?: Array<string> | null
    width?: number | null
  } | null> | null
}

export type BucketAlbumUpdateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  albumId: Scalars['ID']
  data: BucketAlbumInput
}>

export type BucketAlbumUpdateMutation = {
  __typename?: 'Mutation'
  bucketAlbumUpdate?: {
    __typename?: 'BucketAlbum'
    id: string
    title: string
    description?: string | null
    subAlbumCount?: number | null
    bucketId: string
    parentId?: string | null
    counters?: { __typename?: 'BucketAlbumCounters'; totalSubalbums?: number | null; totalMedia?: number | null } | null
    thumbnails?: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
    } | null> | null
  } | null
}

export type BucketAlbumsQueryVariables = Exact<{
  bucketId: Scalars['ID']
  albumId?: InputMaybe<Scalars['ID']>
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketAlbumsQuery = {
  __typename?: 'Query'
  bucketAlbums?: {
    __typename?: 'BucketAlbumsResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketAlbum'
      id: string
      title: string
      description?: string | null
      subAlbumCount?: number | null
      bucketId: string
      parentId?: string | null
      counters?: { __typename?: 'BucketAlbumCounters'; totalSubalbums?: number | null; totalMedia?: number | null } | null
      thumbnails?: Array<{
        __typename?: 'BucketMedia'
        albumId?: string | null
        bucketId: string
        createdAt?: string | null
        dateTaken?: string | null
        description?: string | null
        filename: string
        fileSize?: number | null
        height?: number | null
        id: string
        imageUrl?: string | null
        isVideo?: boolean | null
        mediaType: string
        scheduledDeletionAt?: string | null
        title?: string | null
        userId?: string | null
        userTags?: Array<string> | null
        width?: number | null
      } | null> | null
    }>
  } | null
}

export type BucketAlbumsMoveMutationVariables = Exact<{
  bucketId: Scalars['ID']
  albumIds: Array<Scalars['ID']> | Scalars['ID']
  data?: InputMaybe<BucketAlbumsMoveInput>
}>

export type BucketAlbumsMoveMutation = { __typename?: 'Mutation'; bucketAlbumsMove?: Array<string> | null }

export type BucketAlbumsMoveCopyBetweenBucketsMutationVariables = Exact<{
  bucketId: Scalars['ID']
  albumIds: Array<Scalars['ID']> | Scalars['ID']
  data: BucketAlbumsMoveCopyBetweenBucketsInput
}>

export type BucketAlbumsMoveCopyBetweenBucketsMutation = { __typename?: 'Mutation'; bucketAlbumsMoveCopyBetweenBuckets?: boolean | null }

export type BucketAndAlbumsAndUserQueryVariables = Exact<{
  bucketId: Scalars['ID']
  userId: Scalars['ID']
  albumId?: InputMaybe<Scalars['ID']>
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketAndAlbumsAndUserQuery = {
  __typename?: 'Query'
  bucket?: {
    __typename?: 'Bucket'
    id: string
    architecture?: Architecture | null
    description?: string | null
    bucketStatus?: BucketStatus | null
    bucketType: BucketType
    bucketUserCount?: number | null
    isConverted?: boolean | null
    defaultBucket?: boolean | null
    defaultSortRule: BucketSortingField
    title: string
    ownerId?: string | null
    subscriptionStatus?: SubscriptionStatus | null
    isAdmin?: boolean | null
    isBanned?: boolean | null
    bucketSettings?: {
      __typename?: 'BucketSettings'
      easyLinkingMode?: boolean | null
      linkBackToAlbums?: boolean | null
      showImageTitle?: boolean | null
      shareOptions?: {
        __typename?: 'ShareOptions'
        directLink?: boolean | null
        htmlCode?: boolean | null
        htmlThumb?: boolean | null
        img?: boolean | null
        imgThumb?: boolean | null
      } | null
    } | null
    counters?: {
      __typename?: 'BucketCounters'
      totalMedia?: number | null
      totalMediaInTrash?: number | null
      totalMediaMinusTrash?: number | null
      totalSize?: number | null
      totalSizeInTrash?: number | null
      totalSizeMinusTrash?: number | null
      totalAlbumCount?: number | null
    } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
  } | null
  bucketAlbums?: {
    __typename?: 'BucketAlbumsResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketAlbum'
      id: string
      title: string
      description?: string | null
      subAlbumCount?: number | null
      bucketId: string
      parentId?: string | null
      counters?: { __typename?: 'BucketAlbumCounters'; totalSubalbums?: number | null; totalMedia?: number | null } | null
      thumbnails?: Array<{
        __typename?: 'BucketMedia'
        albumId?: string | null
        bucketId: string
        createdAt?: string | null
        dateTaken?: string | null
        description?: string | null
        filename: string
        fileSize?: number | null
        height?: number | null
        id: string
        imageUrl?: string | null
        isVideo?: boolean | null
        mediaType: string
        scheduledDeletionAt?: string | null
        title?: string | null
        userId?: string | null
        userTags?: Array<string> | null
        width?: number | null
      } | null> | null
    }>
  } | null
  bucketUser?: {
    __typename?: 'BucketUser'
    id: string
    userStatus: BucketUserStatus
    userRole: BucketUserRole
    updated?: string | null
    bucketId: string
    permissions?: {
      __typename?: 'PermissionPolicy'
      albumCreate?: boolean | null
      albumDelete?: boolean | null
      albumGet?: boolean | null
      albumUpdate?: boolean | null
      bucketCreate?: boolean | null
      bucketDelete?: boolean | null
      bucketGet?: boolean | null
      bucketUpdate?: boolean | null
      invitationCreate?: boolean | null
      invitationDelete?: boolean | null
      invitationGet?: boolean | null
      invitationUpdate?: boolean | null
      mediaCreate?: boolean | null
      mediaDelete?: boolean | null
      mediaGet?: boolean | null
      mediaUpdate?: boolean | null
      userCreate?: boolean | null
      userDelete?: boolean | null
      userGet?: boolean | null
      userList?: boolean | null
      userUpdate?: boolean | null
    } | null
    userProfile?: {
      __typename?: 'UserProfile'
      country?: string | null
      createdAt: string
      currentBucketId?: string | null
      firstName?: string | null
      gender?: Gender | null
      id: string
      isQuickLinksEnabled?: boolean | null
      lastName?: string | null
      latestTermsAccepted?: boolean | null
      legacyBucketId?: string | null
      name?: string | null
      state?: string | null
      updatedAt?: string | null
      userStatus?: ProfileStatus | null
      zipCode?: string | null
      counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
      desktopSettings?: {
        __typename?: 'BucketGallerySettings'
        cropMode?: boolean | null
        groupedBy?: BucketMediaGroupedBy | null
        flatten?: boolean | null
        layout?: BucketGalleryGridLayout | null
        showImageTitle?: boolean | null
        zoom?: number | null
        filterBy?: {
          __typename?: 'BucketUserFilterBy'
          isFavorite?: boolean | null
          mediaType?: BucketMediaTypeFilter | null
          owner?: BucketMediaContentOwner | null
        } | null
        sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
      } | null
      linkSettings?: {
        __typename?: 'LinkSettings'
        directLink?: boolean | null
        htmlLarge?: boolean | null
        htmlThumb?: boolean | null
        imgLarge?: boolean | null
        imgThumb?: boolean | null
      } | null
      migrationMetadata?: {
        __typename?: 'ProfileMigrationMetadata'
        bucketId?: string | null
        migrationStatus: ProfileMigrationStatus
        approximateMediaCount?: number | null
        hasKafkaBucket?: boolean | null
        migratedMediaCount?: number | null
      } | null
      optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
      plan?: {
        __typename?: 'Plan'
        apple_id?: string | null
        billing_interval?: string | null
        is_favorites_enabled?: boolean | null
        is_hosting_enabled?: boolean | null
        is_create_bucket_enabled?: boolean | null
        is_image_editor_enabled?: boolean | null
        is_modify_albums_enabled?: boolean | null
        is_search_enabled?: boolean | null
        is_sharing_enabled?: boolean | null
        is_sharing_plan?: boolean | null
        is_sort_by_title_enabled?: boolean | null
        is_sort_by_upload_date_enabled?: boolean | null
        is_storage_plan?: boolean | null
        is_user_tags_enabled?: boolean | null
        is_video_player_enabled?: boolean | null
        media_limit?: number | null
        paddle_id?: string | null
        paypal_id?: string | null
        plan_name: string
        storage_limit?: number | null
        subscription_tier?: string | null
        trial_days?: number | null
        vindicia_id?: string | null
      } | null
      subscription?: {
        __typename?: 'Subscription'
        id: string
        createdAt: string
        expirationDate?: string | null
        firstBillingDate?: string | null
        isTrial?: boolean | null
        isCanceled?: boolean | null
        lastBillingDate?: string | null
        planName?: string | null
        recurring?: boolean | null
        trialExpDate?: string | null
        updatedAt?: string | null
        userId: string
        customPlanProperties?: {
          __typename?: 'CustomPlanProperties'
          planName?: string | null
          planTitle?: string | null
          storageLimitBytes?: number | null
          mediaLimitCount?: number | null
          hostingIncluded?: boolean | null
          price?: number | null
        } | null
        subscription?: {
          __typename?: 'SubscriptionInfo'
          id?: string | null
          status?: SubscriptionStatus | null
          account?: SubscriptionAccount | null
        } | null
      } | null
      usageReason?: {
        __typename?: 'UsageReason'
        personal?: boolean | null
        business?: boolean | null
        storage?: boolean | null
        hosting?: boolean | null
        sharing?: boolean | null
      } | null
    } | null
  } | null
}

export type BucketAndUsersQueryVariables = Exact<{
  bucketId: Scalars['ID']
  userId: Scalars['ID']
}>

export type BucketAndUsersQuery = {
  __typename?: 'Query'
  bucket?: {
    __typename?: 'Bucket'
    id: string
    architecture?: Architecture | null
    description?: string | null
    bucketStatus?: BucketStatus | null
    bucketType: BucketType
    bucketUserCount?: number | null
    isConverted?: boolean | null
    defaultBucket?: boolean | null
    defaultSortRule: BucketSortingField
    title: string
    ownerId?: string | null
    subscriptionStatus?: SubscriptionStatus | null
    isAdmin?: boolean | null
    isBanned?: boolean | null
    bucketSettings?: {
      __typename?: 'BucketSettings'
      easyLinkingMode?: boolean | null
      linkBackToAlbums?: boolean | null
      showImageTitle?: boolean | null
      shareOptions?: {
        __typename?: 'ShareOptions'
        directLink?: boolean | null
        htmlCode?: boolean | null
        htmlThumb?: boolean | null
        img?: boolean | null
        imgThumb?: boolean | null
      } | null
    } | null
    counters?: {
      __typename?: 'BucketCounters'
      totalMedia?: number | null
      totalMediaInTrash?: number | null
      totalMediaMinusTrash?: number | null
      totalSize?: number | null
      totalSizeInTrash?: number | null
      totalSizeMinusTrash?: number | null
      totalAlbumCount?: number | null
    } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
  } | null
  bucketUser?: {
    __typename?: 'BucketUser'
    id: string
    userStatus: BucketUserStatus
    userRole: BucketUserRole
    updated?: string | null
    bucketId: string
    permissions?: {
      __typename?: 'PermissionPolicy'
      albumCreate?: boolean | null
      albumDelete?: boolean | null
      albumGet?: boolean | null
      albumUpdate?: boolean | null
      bucketCreate?: boolean | null
      bucketDelete?: boolean | null
      bucketGet?: boolean | null
      bucketUpdate?: boolean | null
      invitationCreate?: boolean | null
      invitationDelete?: boolean | null
      invitationGet?: boolean | null
      invitationUpdate?: boolean | null
      mediaCreate?: boolean | null
      mediaDelete?: boolean | null
      mediaGet?: boolean | null
      mediaUpdate?: boolean | null
      userCreate?: boolean | null
      userDelete?: boolean | null
      userGet?: boolean | null
      userList?: boolean | null
      userUpdate?: boolean | null
    } | null
    userProfile?: {
      __typename?: 'UserProfile'
      country?: string | null
      createdAt: string
      currentBucketId?: string | null
      firstName?: string | null
      gender?: Gender | null
      id: string
      isQuickLinksEnabled?: boolean | null
      lastName?: string | null
      latestTermsAccepted?: boolean | null
      legacyBucketId?: string | null
      name?: string | null
      state?: string | null
      updatedAt?: string | null
      userStatus?: ProfileStatus | null
      zipCode?: string | null
      counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
      desktopSettings?: {
        __typename?: 'BucketGallerySettings'
        cropMode?: boolean | null
        groupedBy?: BucketMediaGroupedBy | null
        flatten?: boolean | null
        layout?: BucketGalleryGridLayout | null
        showImageTitle?: boolean | null
        zoom?: number | null
        filterBy?: {
          __typename?: 'BucketUserFilterBy'
          isFavorite?: boolean | null
          mediaType?: BucketMediaTypeFilter | null
          owner?: BucketMediaContentOwner | null
        } | null
        sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
      } | null
      linkSettings?: {
        __typename?: 'LinkSettings'
        directLink?: boolean | null
        htmlLarge?: boolean | null
        htmlThumb?: boolean | null
        imgLarge?: boolean | null
        imgThumb?: boolean | null
      } | null
      migrationMetadata?: {
        __typename?: 'ProfileMigrationMetadata'
        bucketId?: string | null
        migrationStatus: ProfileMigrationStatus
        approximateMediaCount?: number | null
        hasKafkaBucket?: boolean | null
        migratedMediaCount?: number | null
      } | null
      optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
      plan?: {
        __typename?: 'Plan'
        apple_id?: string | null
        billing_interval?: string | null
        is_favorites_enabled?: boolean | null
        is_hosting_enabled?: boolean | null
        is_create_bucket_enabled?: boolean | null
        is_image_editor_enabled?: boolean | null
        is_modify_albums_enabled?: boolean | null
        is_search_enabled?: boolean | null
        is_sharing_enabled?: boolean | null
        is_sharing_plan?: boolean | null
        is_sort_by_title_enabled?: boolean | null
        is_sort_by_upload_date_enabled?: boolean | null
        is_storage_plan?: boolean | null
        is_user_tags_enabled?: boolean | null
        is_video_player_enabled?: boolean | null
        media_limit?: number | null
        paddle_id?: string | null
        paypal_id?: string | null
        plan_name: string
        storage_limit?: number | null
        subscription_tier?: string | null
        trial_days?: number | null
        vindicia_id?: string | null
      } | null
      subscription?: {
        __typename?: 'Subscription'
        id: string
        createdAt: string
        expirationDate?: string | null
        firstBillingDate?: string | null
        isTrial?: boolean | null
        isCanceled?: boolean | null
        lastBillingDate?: string | null
        planName?: string | null
        recurring?: boolean | null
        trialExpDate?: string | null
        updatedAt?: string | null
        userId: string
        customPlanProperties?: {
          __typename?: 'CustomPlanProperties'
          planName?: string | null
          planTitle?: string | null
          storageLimitBytes?: number | null
          mediaLimitCount?: number | null
          hostingIncluded?: boolean | null
          price?: number | null
        } | null
        subscription?: {
          __typename?: 'SubscriptionInfo'
          id?: string | null
          status?: SubscriptionStatus | null
          account?: SubscriptionAccount | null
        } | null
      } | null
      usageReason?: {
        __typename?: 'UsageReason'
        personal?: boolean | null
        business?: boolean | null
        storage?: boolean | null
        hosting?: boolean | null
        sharing?: boolean | null
      } | null
    } | null
  } | null
}

export type BucketBulkDownloadsQueryVariables = Exact<{
  bucketId: Scalars['ID']
  status: BulkDownloadStatus
  nextToken?: InputMaybe<Scalars['String']>
  limit: Scalars['Int']
}>

export type BucketBulkDownloadsQuery = {
  __typename?: 'Query'
  bucketBulkDownloads?: {
    __typename?: 'BucketBulkDownloadResults'
    nextToken?: string | null
    items?: Array<{
      __typename?: 'BucketBulkDownload'
      id: string
      userId: string
      createdAt: string
      scheduledDeletionAt?: string | null
      archives?: Array<{ __typename?: 'DownloadLink'; url: string; totalMediaCount: number; size: number }> | null
    }> | null
  } | null
}

export type BucketBulkMediaUpdateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  mediaIds: Array<Scalars['ID']> | Scalars['ID']
  data: BucketMediaUpdateInput
}>

export type BucketBulkMediaUpdateMutation = { __typename?: 'Mutation'; bucketBulkMediaUpdate?: Array<string> | null }

export type BucketCreateMutationVariables = Exact<{
  data: BucketInput
}>

export type BucketCreateMutation = {
  __typename?: 'Mutation'
  bucketCreate?: {
    __typename?: 'Bucket'
    id: string
    architecture?: Architecture | null
    description?: string | null
    bucketStatus?: BucketStatus | null
    bucketType: BucketType
    bucketUserCount?: number | null
    isConverted?: boolean | null
    defaultBucket?: boolean | null
    defaultSortRule: BucketSortingField
    title: string
    ownerId?: string | null
    subscriptionStatus?: SubscriptionStatus | null
    isAdmin?: boolean | null
    isBanned?: boolean | null
    bucketSettings?: {
      __typename?: 'BucketSettings'
      easyLinkingMode?: boolean | null
      linkBackToAlbums?: boolean | null
      showImageTitle?: boolean | null
      shareOptions?: {
        __typename?: 'ShareOptions'
        directLink?: boolean | null
        htmlCode?: boolean | null
        htmlThumb?: boolean | null
        img?: boolean | null
        imgThumb?: boolean | null
      } | null
    } | null
    counters?: {
      __typename?: 'BucketCounters'
      totalMedia?: number | null
      totalMediaInTrash?: number | null
      totalMediaMinusTrash?: number | null
      totalSize?: number | null
      totalSizeInTrash?: number | null
      totalSizeMinusTrash?: number | null
      totalAlbumCount?: number | null
    } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
  } | null
}

export type BucketCredentialsQueryVariables = Exact<{
  bucketId: Scalars['ID']
  userId: Scalars['ID']
}>

export type BucketCredentialsQuery = {
  __typename?: 'Query'
  bucketCredentials?: { __typename?: 'BucketCredentials'; accessKeyId: string; secretAccessKey: string; sessionToken: string } | null
}

export type BucketDowngradeMutationVariables = Exact<{
  bucketId: Scalars['ID']
  consent: Scalars['Boolean']
}>

export type BucketDowngradeMutation = { __typename?: 'Mutation'; bucketDowngrade?: boolean | null }

export type BucketDtpRequesterAddMutationVariables = Exact<{
  bucketId: Scalars['ID']
  requester: DtpRequester
}>

export type BucketDtpRequesterAddMutation = {
  __typename?: 'Mutation'
  bucketDtpRequesterAdd?: {
    __typename?: 'DTP'
    id: string
    createdAt: string
    updatedAt?: string | null
    userId: string
    requests: {
      __typename?: 'DTPRequests'
      capture?: { __typename?: 'DTPRequest'; bucketId: string; requester: DtpRequester; createdAt: string; updatedAt?: string | null } | null
      facebook?: { __typename?: 'DTPRequest'; bucketId: string; requester: DtpRequester; createdAt: string; updatedAt?: string | null } | null
      print?: { __typename?: 'DTPRequest'; bucketId: string; requester: DtpRequester; createdAt: string; updatedAt?: string | null } | null
    }
  } | null
}

export type BucketFragmentFragment = {
  __typename?: 'Bucket'
  id: string
  architecture?: Architecture | null
  description?: string | null
  bucketStatus?: BucketStatus | null
  bucketType: BucketType
  bucketUserCount?: number | null
  isConverted?: boolean | null
  defaultBucket?: boolean | null
  defaultSortRule: BucketSortingField
  title: string
  ownerId?: string | null
  subscriptionStatus?: SubscriptionStatus | null
  isAdmin?: boolean | null
  isBanned?: boolean | null
  bucketSettings?: {
    __typename?: 'BucketSettings'
    easyLinkingMode?: boolean | null
    linkBackToAlbums?: boolean | null
    showImageTitle?: boolean | null
    shareOptions?: {
      __typename?: 'ShareOptions'
      directLink?: boolean | null
      htmlCode?: boolean | null
      htmlThumb?: boolean | null
      img?: boolean | null
      imgThumb?: boolean | null
    } | null
  } | null
  counters?: {
    __typename?: 'BucketCounters'
    totalMedia?: number | null
    totalMediaInTrash?: number | null
    totalMediaMinusTrash?: number | null
    totalSize?: number | null
    totalSizeInTrash?: number | null
    totalSizeMinusTrash?: number | null
    totalAlbumCount?: number | null
  } | null
  plan?: {
    __typename?: 'Plan'
    apple_id?: string | null
    billing_interval?: string | null
    is_favorites_enabled?: boolean | null
    is_hosting_enabled?: boolean | null
    is_create_bucket_enabled?: boolean | null
    is_image_editor_enabled?: boolean | null
    is_modify_albums_enabled?: boolean | null
    is_search_enabled?: boolean | null
    is_sharing_enabled?: boolean | null
    is_sharing_plan?: boolean | null
    is_sort_by_title_enabled?: boolean | null
    is_sort_by_upload_date_enabled?: boolean | null
    is_storage_plan?: boolean | null
    is_user_tags_enabled?: boolean | null
    is_video_player_enabled?: boolean | null
    media_limit?: number | null
    paddle_id?: string | null
    paypal_id?: string | null
    plan_name: string
    storage_limit?: number | null
    subscription_tier?: string | null
    trial_days?: number | null
    vindicia_id?: string | null
  } | null
}

export type BucketGenerateDownloadLinksQueryVariables = Exact<{
  bucketId: Scalars['ID']
  media?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
  albums?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
}>

export type BucketGenerateDownloadLinksQuery = {
  __typename?: 'Query'
  bucketGenerateDownloadLinks?: { __typename?: 'BucketGenerateDownloadLinks'; requestId: string; requestedAt: string } | null
}

export type BucketInvitationQueryVariables = Exact<{
  invitationId: Scalars['ID']
}>

export type BucketInvitationQuery = {
  __typename?: 'Query'
  bucketInvitation?: {
    __typename?: 'BucketInvitation'
    bucketId: string
    bucketTitle?: string | null
    createdAt?: string | null
    id: string
    invitationStatus?: BucketInvitationStatus | null
    email?: string | null
    updatedAt?: string | null
    userRole: BucketUserRole
  } | null
}

export type BucketInvitationAcceptMutationVariables = Exact<{
  invitationId: Scalars['ID']
}>

export type BucketInvitationAcceptMutation = {
  __typename?: 'Mutation'
  bucketInvitationAccept?: {
    __typename?: 'BucketUser'
    id: string
    userStatus: BucketUserStatus
    userRole: BucketUserRole
    updated?: string | null
    bucketId: string
    permissions?: {
      __typename?: 'PermissionPolicy'
      albumCreate?: boolean | null
      albumDelete?: boolean | null
      albumGet?: boolean | null
      albumUpdate?: boolean | null
      bucketCreate?: boolean | null
      bucketDelete?: boolean | null
      bucketGet?: boolean | null
      bucketUpdate?: boolean | null
      invitationCreate?: boolean | null
      invitationDelete?: boolean | null
      invitationGet?: boolean | null
      invitationUpdate?: boolean | null
      mediaCreate?: boolean | null
      mediaDelete?: boolean | null
      mediaGet?: boolean | null
      mediaUpdate?: boolean | null
      userCreate?: boolean | null
      userDelete?: boolean | null
      userGet?: boolean | null
      userList?: boolean | null
      userUpdate?: boolean | null
    } | null
    userProfile?: {
      __typename?: 'UserProfile'
      country?: string | null
      createdAt: string
      currentBucketId?: string | null
      firstName?: string | null
      gender?: Gender | null
      id: string
      isQuickLinksEnabled?: boolean | null
      lastName?: string | null
      latestTermsAccepted?: boolean | null
      legacyBucketId?: string | null
      name?: string | null
      state?: string | null
      updatedAt?: string | null
      userStatus?: ProfileStatus | null
      zipCode?: string | null
      counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
      desktopSettings?: {
        __typename?: 'BucketGallerySettings'
        cropMode?: boolean | null
        groupedBy?: BucketMediaGroupedBy | null
        flatten?: boolean | null
        layout?: BucketGalleryGridLayout | null
        showImageTitle?: boolean | null
        zoom?: number | null
        filterBy?: {
          __typename?: 'BucketUserFilterBy'
          isFavorite?: boolean | null
          mediaType?: BucketMediaTypeFilter | null
          owner?: BucketMediaContentOwner | null
        } | null
        sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
      } | null
      linkSettings?: {
        __typename?: 'LinkSettings'
        directLink?: boolean | null
        htmlLarge?: boolean | null
        htmlThumb?: boolean | null
        imgLarge?: boolean | null
        imgThumb?: boolean | null
      } | null
      migrationMetadata?: {
        __typename?: 'ProfileMigrationMetadata'
        bucketId?: string | null
        migrationStatus: ProfileMigrationStatus
        approximateMediaCount?: number | null
        hasKafkaBucket?: boolean | null
        migratedMediaCount?: number | null
      } | null
      optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
      plan?: {
        __typename?: 'Plan'
        apple_id?: string | null
        billing_interval?: string | null
        is_favorites_enabled?: boolean | null
        is_hosting_enabled?: boolean | null
        is_create_bucket_enabled?: boolean | null
        is_image_editor_enabled?: boolean | null
        is_modify_albums_enabled?: boolean | null
        is_search_enabled?: boolean | null
        is_sharing_enabled?: boolean | null
        is_sharing_plan?: boolean | null
        is_sort_by_title_enabled?: boolean | null
        is_sort_by_upload_date_enabled?: boolean | null
        is_storage_plan?: boolean | null
        is_user_tags_enabled?: boolean | null
        is_video_player_enabled?: boolean | null
        media_limit?: number | null
        paddle_id?: string | null
        paypal_id?: string | null
        plan_name: string
        storage_limit?: number | null
        subscription_tier?: string | null
        trial_days?: number | null
        vindicia_id?: string | null
      } | null
      subscription?: {
        __typename?: 'Subscription'
        id: string
        createdAt: string
        expirationDate?: string | null
        firstBillingDate?: string | null
        isTrial?: boolean | null
        isCanceled?: boolean | null
        lastBillingDate?: string | null
        planName?: string | null
        recurring?: boolean | null
        trialExpDate?: string | null
        updatedAt?: string | null
        userId: string
        customPlanProperties?: {
          __typename?: 'CustomPlanProperties'
          planName?: string | null
          planTitle?: string | null
          storageLimitBytes?: number | null
          mediaLimitCount?: number | null
          hostingIncluded?: boolean | null
          price?: number | null
        } | null
        subscription?: {
          __typename?: 'SubscriptionInfo'
          id?: string | null
          status?: SubscriptionStatus | null
          account?: SubscriptionAccount | null
        } | null
      } | null
      usageReason?: {
        __typename?: 'UsageReason'
        personal?: boolean | null
        business?: boolean | null
        storage?: boolean | null
        hosting?: boolean | null
        sharing?: boolean | null
      } | null
    } | null
  } | null
}

export type BucketInvitationCreateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  userRole?: InputMaybe<BucketUserRole>
  emails?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type BucketInvitationCreateMutation = {
  __typename?: 'Mutation'
  bucketInvitationCreate?: Array<{
    __typename?: 'BucketInvitation'
    bucketId: string
    bucketTitle?: string | null
    createdAt?: string | null
    id: string
    invitationStatus?: BucketInvitationStatus | null
    email?: string | null
    updatedAt?: string | null
    userRole: BucketUserRole
  }> | null
}

export type BucketInvitationFragmentFragment = {
  __typename?: 'BucketInvitation'
  bucketId: string
  bucketTitle?: string | null
  createdAt?: string | null
  id: string
  invitationStatus?: BucketInvitationStatus | null
  email?: string | null
  updatedAt?: string | null
  userRole: BucketUserRole
}

export type BucketInvitationQueryQueryVariables = Exact<{
  bucketId: Scalars['ID']
  userRole?: InputMaybe<BucketUserRole>
}>

export type BucketInvitationQueryQuery = {
  __typename?: 'Query'
  bucketInvitationQuery?: {
    __typename?: 'BucketInvitationsResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketInvitation'
      bucketId: string
      bucketTitle?: string | null
      createdAt?: string | null
      id: string
      invitationStatus?: BucketInvitationStatus | null
      email?: string | null
      updatedAt?: string | null
      userRole: BucketUserRole
    }>
  } | null
}

export type BucketInvitationResendMutationVariables = Exact<{
  bucketId: Scalars['ID']
  invitationId: Scalars['ID']
}>

export type BucketInvitationResendMutation = {
  __typename?: 'Mutation'
  bucketInvitationResend?: {
    __typename?: 'BucketInvitation'
    bucketId: string
    bucketTitle?: string | null
    createdAt?: string | null
    id: string
    invitationStatus?: BucketInvitationStatus | null
    email?: string | null
    updatedAt?: string | null
    userRole: BucketUserRole
  } | null
}

export type BucketInvitationResetMutationVariables = Exact<{
  bucketId: Scalars['ID']
}>

export type BucketInvitationResetMutation = {
  __typename?: 'Mutation'
  bucketInvitationReset?: Array<{ __typename?: 'BucketInvitationDeleted'; id: string; status: BucketInvitationStatus }> | null
}

export type BucketInvitationUpdateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  invitationId: Scalars['ID']
  status: BucketInvitationStatus
}>

export type BucketInvitationUpdateMutation = {
  __typename?: 'Mutation'
  bucketInvitationUpdate?: {
    __typename?: 'BucketInvitation'
    bucketId: string
    bucketTitle?: string | null
    createdAt?: string | null
    id: string
    invitationStatus?: BucketInvitationStatus | null
    email?: string | null
    updatedAt?: string | null
    userRole: BucketUserRole
  } | null
}

export type BucketInvitationsQueryVariables = Exact<{
  bucketId: Scalars['ID']
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketInvitationsQuery = {
  __typename?: 'Query'
  bucketInvitations?: {
    __typename?: 'BucketInvitationsResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketInvitation'
      bucketId: string
      bucketTitle?: string | null
      createdAt?: string | null
      id: string
      invitationStatus?: BucketInvitationStatus | null
      email?: string | null
      updatedAt?: string | null
      userRole: BucketUserRole
    }>
  } | null
}

export type BucketLoginSuccessMutationVariables = Exact<{
  isAdmin?: InputMaybe<Scalars['Boolean']>
}>

export type BucketLoginSuccessMutation = {
  __typename?: 'Mutation'
  bucketLoginSuccess?: {
    __typename?: 'UserProfile'
    country?: string | null
    createdAt: string
    currentBucketId?: string | null
    firstName?: string | null
    gender?: Gender | null
    id: string
    isQuickLinksEnabled?: boolean | null
    lastName?: string | null
    latestTermsAccepted?: boolean | null
    legacyBucketId?: string | null
    name?: string | null
    state?: string | null
    updatedAt?: string | null
    userStatus?: ProfileStatus | null
    zipCode?: string | null
    counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
    desktopSettings?: {
      __typename?: 'BucketGallerySettings'
      cropMode?: boolean | null
      groupedBy?: BucketMediaGroupedBy | null
      flatten?: boolean | null
      layout?: BucketGalleryGridLayout | null
      showImageTitle?: boolean | null
      zoom?: number | null
      filterBy?: {
        __typename?: 'BucketUserFilterBy'
        isFavorite?: boolean | null
        mediaType?: BucketMediaTypeFilter | null
        owner?: BucketMediaContentOwner | null
      } | null
      sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
    } | null
    linkSettings?: {
      __typename?: 'LinkSettings'
      directLink?: boolean | null
      htmlLarge?: boolean | null
      htmlThumb?: boolean | null
      imgLarge?: boolean | null
      imgThumb?: boolean | null
    } | null
    migrationMetadata?: {
      __typename?: 'ProfileMigrationMetadata'
      bucketId?: string | null
      migrationStatus: ProfileMigrationStatus
      approximateMediaCount?: number | null
      hasKafkaBucket?: boolean | null
      migratedMediaCount?: number | null
    } | null
    optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
    subscription?: {
      __typename?: 'Subscription'
      id: string
      createdAt: string
      expirationDate?: string | null
      firstBillingDate?: string | null
      isTrial?: boolean | null
      isCanceled?: boolean | null
      lastBillingDate?: string | null
      planName?: string | null
      recurring?: boolean | null
      trialExpDate?: string | null
      updatedAt?: string | null
      userId: string
      customPlanProperties?: {
        __typename?: 'CustomPlanProperties'
        planName?: string | null
        planTitle?: string | null
        storageLimitBytes?: number | null
        mediaLimitCount?: number | null
        hostingIncluded?: boolean | null
        price?: number | null
      } | null
      subscription?: {
        __typename?: 'SubscriptionInfo'
        id?: string | null
        status?: SubscriptionStatus | null
        account?: SubscriptionAccount | null
      } | null
    } | null
    usageReason?: {
      __typename?: 'UsageReason'
      personal?: boolean | null
      business?: boolean | null
      storage?: boolean | null
      hosting?: boolean | null
      sharing?: boolean | null
    } | null
  } | null
}

export type BucketMediaQueryVariables = Exact<{
  bucketId: Scalars['ID']
  mediaId: Scalars['ID']
}>

export type BucketMediaQuery = {
  __typename?: 'Query'
  bucketMedia?: {
    __typename?: 'BucketMedia'
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    dateTaken?: string | null
    description?: string | null
    filename: string
    fileSize?: number | null
    height?: number | null
    id: string
    imageUrl?: string | null
    isBanned?: boolean | null
    isVideo?: boolean | null
    mediaType: string
    scheduledDeletionAt?: string | null
    title?: string | null
    userId?: string | null
    userTags?: Array<string> | null
    width?: number | null
    isFavorite?: boolean | null
  } | null
}

export type BucketMediaByAlbumIdQueryVariables = Exact<{
  bucketId: Scalars['ID']
  albumId?: InputMaybe<Scalars['ID']>
  limit?: Scalars['Int']
  nextToken?: InputMaybe<Scalars['String']>
  filterBy?: InputMaybe<BucketMediaFilter>
  sortBy?: InputMaybe<BucketMediaSorter>
}>

export type BucketMediaByAlbumIdQuery = {
  __typename?: 'Query'
  bucketMediaByAlbumId?: {
    __typename?: 'BucketMediaResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isBanned?: boolean | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
      isFavorite?: boolean | null
    }>
  } | null
}

export type BucketMediaByIdsQueryVariables = Exact<{
  bucketId: Scalars['ID']
  mediaIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type BucketMediaByIdsQuery = {
  __typename?: 'Query'
  bucketMediaByIds?: Array<{
    __typename?: 'BucketMedia'
    signedUrl?: string | null
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    dateTaken?: string | null
    description?: string | null
    filename: string
    fileSize?: number | null
    height?: number | null
    id: string
    imageUrl?: string | null
    isBanned?: boolean | null
    isVideo?: boolean | null
    mediaType: string
    scheduledDeletionAt?: string | null
    title?: string | null
    userId?: string | null
    userTags?: Array<string> | null
    width?: number | null
    isFavorite?: boolean | null
  }> | null
}

export type BucketMediaByShareIdQueryVariables = Exact<{
  shareId: Scalars['ID']
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketMediaByShareIdQuery = {
  __typename?: 'Query'
  bucketMediaByShareId?: {
    __typename?: 'BucketMediaResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isBanned?: boolean | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      signedUrl?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
    }>
  } | null
}

export type BucketMediaByShareIdsQueryVariables = Exact<{
  shareId: Scalars['ID']
  mediaIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type BucketMediaByShareIdsQuery = {
  __typename?: 'Query'
  bucketMediaByShareIds?: Array<{
    __typename?: 'BucketMedia'
    signedUrl?: string | null
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    dateTaken?: string | null
    description?: string | null
    filename: string
    fileSize?: number | null
    height?: number | null
    id: string
    imageUrl?: string | null
    isBanned?: boolean | null
    isVideo?: boolean | null
    mediaType: string
    scheduledDeletionAt?: string | null
    title?: string | null
    userId?: string | null
    userTags?: Array<string> | null
    width?: number | null
    isFavorite?: boolean | null
  }> | null
}

export type BucketMediaCreateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  mediaId: Scalars['ID']
  filename: Scalars['String']
  data: BucketMediaInput
}>

export type BucketMediaCreateMutation = {
  __typename?: 'Mutation'
  bucketMediaCreate?: {
    __typename?: 'BucketMedia'
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    dateTaken?: string | null
    description?: string | null
    filename: string
    fileSize?: number | null
    height?: number | null
    id: string
    imageUrl?: string | null
    isBanned?: boolean | null
    isVideo?: boolean | null
    mediaType: string
    scheduledDeletionAt?: string | null
    title?: string | null
    userId?: string | null
    userTags?: Array<string> | null
    width?: number | null
    isFavorite?: boolean | null
  } | null
}

export type BucketMediaFragmentFragment = {
  __typename?: 'BucketMedia'
  albumId?: string | null
  bucketId: string
  createdAt?: string | null
  dateTaken?: string | null
  description?: string | null
  filename: string
  fileSize?: number | null
  height?: number | null
  id: string
  imageUrl?: string | null
  isBanned?: boolean | null
  isVideo?: boolean | null
  mediaType: string
  scheduledDeletionAt?: string | null
  title?: string | null
  userId?: string | null
  userTags?: Array<string> | null
  width?: number | null
  isFavorite?: boolean | null
}

export type BucketMediaMoveMutationVariables = Exact<{
  bucketId: Scalars['ID']
  mediaIds: Array<Scalars['ID']> | Scalars['ID']
  data?: InputMaybe<BucketMediaMoveInput>
}>

export type BucketMediaMoveMutation = { __typename?: 'Mutation'; bucketMediaMove?: Array<string> | null }

export type BucketMediaMoveCopyBetweenBucketsMutationVariables = Exact<{
  bucketId: Scalars['ID']
  mediaIds: Array<Scalars['ID']> | Scalars['ID']
  data: BucketMediaMoveCopyBetweenBucketsInput
}>

export type BucketMediaMoveCopyBetweenBucketsMutation = { __typename?: 'Mutation'; bucketMediaMoveCopyBetweenBuckets?: boolean | null }

export type BucketMediaSearchQueryVariables = Exact<{
  bucketId: Scalars['ID']
  query: Scalars['String']
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketMediaSearchQuery = {
  __typename?: 'Query'
  bucketMediaSearch?: {
    __typename?: 'BucketMediaResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isBanned?: boolean | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
      isFavorite?: boolean | null
    }>
  } | null
}

export type BucketMediaSharedFragmentFragment = {
  __typename?: 'BucketMedia'
  albumId?: string | null
  bucketId: string
  createdAt?: string | null
  dateTaken?: string | null
  description?: string | null
  filename: string
  fileSize?: number | null
  height?: number | null
  id: string
  imageUrl?: string | null
  isBanned?: boolean | null
  isVideo?: boolean | null
  mediaType: string
  scheduledDeletionAt?: string | null
  signedUrl?: string | null
  title?: string | null
  userId?: string | null
  userTags?: Array<string> | null
  width?: number | null
}

export type BucketMediaSignedUrlQueryVariables = Exact<{
  bucketId: Scalars['ID']
  mediaId: Scalars['ID']
}>

export type BucketMediaSignedUrlQuery = {
  __typename?: 'Query'
  bucketMedia?: { __typename?: 'BucketMedia'; signedUrl?: string | null; filename: string } | null
}

export type BucketMediaUpdateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  mediaId: Scalars['ID']
  data: BucketMediaUpdateInput
}>

export type BucketMediaUpdateMutation = {
  __typename?: 'Mutation'
  bucketMediaUpdate?: {
    __typename?: 'BucketMedia'
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    dateTaken?: string | null
    description?: string | null
    filename: string
    fileSize?: number | null
    height?: number | null
    id: string
    imageUrl?: string | null
    isBanned?: boolean | null
    isVideo?: boolean | null
    mediaType: string
    scheduledDeletionAt?: string | null
    title?: string | null
    userId?: string | null
    userTags?: Array<string> | null
    width?: number | null
    isFavorite?: boolean | null
  } | null
}

export type BucketShareByIdQueryVariables = Exact<{
  shareId: Scalars['ID']
}>

export type BucketShareByIdQuery = {
  __typename?: 'Query'
  bucketShareById?: {
    __typename?: 'BucketShare'
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    id: string
    password?: string | null
    passwordProtected?: boolean | null
    title?: string | null
    totalMedia?: number | null
    userId?: string | null
    scheduledDeletionAt?: string | null
    shareStatus: BucketShareStatus
    updatedAt?: string | null
    thumbnails?: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
    }> | null
  } | null
}

export type BucketShareByIdProtectedQueryVariables = Exact<{
  shareId: Scalars['ID']
}>

export type BucketShareByIdProtectedQuery = {
  __typename?: 'Query'
  bucketShareById?: {
    __typename?: 'BucketShare'
    passwordProtected?: boolean | null
    bucketId: string
    id: string
    albumId?: string | null
    password?: string | null
    shareStatus: BucketShareStatus
  } | null
}

export type BucketShareCreateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  data: BucketShareCreateInput
}>

export type BucketShareCreateMutation = {
  __typename?: 'Mutation'
  bucketShareCreate?: {
    __typename?: 'BucketShare'
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    id: string
    password?: string | null
    passwordProtected?: boolean | null
    title?: string | null
    totalMedia?: number | null
    userId?: string | null
    scheduledDeletionAt?: string | null
    shareStatus: BucketShareStatus
    updatedAt?: string | null
    thumbnails?: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
    }> | null
  } | null
}

export type BucketShareFragmentFragment = {
  __typename?: 'BucketShare'
  albumId?: string | null
  bucketId: string
  createdAt?: string | null
  id: string
  password?: string | null
  passwordProtected?: boolean | null
  title?: string | null
  totalMedia?: number | null
  userId?: string | null
  scheduledDeletionAt?: string | null
  shareStatus: BucketShareStatus
  updatedAt?: string | null
  thumbnails?: Array<{
    __typename?: 'BucketMedia'
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    dateTaken?: string | null
    description?: string | null
    filename: string
    fileSize?: number | null
    height?: number | null
    id: string
    imageUrl?: string | null
    isVideo?: boolean | null
    mediaType: string
    scheduledDeletionAt?: string | null
    title?: string | null
    userId?: string | null
    userTags?: Array<string> | null
    width?: number | null
  }> | null
}

export type BucketSharePasswordValidateMutationVariables = Exact<{
  shareId: Scalars['ID']
  password: Scalars['String']
}>

export type BucketSharePasswordValidateMutation = { __typename?: 'Mutation'; bucketSharePasswordValidate: boolean }

export type BucketShareUpdateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  shareId: Scalars['ID']
  status: BucketShareStatus
  title?: InputMaybe<Scalars['String']>
  scheduledDeletionAt?: InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
  albumId?: InputMaybe<Scalars['String']>
  shareType?: InputMaybe<BucketShareType>
}>

export type BucketShareUpdateMutation = {
  __typename?: 'Mutation'
  bucketShareUpdate?: {
    __typename?: 'BucketShare'
    albumId?: string | null
    bucketId: string
    createdAt?: string | null
    id: string
    password?: string | null
    passwordProtected?: boolean | null
    title?: string | null
    totalMedia?: number | null
    userId?: string | null
    scheduledDeletionAt?: string | null
    shareStatus: BucketShareStatus
    updatedAt?: string | null
    thumbnails?: Array<{
      __typename?: 'BucketMedia'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      dateTaken?: string | null
      description?: string | null
      filename: string
      fileSize?: number | null
      height?: number | null
      id: string
      imageUrl?: string | null
      isVideo?: boolean | null
      mediaType: string
      scheduledDeletionAt?: string | null
      title?: string | null
      userId?: string | null
      userTags?: Array<string> | null
      width?: number | null
    }> | null
  } | null
}

export type BucketSharesQueryVariables = Exact<{
  bucketId: Scalars['ID']
  nextToken?: InputMaybe<Scalars['String']>
  limit?: Scalars['Int']
}>

export type BucketSharesQuery = {
  __typename?: 'Query'
  bucketShares?: {
    __typename?: 'BucketSharesResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketShare'
      albumId?: string | null
      bucketId: string
      createdAt?: string | null
      id: string
      password?: string | null
      passwordProtected?: boolean | null
      title?: string | null
      totalMedia?: number | null
      userId?: string | null
      scheduledDeletionAt?: string | null
      shareStatus: BucketShareStatus
      updatedAt?: string | null
      thumbnails?: Array<{
        __typename?: 'BucketMedia'
        albumId?: string | null
        bucketId: string
        createdAt?: string | null
        dateTaken?: string | null
        description?: string | null
        filename: string
        fileSize?: number | null
        height?: number | null
        id: string
        imageUrl?: string | null
        isVideo?: boolean | null
        mediaType: string
        scheduledDeletionAt?: string | null
        title?: string | null
        userId?: string | null
        userTags?: Array<string> | null
        width?: number | null
      }> | null
    }>
  } | null
}

export type BucketTransferFragmentFragment = {
  __typename?: 'BucketTransfer'
  bucketId: string
  createdAt?: string | null
  id: string
  email?: string | null
  updatedAt?: string | null
  transferStatus: BucketTransferStatus
}

export type BucketTransferOwnerAcceptMutationVariables = Exact<{
  transferId: Scalars['ID']
}>

export type BucketTransferOwnerAcceptMutation = {
  __typename?: 'Mutation'
  bucketTransferOwnerAccept?: {
    __typename?: 'Bucket'
    id: string
    architecture?: Architecture | null
    description?: string | null
    bucketStatus?: BucketStatus | null
    bucketType: BucketType
    bucketUserCount?: number | null
    isConverted?: boolean | null
    defaultBucket?: boolean | null
    defaultSortRule: BucketSortingField
    title: string
    ownerId?: string | null
    subscriptionStatus?: SubscriptionStatus | null
    isAdmin?: boolean | null
    isBanned?: boolean | null
    bucketSettings?: {
      __typename?: 'BucketSettings'
      easyLinkingMode?: boolean | null
      linkBackToAlbums?: boolean | null
      showImageTitle?: boolean | null
      shareOptions?: {
        __typename?: 'ShareOptions'
        directLink?: boolean | null
        htmlCode?: boolean | null
        htmlThumb?: boolean | null
        img?: boolean | null
        imgThumb?: boolean | null
      } | null
    } | null
    counters?: {
      __typename?: 'BucketCounters'
      totalMedia?: number | null
      totalMediaInTrash?: number | null
      totalMediaMinusTrash?: number | null
      totalSize?: number | null
      totalSizeInTrash?: number | null
      totalSizeMinusTrash?: number | null
      totalAlbumCount?: number | null
    } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
  } | null
}

export type BucketTransferOwnerCreateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  userId: Scalars['ID']
}>

export type BucketTransferOwnerCreateMutation = {
  __typename?: 'Mutation'
  bucketTransferOwnerCreate?: {
    __typename?: 'BucketTransfer'
    bucketId: string
    createdAt?: string | null
    id: string
    email?: string | null
    updatedAt?: string | null
    transferStatus: BucketTransferStatus
  } | null
}

export type BucketTrashEmptyMutationVariables = Exact<{
  bucketId: Scalars['ID']
}>

export type BucketTrashEmptyMutation = { __typename?: 'Mutation'; bucketTrashEmpty?: boolean | null }

export type BucketUpdateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  data: BucketInput
}>

export type BucketUpdateMutation = {
  __typename?: 'Mutation'
  bucketUpdate?: {
    __typename?: 'Bucket'
    id: string
    architecture?: Architecture | null
    description?: string | null
    bucketStatus?: BucketStatus | null
    bucketType: BucketType
    bucketUserCount?: number | null
    isConverted?: boolean | null
    defaultBucket?: boolean | null
    defaultSortRule: BucketSortingField
    title: string
    ownerId?: string | null
    subscriptionStatus?: SubscriptionStatus | null
    isAdmin?: boolean | null
    isBanned?: boolean | null
    bucketSettings?: {
      __typename?: 'BucketSettings'
      easyLinkingMode?: boolean | null
      linkBackToAlbums?: boolean | null
      showImageTitle?: boolean | null
      shareOptions?: {
        __typename?: 'ShareOptions'
        directLink?: boolean | null
        htmlCode?: boolean | null
        htmlThumb?: boolean | null
        img?: boolean | null
        imgThumb?: boolean | null
      } | null
    } | null
    counters?: {
      __typename?: 'BucketCounters'
      totalMedia?: number | null
      totalMediaInTrash?: number | null
      totalMediaMinusTrash?: number | null
      totalSize?: number | null
      totalSizeInTrash?: number | null
      totalSizeMinusTrash?: number | null
      totalAlbumCount?: number | null
    } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
  } | null
}

export type BucketUserQueryVariables = Exact<{
  bucketId: Scalars['ID']
  userId: Scalars['ID']
}>

export type BucketUserQuery = {
  __typename?: 'Query'
  bucketUser?: {
    __typename?: 'BucketUser'
    id: string
    userStatus: BucketUserStatus
    userRole: BucketUserRole
    updated?: string | null
    bucketId: string
    permissions?: {
      __typename?: 'PermissionPolicy'
      albumCreate?: boolean | null
      albumDelete?: boolean | null
      albumGet?: boolean | null
      albumUpdate?: boolean | null
      bucketCreate?: boolean | null
      bucketDelete?: boolean | null
      bucketGet?: boolean | null
      bucketUpdate?: boolean | null
      invitationCreate?: boolean | null
      invitationDelete?: boolean | null
      invitationGet?: boolean | null
      invitationUpdate?: boolean | null
      mediaCreate?: boolean | null
      mediaDelete?: boolean | null
      mediaGet?: boolean | null
      mediaUpdate?: boolean | null
      userCreate?: boolean | null
      userDelete?: boolean | null
      userGet?: boolean | null
      userList?: boolean | null
      userUpdate?: boolean | null
    } | null
    userProfile?: {
      __typename?: 'UserProfile'
      country?: string | null
      createdAt: string
      currentBucketId?: string | null
      firstName?: string | null
      gender?: Gender | null
      id: string
      isQuickLinksEnabled?: boolean | null
      lastName?: string | null
      latestTermsAccepted?: boolean | null
      legacyBucketId?: string | null
      name?: string | null
      state?: string | null
      updatedAt?: string | null
      userStatus?: ProfileStatus | null
      zipCode?: string | null
      counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
      desktopSettings?: {
        __typename?: 'BucketGallerySettings'
        cropMode?: boolean | null
        groupedBy?: BucketMediaGroupedBy | null
        flatten?: boolean | null
        layout?: BucketGalleryGridLayout | null
        showImageTitle?: boolean | null
        zoom?: number | null
        filterBy?: {
          __typename?: 'BucketUserFilterBy'
          isFavorite?: boolean | null
          mediaType?: BucketMediaTypeFilter | null
          owner?: BucketMediaContentOwner | null
        } | null
        sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
      } | null
      linkSettings?: {
        __typename?: 'LinkSettings'
        directLink?: boolean | null
        htmlLarge?: boolean | null
        htmlThumb?: boolean | null
        imgLarge?: boolean | null
        imgThumb?: boolean | null
      } | null
      migrationMetadata?: {
        __typename?: 'ProfileMigrationMetadata'
        bucketId?: string | null
        migrationStatus: ProfileMigrationStatus
        approximateMediaCount?: number | null
        hasKafkaBucket?: boolean | null
        migratedMediaCount?: number | null
      } | null
      optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
      plan?: {
        __typename?: 'Plan'
        apple_id?: string | null
        billing_interval?: string | null
        is_favorites_enabled?: boolean | null
        is_hosting_enabled?: boolean | null
        is_create_bucket_enabled?: boolean | null
        is_image_editor_enabled?: boolean | null
        is_modify_albums_enabled?: boolean | null
        is_search_enabled?: boolean | null
        is_sharing_enabled?: boolean | null
        is_sharing_plan?: boolean | null
        is_sort_by_title_enabled?: boolean | null
        is_sort_by_upload_date_enabled?: boolean | null
        is_storage_plan?: boolean | null
        is_user_tags_enabled?: boolean | null
        is_video_player_enabled?: boolean | null
        media_limit?: number | null
        paddle_id?: string | null
        paypal_id?: string | null
        plan_name: string
        storage_limit?: number | null
        subscription_tier?: string | null
        trial_days?: number | null
        vindicia_id?: string | null
      } | null
      subscription?: {
        __typename?: 'Subscription'
        id: string
        createdAt: string
        expirationDate?: string | null
        firstBillingDate?: string | null
        isTrial?: boolean | null
        isCanceled?: boolean | null
        lastBillingDate?: string | null
        planName?: string | null
        recurring?: boolean | null
        trialExpDate?: string | null
        updatedAt?: string | null
        userId: string
        customPlanProperties?: {
          __typename?: 'CustomPlanProperties'
          planName?: string | null
          planTitle?: string | null
          storageLimitBytes?: number | null
          mediaLimitCount?: number | null
          hostingIncluded?: boolean | null
          price?: number | null
        } | null
        subscription?: {
          __typename?: 'SubscriptionInfo'
          id?: string | null
          status?: SubscriptionStatus | null
          account?: SubscriptionAccount | null
        } | null
      } | null
      usageReason?: {
        __typename?: 'UsageReason'
        personal?: boolean | null
        business?: boolean | null
        storage?: boolean | null
        hosting?: boolean | null
        sharing?: boolean | null
      } | null
    } | null
  } | null
}

export type BucketUserFragmentFragment = {
  __typename?: 'BucketUser'
  id: string
  userStatus: BucketUserStatus
  userRole: BucketUserRole
  updated?: string | null
  bucketId: string
  permissions?: {
    __typename?: 'PermissionPolicy'
    albumCreate?: boolean | null
    albumDelete?: boolean | null
    albumGet?: boolean | null
    albumUpdate?: boolean | null
    bucketCreate?: boolean | null
    bucketDelete?: boolean | null
    bucketGet?: boolean | null
    bucketUpdate?: boolean | null
    invitationCreate?: boolean | null
    invitationDelete?: boolean | null
    invitationGet?: boolean | null
    invitationUpdate?: boolean | null
    mediaCreate?: boolean | null
    mediaDelete?: boolean | null
    mediaGet?: boolean | null
    mediaUpdate?: boolean | null
    userCreate?: boolean | null
    userDelete?: boolean | null
    userGet?: boolean | null
    userList?: boolean | null
    userUpdate?: boolean | null
  } | null
  userProfile?: {
    __typename?: 'UserProfile'
    country?: string | null
    createdAt: string
    currentBucketId?: string | null
    firstName?: string | null
    gender?: Gender | null
    id: string
    isQuickLinksEnabled?: boolean | null
    lastName?: string | null
    latestTermsAccepted?: boolean | null
    legacyBucketId?: string | null
    name?: string | null
    state?: string | null
    updatedAt?: string | null
    userStatus?: ProfileStatus | null
    zipCode?: string | null
    counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
    desktopSettings?: {
      __typename?: 'BucketGallerySettings'
      cropMode?: boolean | null
      groupedBy?: BucketMediaGroupedBy | null
      flatten?: boolean | null
      layout?: BucketGalleryGridLayout | null
      showImageTitle?: boolean | null
      zoom?: number | null
      filterBy?: {
        __typename?: 'BucketUserFilterBy'
        isFavorite?: boolean | null
        mediaType?: BucketMediaTypeFilter | null
        owner?: BucketMediaContentOwner | null
      } | null
      sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
    } | null
    linkSettings?: {
      __typename?: 'LinkSettings'
      directLink?: boolean | null
      htmlLarge?: boolean | null
      htmlThumb?: boolean | null
      imgLarge?: boolean | null
      imgThumb?: boolean | null
    } | null
    migrationMetadata?: {
      __typename?: 'ProfileMigrationMetadata'
      bucketId?: string | null
      migrationStatus: ProfileMigrationStatus
      approximateMediaCount?: number | null
      hasKafkaBucket?: boolean | null
      migratedMediaCount?: number | null
    } | null
    optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
    subscription?: {
      __typename?: 'Subscription'
      id: string
      createdAt: string
      expirationDate?: string | null
      firstBillingDate?: string | null
      isTrial?: boolean | null
      isCanceled?: boolean | null
      lastBillingDate?: string | null
      planName?: string | null
      recurring?: boolean | null
      trialExpDate?: string | null
      updatedAt?: string | null
      userId: string
      customPlanProperties?: {
        __typename?: 'CustomPlanProperties'
        planName?: string | null
        planTitle?: string | null
        storageLimitBytes?: number | null
        mediaLimitCount?: number | null
        hostingIncluded?: boolean | null
        price?: number | null
      } | null
      subscription?: {
        __typename?: 'SubscriptionInfo'
        id?: string | null
        status?: SubscriptionStatus | null
        account?: SubscriptionAccount | null
      } | null
    } | null
    usageReason?: {
      __typename?: 'UsageReason'
      personal?: boolean | null
      business?: boolean | null
      storage?: boolean | null
      hosting?: boolean | null
      sharing?: boolean | null
    } | null
  } | null
}

export type BucketUserMigrateMutationVariables = Exact<{ [key: string]: never }>

export type BucketUserMigrateMutation = { __typename?: 'Mutation'; userMigrate?: { __typename?: 'UserProfile'; id: string } | null }

export type BucketUserPermissionsFragmentFragment = {
  __typename?: 'PermissionPolicy'
  albumCreate?: boolean | null
  albumDelete?: boolean | null
  albumGet?: boolean | null
  albumUpdate?: boolean | null
  bucketCreate?: boolean | null
  bucketDelete?: boolean | null
  bucketGet?: boolean | null
  bucketUpdate?: boolean | null
  invitationCreate?: boolean | null
  invitationDelete?: boolean | null
  invitationGet?: boolean | null
  invitationUpdate?: boolean | null
  mediaCreate?: boolean | null
  mediaDelete?: boolean | null
  mediaGet?: boolean | null
  mediaUpdate?: boolean | null
  userCreate?: boolean | null
  userDelete?: boolean | null
  userGet?: boolean | null
  userList?: boolean | null
  userUpdate?: boolean | null
}

export type BucketUserUpdateMutationVariables = Exact<{
  bucketId: Scalars['ID']
  userId: Scalars['ID']
  data: BucketUserInput
}>

export type BucketUserUpdateMutation = {
  __typename?: 'Mutation'
  bucketUserUpdate?: {
    __typename?: 'BucketUser'
    id: string
    userStatus: BucketUserStatus
    userRole: BucketUserRole
    updated?: string | null
    bucketId: string
    permissions?: {
      __typename?: 'PermissionPolicy'
      albumCreate?: boolean | null
      albumDelete?: boolean | null
      albumGet?: boolean | null
      albumUpdate?: boolean | null
      bucketCreate?: boolean | null
      bucketDelete?: boolean | null
      bucketGet?: boolean | null
      bucketUpdate?: boolean | null
      invitationCreate?: boolean | null
      invitationDelete?: boolean | null
      invitationGet?: boolean | null
      invitationUpdate?: boolean | null
      mediaCreate?: boolean | null
      mediaDelete?: boolean | null
      mediaGet?: boolean | null
      mediaUpdate?: boolean | null
      userCreate?: boolean | null
      userDelete?: boolean | null
      userGet?: boolean | null
      userList?: boolean | null
      userUpdate?: boolean | null
    } | null
    userProfile?: {
      __typename?: 'UserProfile'
      country?: string | null
      createdAt: string
      currentBucketId?: string | null
      firstName?: string | null
      gender?: Gender | null
      id: string
      isQuickLinksEnabled?: boolean | null
      lastName?: string | null
      latestTermsAccepted?: boolean | null
      legacyBucketId?: string | null
      name?: string | null
      state?: string | null
      updatedAt?: string | null
      userStatus?: ProfileStatus | null
      zipCode?: string | null
      counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
      desktopSettings?: {
        __typename?: 'BucketGallerySettings'
        cropMode?: boolean | null
        groupedBy?: BucketMediaGroupedBy | null
        flatten?: boolean | null
        layout?: BucketGalleryGridLayout | null
        showImageTitle?: boolean | null
        zoom?: number | null
        filterBy?: {
          __typename?: 'BucketUserFilterBy'
          isFavorite?: boolean | null
          mediaType?: BucketMediaTypeFilter | null
          owner?: BucketMediaContentOwner | null
        } | null
        sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
      } | null
      linkSettings?: {
        __typename?: 'LinkSettings'
        directLink?: boolean | null
        htmlLarge?: boolean | null
        htmlThumb?: boolean | null
        imgLarge?: boolean | null
        imgThumb?: boolean | null
      } | null
      migrationMetadata?: {
        __typename?: 'ProfileMigrationMetadata'
        bucketId?: string | null
        migrationStatus: ProfileMigrationStatus
        approximateMediaCount?: number | null
        hasKafkaBucket?: boolean | null
        migratedMediaCount?: number | null
      } | null
      optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
      plan?: {
        __typename?: 'Plan'
        apple_id?: string | null
        billing_interval?: string | null
        is_favorites_enabled?: boolean | null
        is_hosting_enabled?: boolean | null
        is_create_bucket_enabled?: boolean | null
        is_image_editor_enabled?: boolean | null
        is_modify_albums_enabled?: boolean | null
        is_search_enabled?: boolean | null
        is_sharing_enabled?: boolean | null
        is_sharing_plan?: boolean | null
        is_sort_by_title_enabled?: boolean | null
        is_sort_by_upload_date_enabled?: boolean | null
        is_storage_plan?: boolean | null
        is_user_tags_enabled?: boolean | null
        is_video_player_enabled?: boolean | null
        media_limit?: number | null
        paddle_id?: string | null
        paypal_id?: string | null
        plan_name: string
        storage_limit?: number | null
        subscription_tier?: string | null
        trial_days?: number | null
        vindicia_id?: string | null
      } | null
      subscription?: {
        __typename?: 'Subscription'
        id: string
        createdAt: string
        expirationDate?: string | null
        firstBillingDate?: string | null
        isTrial?: boolean | null
        isCanceled?: boolean | null
        lastBillingDate?: string | null
        planName?: string | null
        recurring?: boolean | null
        trialExpDate?: string | null
        updatedAt?: string | null
        userId: string
        customPlanProperties?: {
          __typename?: 'CustomPlanProperties'
          planName?: string | null
          planTitle?: string | null
          storageLimitBytes?: number | null
          mediaLimitCount?: number | null
          hostingIncluded?: boolean | null
          price?: number | null
        } | null
        subscription?: {
          __typename?: 'SubscriptionInfo'
          id?: string | null
          status?: SubscriptionStatus | null
          account?: SubscriptionAccount | null
        } | null
      } | null
      usageReason?: {
        __typename?: 'UsageReason'
        personal?: boolean | null
        business?: boolean | null
        storage?: boolean | null
        hosting?: boolean | null
        sharing?: boolean | null
      } | null
    } | null
  } | null
}

export type BucketUsersQueryVariables = Exact<{
  bucketId: Scalars['ID']
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketUsersQuery = {
  __typename?: 'Query'
  bucketUsers?: {
    __typename?: 'BucketUsersResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'BucketUser'
      id: string
      userStatus: BucketUserStatus
      userRole: BucketUserRole
      updated?: string | null
      bucketId: string
      permissions?: {
        __typename?: 'PermissionPolicy'
        albumCreate?: boolean | null
        albumDelete?: boolean | null
        albumGet?: boolean | null
        albumUpdate?: boolean | null
        bucketCreate?: boolean | null
        bucketDelete?: boolean | null
        bucketGet?: boolean | null
        bucketUpdate?: boolean | null
        invitationCreate?: boolean | null
        invitationDelete?: boolean | null
        invitationGet?: boolean | null
        invitationUpdate?: boolean | null
        mediaCreate?: boolean | null
        mediaDelete?: boolean | null
        mediaGet?: boolean | null
        mediaUpdate?: boolean | null
        userCreate?: boolean | null
        userDelete?: boolean | null
        userGet?: boolean | null
        userList?: boolean | null
        userUpdate?: boolean | null
      } | null
      userProfile?: {
        __typename?: 'UserProfile'
        country?: string | null
        createdAt: string
        currentBucketId?: string | null
        firstName?: string | null
        gender?: Gender | null
        id: string
        isQuickLinksEnabled?: boolean | null
        lastName?: string | null
        latestTermsAccepted?: boolean | null
        legacyBucketId?: string | null
        name?: string | null
        state?: string | null
        updatedAt?: string | null
        userStatus?: ProfileStatus | null
        zipCode?: string | null
        counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
        desktopSettings?: {
          __typename?: 'BucketGallerySettings'
          cropMode?: boolean | null
          groupedBy?: BucketMediaGroupedBy | null
          flatten?: boolean | null
          layout?: BucketGalleryGridLayout | null
          showImageTitle?: boolean | null
          zoom?: number | null
          filterBy?: {
            __typename?: 'BucketUserFilterBy'
            isFavorite?: boolean | null
            mediaType?: BucketMediaTypeFilter | null
            owner?: BucketMediaContentOwner | null
          } | null
          sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
        } | null
        linkSettings?: {
          __typename?: 'LinkSettings'
          directLink?: boolean | null
          htmlLarge?: boolean | null
          htmlThumb?: boolean | null
          imgLarge?: boolean | null
          imgThumb?: boolean | null
        } | null
        migrationMetadata?: {
          __typename?: 'ProfileMigrationMetadata'
          bucketId?: string | null
          migrationStatus: ProfileMigrationStatus
          approximateMediaCount?: number | null
          hasKafkaBucket?: boolean | null
          migratedMediaCount?: number | null
        } | null
        optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
        plan?: {
          __typename?: 'Plan'
          apple_id?: string | null
          billing_interval?: string | null
          is_favorites_enabled?: boolean | null
          is_hosting_enabled?: boolean | null
          is_create_bucket_enabled?: boolean | null
          is_image_editor_enabled?: boolean | null
          is_modify_albums_enabled?: boolean | null
          is_search_enabled?: boolean | null
          is_sharing_enabled?: boolean | null
          is_sharing_plan?: boolean | null
          is_sort_by_title_enabled?: boolean | null
          is_sort_by_upload_date_enabled?: boolean | null
          is_storage_plan?: boolean | null
          is_user_tags_enabled?: boolean | null
          is_video_player_enabled?: boolean | null
          media_limit?: number | null
          paddle_id?: string | null
          paypal_id?: string | null
          plan_name: string
          storage_limit?: number | null
          subscription_tier?: string | null
          trial_days?: number | null
          vindicia_id?: string | null
        } | null
        subscription?: {
          __typename?: 'Subscription'
          id: string
          createdAt: string
          expirationDate?: string | null
          firstBillingDate?: string | null
          isTrial?: boolean | null
          isCanceled?: boolean | null
          lastBillingDate?: string | null
          planName?: string | null
          recurring?: boolean | null
          trialExpDate?: string | null
          updatedAt?: string | null
          userId: string
          customPlanProperties?: {
            __typename?: 'CustomPlanProperties'
            planName?: string | null
            planTitle?: string | null
            storageLimitBytes?: number | null
            mediaLimitCount?: number | null
            hostingIncluded?: boolean | null
            price?: number | null
          } | null
          subscription?: {
            __typename?: 'SubscriptionInfo'
            id?: string | null
            status?: SubscriptionStatus | null
            account?: SubscriptionAccount | null
          } | null
        } | null
        usageReason?: {
          __typename?: 'UsageReason'
          personal?: boolean | null
          business?: boolean | null
          storage?: boolean | null
          hosting?: boolean | null
          sharing?: boolean | null
        } | null
      } | null
    }>
  } | null
}

export type BucketsByUserIdQueryVariables = Exact<{
  userId: Scalars['ID']
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketsByUserIdQuery = {
  __typename?: 'Query'
  bucketsByUserId?: {
    __typename?: 'BucketsByUserIdResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'Bucket'
      id: string
      architecture?: Architecture | null
      description?: string | null
      bucketStatus?: BucketStatus | null
      bucketType: BucketType
      bucketUserCount?: number | null
      isConverted?: boolean | null
      defaultBucket?: boolean | null
      defaultSortRule: BucketSortingField
      title: string
      ownerId?: string | null
      subscriptionStatus?: SubscriptionStatus | null
      isAdmin?: boolean | null
      isBanned?: boolean | null
      bucketSettings?: {
        __typename?: 'BucketSettings'
        easyLinkingMode?: boolean | null
        linkBackToAlbums?: boolean | null
        showImageTitle?: boolean | null
        shareOptions?: {
          __typename?: 'ShareOptions'
          directLink?: boolean | null
          htmlCode?: boolean | null
          htmlThumb?: boolean | null
          img?: boolean | null
          imgThumb?: boolean | null
        } | null
      } | null
      counters?: {
        __typename?: 'BucketCounters'
        totalMedia?: number | null
        totalMediaInTrash?: number | null
        totalMediaMinusTrash?: number | null
        totalSize?: number | null
        totalSizeInTrash?: number | null
        totalSizeMinusTrash?: number | null
        totalAlbumCount?: number | null
      } | null
      plan?: {
        __typename?: 'Plan'
        apple_id?: string | null
        billing_interval?: string | null
        is_favorites_enabled?: boolean | null
        is_hosting_enabled?: boolean | null
        is_create_bucket_enabled?: boolean | null
        is_image_editor_enabled?: boolean | null
        is_modify_albums_enabled?: boolean | null
        is_search_enabled?: boolean | null
        is_sharing_enabled?: boolean | null
        is_sharing_plan?: boolean | null
        is_sort_by_title_enabled?: boolean | null
        is_sort_by_upload_date_enabled?: boolean | null
        is_storage_plan?: boolean | null
        is_user_tags_enabled?: boolean | null
        is_video_player_enabled?: boolean | null
        media_limit?: number | null
        paddle_id?: string | null
        paypal_id?: string | null
        plan_name: string
        storage_limit?: number | null
        subscription_tier?: string | null
        trial_days?: number | null
        vindicia_id?: string | null
      } | null
    }>
  } | null
  userProfile?: {
    __typename?: 'UserProfile'
    country?: string | null
    createdAt: string
    currentBucketId?: string | null
    firstName?: string | null
    gender?: Gender | null
    id: string
    isQuickLinksEnabled?: boolean | null
    lastName?: string | null
    latestTermsAccepted?: boolean | null
    legacyBucketId?: string | null
    name?: string | null
    state?: string | null
    updatedAt?: string | null
    userStatus?: ProfileStatus | null
    zipCode?: string | null
    counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
    desktopSettings?: {
      __typename?: 'BucketGallerySettings'
      cropMode?: boolean | null
      groupedBy?: BucketMediaGroupedBy | null
      flatten?: boolean | null
      layout?: BucketGalleryGridLayout | null
      showImageTitle?: boolean | null
      zoom?: number | null
      filterBy?: {
        __typename?: 'BucketUserFilterBy'
        isFavorite?: boolean | null
        mediaType?: BucketMediaTypeFilter | null
        owner?: BucketMediaContentOwner | null
      } | null
      sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
    } | null
    linkSettings?: {
      __typename?: 'LinkSettings'
      directLink?: boolean | null
      htmlLarge?: boolean | null
      htmlThumb?: boolean | null
      imgLarge?: boolean | null
      imgThumb?: boolean | null
    } | null
    migrationMetadata?: {
      __typename?: 'ProfileMigrationMetadata'
      bucketId?: string | null
      migrationStatus: ProfileMigrationStatus
      approximateMediaCount?: number | null
      hasKafkaBucket?: boolean | null
      migratedMediaCount?: number | null
    } | null
    optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
    subscription?: {
      __typename?: 'Subscription'
      id: string
      createdAt: string
      expirationDate?: string | null
      firstBillingDate?: string | null
      isTrial?: boolean | null
      isCanceled?: boolean | null
      lastBillingDate?: string | null
      planName?: string | null
      recurring?: boolean | null
      trialExpDate?: string | null
      updatedAt?: string | null
      userId: string
      customPlanProperties?: {
        __typename?: 'CustomPlanProperties'
        planName?: string | null
        planTitle?: string | null
        storageLimitBytes?: number | null
        mediaLimitCount?: number | null
        hostingIncluded?: boolean | null
        price?: number | null
      } | null
      subscription?: {
        __typename?: 'SubscriptionInfo'
        id?: string | null
        status?: SubscriptionStatus | null
        account?: SubscriptionAccount | null
      } | null
    } | null
    usageReason?: {
      __typename?: 'UsageReason'
      personal?: boolean | null
      business?: boolean | null
      storage?: boolean | null
      hosting?: boolean | null
      sharing?: boolean | null
    } | null
  } | null
}

export type BucketsByUserIdDataTransferQueryVariables = Exact<{
  userId: Scalars['ID']
  nextToken?: InputMaybe<Scalars['String']>
}>

export type BucketsByUserIdDataTransferQuery = {
  __typename?: 'Query'
  bucketsByUserId?: {
    __typename?: 'BucketsByUserIdResults'
    nextToken?: string | null
    items: Array<{
      __typename?: 'Bucket'
      id: string
      architecture?: Architecture | null
      ownerId?: string | null
      title: string
      isBanned?: boolean | null
      bucketUser?: {
        __typename?: 'BucketUser'
        id: string
        permissions?: {
          __typename?: 'PermissionPolicy'
          mediaCreate?: boolean | null
          mediaDelete?: boolean | null
          mediaGet?: boolean | null
          mediaUpdate?: boolean | null
        } | null
      } | null
    }>
  } | null
}

export type ChangeEmailMutationVariables = Exact<{
  newEmail: Scalars['String']
}>

export type ChangeEmailMutation = { __typename?: 'Mutation'; changeEmail: boolean }

export type ChangePasswordMutationVariables = Exact<{
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
  confirmNewPassword: Scalars['String']
}>

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword: boolean }

export type DeleteAlbumsWithContentSavingMutationVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
}>

export type DeleteAlbumsWithContentSavingMutation = { __typename?: 'Mutation'; deleteAlbumsWithContentSaving: boolean }

export type DtpFragmentFragment = {
  __typename?: 'DTP'
  id: string
  createdAt: string
  updatedAt?: string | null
  userId: string
  requests: {
    __typename?: 'DTPRequests'
    capture?: { __typename?: 'DTPRequest'; bucketId: string; requester: DtpRequester; createdAt: string; updatedAt?: string | null } | null
    facebook?: { __typename?: 'DTPRequest'; bucketId: string; requester: DtpRequester; createdAt: string; updatedAt?: string | null } | null
    print?: { __typename?: 'DTPRequest'; bucketId: string; requester: DtpRequester; createdAt: string; updatedAt?: string | null } | null
  }
}

export type EmailAvailableQueryVariables = Exact<{
  email: Scalars['String']
}>

export type EmailAvailableQuery = { __typename?: 'Query'; emailAvailable?: boolean | null }

export type EmailConfirmationAcceptMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type EmailConfirmationAcceptMutation = { __typename?: 'Mutation'; emailConfirmationAccept?: boolean | null }

export type EmailConfirmationResendMutationVariables = Exact<{ [key: string]: never }>

export type EmailConfirmationResendMutation = { __typename?: 'Mutation'; emailConfirmationResend?: boolean | null }

export type EmptyTrashMutationVariables = Exact<{ [key: string]: never }>

export type EmptyTrashMutation = { __typename?: 'Mutation'; emptyTrash: boolean }

export type GetAlbumDetailsQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetAlbumDetailsQuery = {
  __typename?: 'Query'
  getAlbumDetails: {
    __typename?: 'AlbumDetails'
    id: string
    username: string
    title?: string | null
    description?: string | null
    parentAlbumId?: string | null
    creationDate: string
    privacyMode: AlbumPrivacyMode
    totalUserImageCount: number
    imageCount: number
    usedSpace: number
    nestedAlbumsCount: number
    totalUserUsedSpace: number
    imageCountIncludeSubAlbums?: number | null
    sorting?: { __typename?: 'Sorting'; field?: SortingField | null; desc?: boolean | null } | null
  }
}

export type EditorGetAlbumQueryVariables = Exact<{
  id: Scalars['String']
}>

export type EditorGetAlbumQuery = {
  __typename?: 'Query'
  getAlbumForEditor: {
    __typename?: 'AlbumForEditor'
    albumId: string
    owner: string
    title?: string | null
    description?: string | null
    privacy: AlbumPrivacyMode
    passwords: Array<{ __typename?: 'AlbumPassword'; id: string; title?: string | null; password: string } | null>
  }
}

export type GetAlbumImagesV2QueryVariables = Exact<{
  albumId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  scrollPointer?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Sorter>
}>

export type GetAlbumImagesV2Query = {
  __typename?: 'Query'
  getAlbumImagesV2: {
    __typename?: 'ImageScrollPointer'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    } | null>
  }
}

export type GetAllPublicAlbumsQueryVariables = Exact<{
  sortBy: Sorter
  owner: Scalars['String']
}>

export type GetAllPublicAlbumsQuery = {
  __typename?: 'Query'
  getAllPublicAlbums: Array<{
    __typename?: 'AlbumTreeItem'
    id: string
    title?: string | null
    privacyMode: AlbumPrivacyMode
    parentAlbumId?: string | null
    imageCount: number
  } | null>
}

export type GetDirectLinksQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}>

export type GetDirectLinksQuery = { __typename?: 'Query'; getDirectLinks: Array<string | null> }

export type GetDirectVideoLinksQueryVariables = Exact<{
  ids: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  password?: InputMaybe<Scalars['String']>
}>

export type GetDirectVideoLinksQuery = { __typename?: 'Query'; getDirectVideoLinks: Array<string | null> }

export type GetForeignUserQueryVariables = Exact<{
  username?: InputMaybe<Scalars['String']>
}>

export type GetForeignUserQuery = {
  __typename?: 'Query'
  getForeignUser: { __typename?: 'ForeignUser'; username: string; defaultAlbum?: string | null }
}

export type GetImageNextBlockScrollPointerQueryVariables = Exact<{
  currentScrollPointer: Scalars['String']
  sortingField: SortingField
  desc: Scalars['Boolean']
  lookupForAlbum?: InputMaybe<Scalars['Boolean']>
}>

export type GetImageNextBlockScrollPointerQuery = { __typename?: 'Query'; getImageNextBlockScrollPointer?: string | null }

export type GetImageScrollPointerByDateQueryVariables = Exact<{
  date: Scalars['String']
  sortingField: SortingField
  forEndOfPeriod: Scalars['Boolean']
}>

export type GetImageScrollPointerByDateQuery = { __typename?: 'Query'; getImageScrollPointerByDate?: string | null }

export type GetImagesFromAlbumAndSubAlbumsQueryVariables = Exact<{
  albumId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  scrollPointer?: InputMaybe<Scalars['String']>
  sortBy?: InputMaybe<Sorter>
}>

export type GetImagesFromAlbumAndSubAlbumsQuery = {
  __typename?: 'Query'
  getImagesFromAlbumAndSubAlbums: {
    __typename?: 'ImageScrollPointer'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    } | null>
  }
}

export type GetMigrationProgressStatisticQueryVariables = Exact<{ [key: string]: never }>

export type GetMigrationProgressStatisticQuery = {
  __typename?: 'Query'
  getMigrationProgressStatistic?: {
    __typename?: 'MigrationProgressStatisticType'
    totalImagesCount?: number | null
    processedImagesCount?: number | null
    status?: UserMigrationStatus | null
  } | null
}

export type GetProfileQueryVariables = Exact<{ [key: string]: never }>

export type GetProfileQuery = {
  __typename?: 'Query'
  getProfile?: {
    __typename?: 'AuthenticatedUser'
    id: string
    token: string
    tokenType: TokenType
    email: string
    phoneNumber?: string | null
    username: string
    birthDate?: string | null
    defaultAlbum: string
    mobileAlbum?: string | null
    firstName?: string | null
    lastName?: string | null
    country?: string | null
    zip?: string | null
    sex?: Sex | null
    stateOrProvince?: string | null
    createdAt?: string | null
    emailStatus: EmailStatus
    isPasswordExpired: boolean
    migrationStatus?: UserMigrationStatus | null
    albumsSettings: {
      __typename?: 'AlbumsSettings'
      easyLinkingMode: boolean
      showImageTitle: boolean
      linkBackOption: boolean
      defaultSortRule: { __typename?: 'Sorting'; desc?: boolean | null; field?: SortingField | null }
      links: {
        __typename?: 'MediaLinksSettings'
        email: boolean
        direct: boolean
        htmlCode: boolean
        htmlThumb: boolean
        imgThumb: boolean
        img: boolean
      }
    }
    usageReasons: { __typename?: 'UsageReasons'; personal: boolean; bussiness: boolean; storage: boolean; hosting: boolean; personalSharing: boolean }
    subscription: {
      __typename?: 'UserSubscription'
      expDate?: string | null
      subscriptionStatus?: UserSubscriptionStatus | null
      recurring?: boolean | null
      account?: BillingAccount | null
      totalUserUsedSpace: number
      totalImagesCount: number
      isTrial: boolean
      notifications: Array<ModalNotificationType | null>
      trialExpDate?: string | null
      firstBillingDate?: string | null
      lastBillingDate?: string | null
      restrictions?: Array<{ __typename?: 'SubscriptionRestriction'; key: RestrictionEnum; value: boolean }> | null
      thresholdDetails?: { __typename?: 'Threshold'; usedSpace: number; maxSpace?: number | null } | null
      plan: {
        __typename?: 'UserSubscriptionPlan'
        maxImagesCount?: number | null
        maxSpace?: number | null
        planId: string
        title: string
        hostingThreshold?: { __typename?: 'HostingThreshold'; maxHostingThreshold: number } | null
      }
    }
  } | null
  getSupportedFileTypes: {
    __typename?: 'SupportedFileTypes'
    imageTypes: Array<string | null>
    videoTypes: Array<string | null>
    maxImageFileSizeInBytes: number
    maxVideoFileSizeInBytes: number
  }
  getTrashAlbumDetails: { __typename?: 'AlbumDetails'; id: string; imageCount: number; usedSpace: number }
}

export type GetPublicAlbumDetailsQueryVariables = Exact<{
  albumId: Scalars['String']
  owner: Scalars['String']
  password?: InputMaybe<Scalars['String']>
}>

export type GetPublicAlbumDetailsQuery = {
  __typename?: 'Query'
  getPublicAlbumDetails: {
    __typename?: 'AlbumDetails'
    id: string
    title?: string | null
    privacyMode: AlbumPrivacyMode
    description?: string | null
    nestedAlbumsCount: number
    parentAlbumId?: string | null
    totalUserUsedSpace: number
    totalUserImageCount: number
    imageCountIncludeSubAlbums?: number | null
    imageCount: number
    sorting?: { __typename?: 'Sorting'; field?: SortingField | null; desc?: boolean | null } | null
  }
}

export type GetPublicAlbumImagesV2QueryVariables = Exact<{
  albumId: Scalars['String']
  sortBy?: InputMaybe<Sorter>
  scrollPointer?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['Int']>
  password?: InputMaybe<Scalars['String']>
}>

export type GetPublicAlbumImagesV2Query = {
  __typename?: 'Query'
  getPublicAlbumImagesV2: {
    __typename?: 'ImageScrollPointer'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    } | null>
  }
}

export type GetPublicImageQueryVariables = Exact<{
  imageId: Scalars['String']
  password?: InputMaybe<Scalars['String']>
}>

export type GetPublicImageQuery = {
  __typename?: 'Query'
  getPublicImage?: {
    __typename?: 'Image'
    id: string
    title: string
    dateTaken?: string | null
    uploadDate: string
    isVideoType: boolean
    username: string
    isBlurred: boolean
    nsfw?: boolean | null
    status: Array<MediaStatus | null>
    albumId: string
    description?: string | null
    userTags: Array<string | null>
    clarifaiTags: Array<string | null>
    originalFilename: string
    isMobileUpload: boolean
    albumName: string
    attributes?: Array<string | null> | null
    image: {
      __typename?: 'ImageFile'
      width: number
      size: number
      height: number
      url: string
      isLandscape?: boolean | null
      exif?: {
        __typename?: 'Exif'
        longitude?: string | null
        eastOrWestLongitude?: string | null
        latitude?: string | null
        northOrSouthLatitude?: string | null
        altitude?: string | null
        altitudeRef?: string | null
        cameraBrand?: string | null
        cameraModel?: string | null
      } | null
    }
    thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
    deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
  } | null
}

export type GetPublicSubAlbumsV2QueryVariables = Exact<{
  albumId: Scalars['String']
  sortBy?: InputMaybe<Sorter>
  pageSize?: InputMaybe<Scalars['Int']>
  scrollPointer?: InputMaybe<Scalars['String']>
  nestedImagesCount?: InputMaybe<Scalars['Int']>
  username: Scalars['String']
}>

export type GetPublicSubAlbumsV2Query = {
  __typename?: 'Query'
  getPublicSubAlbumsV2: {
    __typename?: 'SubAlbumsScrollPointer'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'SubAlbum'
      id: string
      title?: string | null
      privacyMode: AlbumPrivacyMode
      imageCount: number
      hasNestedAlbums: boolean
      images: Array<{
        __typename?: 'Image'
        id: string
        uploadDate: string
        isVideoType: boolean
        isBlurred: boolean
        thumbnailImage: { __typename?: 'ImageFile'; url: string }
        originalImage: { __typename?: 'ImageFile'; url: string }
      }>
    } | null>
  }
}

export type GetSignedUrlQueryVariables = Exact<{
  bucketId: Scalars['ID']
  mediaId: Scalars['ID']
}>

export type GetSignedUrlQuery = { __typename?: 'Query'; bucketMedia?: { __typename?: 'BucketMedia'; signedUrl?: string | null } | null }

export type GetSupportedFileTypesQueryVariables = Exact<{ [key: string]: never }>

export type GetSupportedFileTypesQuery = {
  __typename?: 'Query'
  getSupportedFileTypes: {
    __typename?: 'SupportedFileTypes'
    imageTypes: Array<string | null>
    videoTypes: Array<string | null>
    maxImageFileSizeInBytes: number
    maxVideoFileSizeInBytes: number
  }
}

export type GetTrashAlbumDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetTrashAlbumDetailsQuery = {
  __typename?: 'Query'
  getTrashAlbumDetails: { __typename?: 'AlbumDetails'; id: string; imageCount: number; usedSpace: number }
}

export type GetTrashImagesQueryVariables = Exact<{
  pageSize: Scalars['Int']
  trashSorter: TrashSorterInput
  scrollPointer?: InputMaybe<Scalars['String']>
}>

export type GetTrashImagesQuery = {
  __typename?: 'Query'
  getTrashImages: {
    __typename?: 'ImageScrollPointer'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    } | null>
  }
  getTrashAlbumDetails: { __typename?: 'AlbumDetails'; id: string; imageCount: number; usedSpace: number }
}

export type GetUserImagesQueryVariables = Exact<{
  scrollPointer: Scalars['String']
  scrollDirection: ScrollDirection
  pageSize: Scalars['Int']
  sortBy: Sorter
}>

export type GetUserImagesQuery = {
  __typename?: 'Query'
  getUserImages: {
    __typename?: 'UserImagesScrollPointer'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    } | null>
  }
}

export type GetUserTagsQueryVariables = Exact<{
  tagPattern: Scalars['String']
}>

export type GetUserTagsQuery = { __typename?: 'Query'; getUserTags?: Array<string | null> | null }

export type HostedImagesQueryVariables = Exact<{
  pageSize: Scalars['Int']
  scrollPointer?: InputMaybe<Scalars['String']>
}>

export type HostedImagesQuery = {
  __typename?: 'Query'
  hostedImages: {
    __typename?: 'HostedImageResult'
    scrollPointer?: string | null
    images: Array<{
      __typename?: 'HostedImage'
      imageId: string
      views: number
      bandwidth: any
      lastView: string
      originalImage: { __typename?: 'ImageFile'; url: string }
    } | null>
  }
}

export type ImageQueryVariables = Exact<{
  id: Scalars['String']
}>

export type ImageQuery = {
  __typename?: 'Query'
  image?: {
    __typename?: 'Image'
    id: string
    title: string
    dateTaken?: string | null
    uploadDate: string
    isVideoType: boolean
    username: string
    isBlurred: boolean
    nsfw?: boolean | null
    status: Array<MediaStatus | null>
    albumId: string
    description?: string | null
    userTags: Array<string | null>
    clarifaiTags: Array<string | null>
    originalFilename: string
    isMobileUpload: boolean
    albumName: string
    attributes?: Array<string | null> | null
    image: {
      __typename?: 'ImageFile'
      width: number
      size: number
      height: number
      url: string
      isLandscape?: boolean | null
      exif?: {
        __typename?: 'Exif'
        longitude?: string | null
        eastOrWestLongitude?: string | null
        latitude?: string | null
        northOrSouthLatitude?: string | null
        altitude?: string | null
        altitudeRef?: string | null
        cameraBrand?: string | null
        cameraModel?: string | null
      } | null
    }
    thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
    deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
  } | null
}

export type ImageReferrersQueryVariables = Exact<{
  imageId: Scalars['ID']
}>

export type ImageReferrersQuery = {
  __typename?: 'Query'
  imageReferrers: Array<{ __typename?: 'ImageReferrer'; views: number; lastView: string; bandwidth: any; referrer: string } | null>
}

export type ImageUpdateMutationVariables = Exact<{
  imageId: Scalars['String']
  title?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  userTags?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
}>

export type ImageUpdateMutation = {
  __typename?: 'Mutation'
  imageUpdate: {
    __typename?: 'Image'
    id: string
    title: string
    dateTaken?: string | null
    uploadDate: string
    isVideoType: boolean
    username: string
    isBlurred: boolean
    nsfw?: boolean | null
    status: Array<MediaStatus | null>
    albumId: string
    description?: string | null
    userTags: Array<string | null>
    clarifaiTags: Array<string | null>
    originalFilename: string
    isMobileUpload: boolean
    albumName: string
    attributes?: Array<string | null> | null
    image: {
      __typename?: 'ImageFile'
      width: number
      size: number
      height: number
      url: string
      isLandscape?: boolean | null
      exif?: {
        __typename?: 'Exif'
        longitude?: string | null
        eastOrWestLongitude?: string | null
        latitude?: string | null
        northOrSouthLatitude?: string | null
        altitude?: string | null
        altitudeRef?: string | null
        cameraBrand?: string | null
        cameraModel?: string | null
      } | null
    }
    thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
    deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
  }
}

export type ImagesDeleteMutationVariables = Exact<{
  albumId?: InputMaybe<Scalars['String']>
  imageIds: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
}>

export type ImagesDeleteMutation = { __typename?: 'Mutation'; imagesDelete: Array<string> }

export type ImagesMoveMutationVariables = Exact<{
  targetAlbumId: Scalars['String']
  imageIds: Array<Scalars['String']> | Scalars['String']
}>

export type ImagesMoveMutation = {
  __typename?: 'Mutation'
  imagesMove: Array<{
    __typename?: 'Image'
    imageId: string
    id: string
    title: string
    dateTaken?: string | null
    uploadDate: string
    isVideoType: boolean
    username: string
    isBlurred: boolean
    nsfw?: boolean | null
    status: Array<MediaStatus | null>
    albumId: string
    description?: string | null
    userTags: Array<string | null>
    clarifaiTags: Array<string | null>
    originalFilename: string
    isMobileUpload: boolean
    albumName: string
    attributes?: Array<string | null> | null
    image: {
      __typename?: 'ImageFile'
      width: number
      size: number
      height: number
      url: string
      isLandscape?: boolean | null
      exif?: {
        __typename?: 'Exif'
        longitude?: string | null
        eastOrWestLongitude?: string | null
        latitude?: string | null
        northOrSouthLatitude?: string | null
        altitude?: string | null
        altitudeRef?: string | null
        cameraBrand?: string | null
        cameraModel?: string | null
      } | null
    }
    thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
    deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
  }>
}

export type ImagesMoveToTrashMutationVariables = Exact<{
  imageIds: Array<Scalars['String']> | Scalars['String']
}>

export type ImagesMoveToTrashMutation = { __typename?: 'Mutation'; imagesMoveToTrash: Array<string> }

export type ImagesReadByAttributesQueryVariables = Exact<{
  attributes: Array<Scalars['String']> | Scalars['String']
  pageSize: Scalars['Int']
  sortBy: SortingField
  desc: Scalars['Boolean']
  scrollPointer?: InputMaybe<Scalars['String']>
}>

export type ImagesReadByAttributesQuery = {
  __typename?: 'Query'
  imagesReadByAttributes: {
    __typename?: 'ImagesByAttributesSearchResult'
    scrollPointer?: string | null
    total: number
    items?: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    }> | null
  }
}

export type ImagesUpdateAttributesMutationVariables = Exact<{
  imageIds: Array<Scalars['String']> | Scalars['String']
  add?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
  delete?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>
}>

export type ImagesUpdateAttributesMutation = {
  __typename?: 'Mutation'
  imagesUpdateAttributes: Array<{
    __typename?: 'Image'
    id: string
    title: string
    dateTaken?: string | null
    uploadDate: string
    isVideoType: boolean
    username: string
    isBlurred: boolean
    nsfw?: boolean | null
    status: Array<MediaStatus | null>
    albumId: string
    description?: string | null
    userTags: Array<string | null>
    clarifaiTags: Array<string | null>
    originalFilename: string
    isMobileUpload: boolean
    albumName: string
    attributes?: Array<string | null> | null
    image: {
      __typename?: 'ImageFile'
      width: number
      size: number
      height: number
      url: string
      isLandscape?: boolean | null
      exif?: {
        __typename?: 'Exif'
        longitude?: string | null
        eastOrWestLongitude?: string | null
        latitude?: string | null
        northOrSouthLatitude?: string | null
        altitude?: string | null
        altitudeRef?: string | null
        cameraBrand?: string | null
        cameraModel?: string | null
      } | null
    }
    thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
    livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
    deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
  }>
}

export type LoginMutationVariables = Exact<{
  username: Scalars['String']
  password: Scalars['String']
  captchaToken: Scalars['String']
  captchaSource: CaptchaSource
}>

export type LoginMutation = {
  __typename?: 'Mutation'
  login?: {
    __typename?: 'AuthenticatedUser'
    token: string
    username: string
    email: string
    migrationStatus?: UserMigrationStatus | null
    subscription: {
      __typename?: 'UserSubscription'
      totalUserUsedSpace: number
      totalImagesCount: number
      thresholdDetails?: { __typename?: 'Threshold'; usedSpace: number; maxSpace?: number | null } | null
      plan: { __typename?: 'UserSubscriptionPlan'; maxImagesCount?: number | null; maxSpace?: number | null; planId: string; title: string }
    }
  } | null
}

export type MediaFragmentFragment = {
  __typename?: 'Image'
  id: string
  title: string
  dateTaken?: string | null
  uploadDate: string
  isVideoType: boolean
  username: string
  isBlurred: boolean
  nsfw?: boolean | null
  status: Array<MediaStatus | null>
  albumId: string
  description?: string | null
  userTags: Array<string | null>
  clarifaiTags: Array<string | null>
  originalFilename: string
  isMobileUpload: boolean
  albumName: string
  attributes?: Array<string | null> | null
  image: {
    __typename?: 'ImageFile'
    width: number
    size: number
    height: number
    url: string
    isLandscape?: boolean | null
    exif?: {
      __typename?: 'Exif'
      longitude?: string | null
      eastOrWestLongitude?: string | null
      latitude?: string | null
      northOrSouthLatitude?: string | null
      altitude?: string | null
      altitudeRef?: string | null
      cameraBrand?: string | null
      cameraModel?: string | null
    } | null
  }
  thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
  originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
  livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
  deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
}

export type GetMonthsScrollPointerQueryVariables = Exact<{
  imageId: Scalars['ID']
  sortingField: DateSortingField
}>

export type GetMonthsScrollPointerQuery = { __typename?: 'Query'; monthsScrollPointer: string }

export type MonthsViewQueryVariables = Exact<{
  topDays: Scalars['Int']
  pagination: ViewPagination
  scroll?: InputMaybe<Scroll>
}>

export type MonthsViewQuery = {
  __typename?: 'Query'
  monthsView: {
    __typename?: 'MonthsView'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'MonthViewItem'
      localDate: string
      viewMoreStats: { __typename?: 'ViewMore'; itemsCount: number; mediaCount: number }
      previewStats: Array<{
        __typename?: 'PreviewStatsItem'
        dayOfMonth: string
        mediaCount: number
        previewImage: {
          __typename?: 'Image'
          albumId: string
          albumName: string
          dateTaken?: string | null
          description?: string | null
          imageId: string
          isBlurred: boolean
          isMobileUpload: boolean
          isVideoType: boolean
          originalFilename: string
          deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
          originalImage: { __typename?: 'ImageFile'; height: number; isLandscape?: boolean | null; size: number; url: string }
        }
      } | null>
    } | null>
  }
}

export type MoveAlbumsMutationVariables = Exact<{
  targetAlbumId: Scalars['String']
  albumIds: Array<Scalars['String']> | Scalars['String']
}>

export type MoveAlbumsMutation = { __typename?: 'Mutation'; moveAlbums: boolean }

export type ReadNotificationsMutationVariables = Exact<{
  notifications: Array<ModalNotificationType> | ModalNotificationType
}>

export type ReadNotificationsMutation = { __typename?: 'Mutation'; readNotifications: boolean }

export type RegisterSimplifiedMutationVariables = Exact<{
  email: Scalars['String']
  username: Scalars['String']
  password: Scalars['String']
  planId: Scalars['String']
  captchaToken: Scalars['String']
  captchaSource: CaptchaSource
  addons: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  isTrial?: InputMaybe<Scalars['Boolean']>
}>

export type RegisterSimplifiedMutation = {
  __typename?: 'Mutation'
  registerSimplified?: {
    __typename?: 'AuthenticatedUser'
    token: string
    username: string
    subscription: { __typename?: 'UserSubscription'; planId: string }
  } | null
}

export type RemoveUserTagsMutationVariables = Exact<{
  imageIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
  tags?: InputMaybe<Array<Scalars['String']> | Scalars['String']>
}>

export type RemoveUserTagsMutation = { __typename?: 'Mutation'; removeUserTags: boolean }

export type ResendActivationCodeMutationVariables = Exact<{ [key: string]: never }>

export type ResendActivationCodeMutation = { __typename?: 'Mutation'; resendActivationCode: boolean }

export type ResetPasswordMutationVariables = Exact<{
  code: Scalars['String']
  newPassword: Scalars['String']
  confirmNewPassword: Scalars['String']
}>

export type ResetPasswordMutation = { __typename?: 'Mutation'; resetPassword: boolean }

export type SearchImagesQueryVariables = Exact<{
  query: Scalars['String']
  page?: InputMaybe<Scalars['Int']>
  perPage?: InputMaybe<Scalars['Int']>
}>

export type SearchImagesQuery = {
  __typename?: 'Query'
  searchImages: {
    __typename?: 'ImageSearchResult'
    total?: number | null
    hasMore: boolean
    result?: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    } | null> | null
  }
}

export type SendResetPasswordCodeMutationVariables = Exact<{
  username: Scalars['String']
}>

export type SendResetPasswordCodeMutation = { __typename?: 'Mutation'; sendResetPasswordCode: boolean }

export type ShareByEmailMutationVariables = Exact<{
  captchaToken: Scalars['String']
  captchaSource: CaptchaSource
  to: Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>
  author: Scalars['String']
  name: Scalars['String']
  message: Scalars['String']
  directUrl: Scalars['String']
  fullscreenUrl: Scalars['String']
  mediaType: ShareByEmailMediaType
}>

export type ShareByEmailMutation = { __typename?: 'Mutation'; shareByEmail: boolean }

export type TurnOffAllHostingLinksMutationVariables = Exact<{ [key: string]: never }>

export type TurnOffAllHostingLinksMutation = { __typename?: 'Mutation'; turnOffAllHostingLinks: boolean }

export type TurnOffHostingLinksMutationVariables = Exact<{
  imageIds: Array<Scalars['String']> | Scalars['String']
}>

export type TurnOffHostingLinksMutation = {
  __typename?: 'Mutation'
  turnOffHostingLinks: Array<{ __typename?: 'Image'; title: string; imageId: string } | null>
}

export type UnbanImagesMutationVariables = Exact<{
  imageIds: Array<Scalars['String']> | Scalars['String']
}>

export type UnbanImagesMutation = {
  __typename?: 'Mutation'
  imagesUnban: {
    __typename?: 'ImageScrollPointer'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'Image'
      id: string
      title: string
      dateTaken?: string | null
      uploadDate: string
      isVideoType: boolean
      username: string
      isBlurred: boolean
      nsfw?: boolean | null
      status: Array<MediaStatus | null>
      albumId: string
      description?: string | null
      userTags: Array<string | null>
      clarifaiTags: Array<string | null>
      originalFilename: string
      isMobileUpload: boolean
      albumName: string
      attributes?: Array<string | null> | null
      image: {
        __typename?: 'ImageFile'
        width: number
        size: number
        height: number
        url: string
        isLandscape?: boolean | null
        exif?: {
          __typename?: 'Exif'
          longitude?: string | null
          eastOrWestLongitude?: string | null
          latitude?: string | null
          northOrSouthLatitude?: string | null
          altitude?: string | null
          altitudeRef?: string | null
          cameraBrand?: string | null
          cameraModel?: string | null
        } | null
      }
      thumbnailImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      originalImage: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null }
      livePhoto?: { __typename?: 'ImageFile'; width: number; size: number; height: number; url: string; isLandscape?: boolean | null } | null
      deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
    } | null>
  }
}

export type UpdateTokenMutationVariables = Exact<{ [key: string]: never }>

export type UpdateTokenMutation = { __typename?: 'Mutation'; updateToken: string }

export type UserAlbumsSettingsUpdateMutationVariables = Exact<{
  settings: AlbumsSettingsInput
}>

export type UserAlbumsSettingsUpdateMutation = {
  __typename?: 'Mutation'
  userAlbumsSettingsUpdate: {
    __typename?: 'AuthenticatedUser'
    id: string
    token: string
    tokenType: TokenType
    email: string
    phoneNumber?: string | null
    username: string
    birthDate?: string | null
    defaultAlbum: string
    mobileAlbum?: string | null
    firstName?: string | null
    lastName?: string | null
    country?: string | null
    zip?: string | null
    sex?: Sex | null
    stateOrProvince?: string | null
    createdAt?: string | null
    emailStatus: EmailStatus
    isPasswordExpired: boolean
    migrationStatus?: UserMigrationStatus | null
    albumsSettings: {
      __typename?: 'AlbumsSettings'
      easyLinkingMode: boolean
      showImageTitle: boolean
      linkBackOption: boolean
      defaultSortRule: { __typename?: 'Sorting'; desc?: boolean | null; field?: SortingField | null }
      links: {
        __typename?: 'MediaLinksSettings'
        email: boolean
        direct: boolean
        htmlCode: boolean
        htmlThumb: boolean
        imgThumb: boolean
        img: boolean
      }
    }
    usageReasons: { __typename?: 'UsageReasons'; personal: boolean; bussiness: boolean; storage: boolean; hosting: boolean; personalSharing: boolean }
    subscription: {
      __typename?: 'UserSubscription'
      expDate?: string | null
      subscriptionStatus?: UserSubscriptionStatus | null
      recurring?: boolean | null
      account?: BillingAccount | null
      totalUserUsedSpace: number
      totalImagesCount: number
      isTrial: boolean
      notifications: Array<ModalNotificationType | null>
      trialExpDate?: string | null
      firstBillingDate?: string | null
      lastBillingDate?: string | null
      restrictions?: Array<{ __typename?: 'SubscriptionRestriction'; key: RestrictionEnum; value: boolean }> | null
      thresholdDetails?: { __typename?: 'Threshold'; usedSpace: number; maxSpace?: number | null } | null
      plan: {
        __typename?: 'UserSubscriptionPlan'
        maxImagesCount?: number | null
        maxSpace?: number | null
        planId: string
        title: string
        hostingThreshold?: { __typename?: 'HostingThreshold'; maxHostingThreshold: number } | null
      }
    }
  }
}

export type UserLoginMutationVariables = Exact<{
  token: Scalars['String']
}>

export type UserLoginMutation = {
  __typename?: 'Mutation'
  userLogin: {
    __typename?: 'AuthenticatedUser'
    id: string
    token: string
    tokenType: TokenType
    email: string
    phoneNumber?: string | null
    username: string
    birthDate?: string | null
    defaultAlbum: string
    mobileAlbum?: string | null
    firstName?: string | null
    lastName?: string | null
    country?: string | null
    zip?: string | null
    sex?: Sex | null
    stateOrProvince?: string | null
    createdAt?: string | null
    emailStatus: EmailStatus
    isPasswordExpired: boolean
    migrationStatus?: UserMigrationStatus | null
    albumsSettings: {
      __typename?: 'AlbumsSettings'
      easyLinkingMode: boolean
      showImageTitle: boolean
      linkBackOption: boolean
      defaultSortRule: { __typename?: 'Sorting'; desc?: boolean | null; field?: SortingField | null }
      links: {
        __typename?: 'MediaLinksSettings'
        email: boolean
        direct: boolean
        htmlCode: boolean
        htmlThumb: boolean
        imgThumb: boolean
        img: boolean
      }
    }
    usageReasons: { __typename?: 'UsageReasons'; personal: boolean; bussiness: boolean; storage: boolean; hosting: boolean; personalSharing: boolean }
    subscription: {
      __typename?: 'UserSubscription'
      expDate?: string | null
      subscriptionStatus?: UserSubscriptionStatus | null
      recurring?: boolean | null
      account?: BillingAccount | null
      totalUserUsedSpace: number
      totalImagesCount: number
      isTrial: boolean
      notifications: Array<ModalNotificationType | null>
      trialExpDate?: string | null
      firstBillingDate?: string | null
      lastBillingDate?: string | null
      restrictions?: Array<{ __typename?: 'SubscriptionRestriction'; key: RestrictionEnum; value: boolean }> | null
      thresholdDetails?: { __typename?: 'Threshold'; usedSpace: number; maxSpace?: number | null } | null
      plan: {
        __typename?: 'UserSubscriptionPlan'
        maxImagesCount?: number | null
        maxSpace?: number | null
        planId: string
        title: string
        hostingThreshold?: { __typename?: 'HostingThreshold'; maxHostingThreshold: number } | null
      }
    }
  }
}

export type UserLoginRuleQueryVariables = Exact<{
  userId: Scalars['ID']
  planId: UserPlanId
  storageStatistics: UserStorageStatistics
}>

export type UserLoginRuleQuery = {
  __typename?: 'Query'
  userLoginRule?: {
    __typename: 'UserLoginRuleResponse'
    userId: string
    planId: UserPlanId
    displayMessage?: string | null
    redirectUri?: string | null
    isNewsBarDisplayed?: boolean | null
    displayHeadline?: string | null
    newsBarMessage?: string | null
    upgradePlan?: string | null
    discount?: string | null
  } | null
}

export type UserPasswordUpdateMutationVariables = Exact<{
  password: Scalars['String']
}>

export type UserPasswordUpdateMutation = { __typename?: 'Mutation'; userPasswordUpdate: boolean }

export type UserProfileQueryVariables = Exact<{ [key: string]: never }>

export type UserProfileQuery = {
  __typename?: 'Query'
  userProfile?: {
    __typename?: 'UserProfile'
    country?: string | null
    createdAt: string
    currentBucketId?: string | null
    firstName?: string | null
    gender?: Gender | null
    id: string
    isQuickLinksEnabled?: boolean | null
    lastName?: string | null
    latestTermsAccepted?: boolean | null
    legacyBucketId?: string | null
    name?: string | null
    state?: string | null
    updatedAt?: string | null
    userStatus?: ProfileStatus | null
    zipCode?: string | null
    counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
    desktopSettings?: {
      __typename?: 'BucketGallerySettings'
      cropMode?: boolean | null
      groupedBy?: BucketMediaGroupedBy | null
      flatten?: boolean | null
      layout?: BucketGalleryGridLayout | null
      showImageTitle?: boolean | null
      zoom?: number | null
      filterBy?: {
        __typename?: 'BucketUserFilterBy'
        isFavorite?: boolean | null
        mediaType?: BucketMediaTypeFilter | null
        owner?: BucketMediaContentOwner | null
      } | null
      sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
    } | null
    linkSettings?: {
      __typename?: 'LinkSettings'
      directLink?: boolean | null
      htmlLarge?: boolean | null
      htmlThumb?: boolean | null
      imgLarge?: boolean | null
      imgThumb?: boolean | null
    } | null
    migrationMetadata?: {
      __typename?: 'ProfileMigrationMetadata'
      bucketId?: string | null
      migrationStatus: ProfileMigrationStatus
      approximateMediaCount?: number | null
      hasKafkaBucket?: boolean | null
      migratedMediaCount?: number | null
    } | null
    optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
    subscription?: {
      __typename?: 'Subscription'
      id: string
      createdAt: string
      expirationDate?: string | null
      firstBillingDate?: string | null
      isTrial?: boolean | null
      isCanceled?: boolean | null
      lastBillingDate?: string | null
      planName?: string | null
      recurring?: boolean | null
      trialExpDate?: string | null
      updatedAt?: string | null
      userId: string
      customPlanProperties?: {
        __typename?: 'CustomPlanProperties'
        planName?: string | null
        planTitle?: string | null
        storageLimitBytes?: number | null
        mediaLimitCount?: number | null
        hostingIncluded?: boolean | null
        price?: number | null
      } | null
      subscription?: {
        __typename?: 'SubscriptionInfo'
        id?: string | null
        status?: SubscriptionStatus | null
        account?: SubscriptionAccount | null
      } | null
    } | null
    usageReason?: {
      __typename?: 'UsageReason'
      personal?: boolean | null
      business?: boolean | null
      storage?: boolean | null
      hosting?: boolean | null
      sharing?: boolean | null
    } | null
  } | null
}

export type UserProfileCreateMutationVariables = Exact<{
  data: UserProfileInput
  isConverted?: InputMaybe<Scalars['Boolean']>
  invitationId?: InputMaybe<Scalars['String']>
}>

export type UserProfileCreateMutation = {
  __typename?: 'Mutation'
  userProfileCreate?: {
    __typename?: 'UserProfile'
    country?: string | null
    createdAt: string
    currentBucketId?: string | null
    firstName?: string | null
    gender?: Gender | null
    id: string
    isQuickLinksEnabled?: boolean | null
    lastName?: string | null
    latestTermsAccepted?: boolean | null
    legacyBucketId?: string | null
    name?: string | null
    state?: string | null
    updatedAt?: string | null
    userStatus?: ProfileStatus | null
    zipCode?: string | null
    counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
    desktopSettings?: {
      __typename?: 'BucketGallerySettings'
      cropMode?: boolean | null
      groupedBy?: BucketMediaGroupedBy | null
      flatten?: boolean | null
      layout?: BucketGalleryGridLayout | null
      showImageTitle?: boolean | null
      zoom?: number | null
      filterBy?: {
        __typename?: 'BucketUserFilterBy'
        isFavorite?: boolean | null
        mediaType?: BucketMediaTypeFilter | null
        owner?: BucketMediaContentOwner | null
      } | null
      sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
    } | null
    linkSettings?: {
      __typename?: 'LinkSettings'
      directLink?: boolean | null
      htmlLarge?: boolean | null
      htmlThumb?: boolean | null
      imgLarge?: boolean | null
      imgThumb?: boolean | null
    } | null
    migrationMetadata?: {
      __typename?: 'ProfileMigrationMetadata'
      bucketId?: string | null
      migrationStatus: ProfileMigrationStatus
      approximateMediaCount?: number | null
      hasKafkaBucket?: boolean | null
      migratedMediaCount?: number | null
    } | null
    optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
    subscription?: {
      __typename?: 'Subscription'
      id: string
      createdAt: string
      expirationDate?: string | null
      firstBillingDate?: string | null
      isTrial?: boolean | null
      isCanceled?: boolean | null
      lastBillingDate?: string | null
      planName?: string | null
      recurring?: boolean | null
      trialExpDate?: string | null
      updatedAt?: string | null
      userId: string
      customPlanProperties?: {
        __typename?: 'CustomPlanProperties'
        planName?: string | null
        planTitle?: string | null
        storageLimitBytes?: number | null
        mediaLimitCount?: number | null
        hostingIncluded?: boolean | null
        price?: number | null
      } | null
      subscription?: {
        __typename?: 'SubscriptionInfo'
        id?: string | null
        status?: SubscriptionStatus | null
        account?: SubscriptionAccount | null
      } | null
    } | null
    usageReason?: {
      __typename?: 'UsageReason'
      personal?: boolean | null
      business?: boolean | null
      storage?: boolean | null
      hosting?: boolean | null
      sharing?: boolean | null
    } | null
  } | null
}

export type UserProfileFragmentFragment = {
  __typename?: 'UserProfile'
  country?: string | null
  createdAt: string
  currentBucketId?: string | null
  firstName?: string | null
  gender?: Gender | null
  id: string
  isQuickLinksEnabled?: boolean | null
  lastName?: string | null
  latestTermsAccepted?: boolean | null
  legacyBucketId?: string | null
  name?: string | null
  state?: string | null
  updatedAt?: string | null
  userStatus?: ProfileStatus | null
  zipCode?: string | null
  counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
  desktopSettings?: {
    __typename?: 'BucketGallerySettings'
    cropMode?: boolean | null
    groupedBy?: BucketMediaGroupedBy | null
    flatten?: boolean | null
    layout?: BucketGalleryGridLayout | null
    showImageTitle?: boolean | null
    zoom?: number | null
    filterBy?: {
      __typename?: 'BucketUserFilterBy'
      isFavorite?: boolean | null
      mediaType?: BucketMediaTypeFilter | null
      owner?: BucketMediaContentOwner | null
    } | null
    sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
  } | null
  linkSettings?: {
    __typename?: 'LinkSettings'
    directLink?: boolean | null
    htmlLarge?: boolean | null
    htmlThumb?: boolean | null
    imgLarge?: boolean | null
    imgThumb?: boolean | null
  } | null
  migrationMetadata?: {
    __typename?: 'ProfileMigrationMetadata'
    bucketId?: string | null
    migrationStatus: ProfileMigrationStatus
    approximateMediaCount?: number | null
    hasKafkaBucket?: boolean | null
    migratedMediaCount?: number | null
  } | null
  optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
  plan?: {
    __typename?: 'Plan'
    apple_id?: string | null
    billing_interval?: string | null
    is_favorites_enabled?: boolean | null
    is_hosting_enabled?: boolean | null
    is_create_bucket_enabled?: boolean | null
    is_image_editor_enabled?: boolean | null
    is_modify_albums_enabled?: boolean | null
    is_search_enabled?: boolean | null
    is_sharing_enabled?: boolean | null
    is_sharing_plan?: boolean | null
    is_sort_by_title_enabled?: boolean | null
    is_sort_by_upload_date_enabled?: boolean | null
    is_storage_plan?: boolean | null
    is_user_tags_enabled?: boolean | null
    is_video_player_enabled?: boolean | null
    media_limit?: number | null
    paddle_id?: string | null
    paypal_id?: string | null
    plan_name: string
    storage_limit?: number | null
    subscription_tier?: string | null
    trial_days?: number | null
    vindicia_id?: string | null
  } | null
  subscription?: {
    __typename?: 'Subscription'
    id: string
    createdAt: string
    expirationDate?: string | null
    firstBillingDate?: string | null
    isTrial?: boolean | null
    isCanceled?: boolean | null
    lastBillingDate?: string | null
    planName?: string | null
    recurring?: boolean | null
    trialExpDate?: string | null
    updatedAt?: string | null
    userId: string
    customPlanProperties?: {
      __typename?: 'CustomPlanProperties'
      planName?: string | null
      planTitle?: string | null
      storageLimitBytes?: number | null
      mediaLimitCount?: number | null
      hostingIncluded?: boolean | null
      price?: number | null
    } | null
    subscription?: {
      __typename?: 'SubscriptionInfo'
      id?: string | null
      status?: SubscriptionStatus | null
      account?: SubscriptionAccount | null
    } | null
  } | null
  usageReason?: {
    __typename?: 'UsageReason'
    personal?: boolean | null
    business?: boolean | null
    storage?: boolean | null
    hosting?: boolean | null
    sharing?: boolean | null
  } | null
}

export type UserProfileUpdateMutationVariables = Exact<{
  data: UserProfileInput
}>

export type UserProfileUpdateMutation = {
  __typename?: 'Mutation'
  userProfileUpdate?: {
    __typename?: 'UserProfile'
    country?: string | null
    createdAt: string
    currentBucketId?: string | null
    firstName?: string | null
    gender?: Gender | null
    id: string
    isQuickLinksEnabled?: boolean | null
    lastName?: string | null
    latestTermsAccepted?: boolean | null
    legacyBucketId?: string | null
    name?: string | null
    state?: string | null
    updatedAt?: string | null
    userStatus?: ProfileStatus | null
    zipCode?: string | null
    counters?: { __typename?: 'ProfileCounters'; totalMedia?: number | null; totalSize?: number | null } | null
    desktopSettings?: {
      __typename?: 'BucketGallerySettings'
      cropMode?: boolean | null
      groupedBy?: BucketMediaGroupedBy | null
      flatten?: boolean | null
      layout?: BucketGalleryGridLayout | null
      showImageTitle?: boolean | null
      zoom?: number | null
      filterBy?: {
        __typename?: 'BucketUserFilterBy'
        isFavorite?: boolean | null
        mediaType?: BucketMediaTypeFilter | null
        owner?: BucketMediaContentOwner | null
      } | null
      sortBy?: { __typename?: 'BucketUserSortBy'; field?: BucketSortingField | null; sortingOrder?: BucketSortingOrder | null } | null
    } | null
    linkSettings?: {
      __typename?: 'LinkSettings'
      directLink?: boolean | null
      htmlLarge?: boolean | null
      htmlThumb?: boolean | null
      imgLarge?: boolean | null
      imgThumb?: boolean | null
    } | null
    migrationMetadata?: {
      __typename?: 'ProfileMigrationMetadata'
      bucketId?: string | null
      migrationStatus: ProfileMigrationStatus
      approximateMediaCount?: number | null
      hasKafkaBucket?: boolean | null
      migratedMediaCount?: number | null
    } | null
    optIntoMarketingEmails?: { __typename?: 'MarketingPreference'; optedIn?: boolean | null; updatedAt?: string | null } | null
    plan?: {
      __typename?: 'Plan'
      apple_id?: string | null
      billing_interval?: string | null
      is_favorites_enabled?: boolean | null
      is_hosting_enabled?: boolean | null
      is_create_bucket_enabled?: boolean | null
      is_image_editor_enabled?: boolean | null
      is_modify_albums_enabled?: boolean | null
      is_search_enabled?: boolean | null
      is_sharing_enabled?: boolean | null
      is_sharing_plan?: boolean | null
      is_sort_by_title_enabled?: boolean | null
      is_sort_by_upload_date_enabled?: boolean | null
      is_storage_plan?: boolean | null
      is_user_tags_enabled?: boolean | null
      is_video_player_enabled?: boolean | null
      media_limit?: number | null
      paddle_id?: string | null
      paypal_id?: string | null
      plan_name: string
      storage_limit?: number | null
      subscription_tier?: string | null
      trial_days?: number | null
      vindicia_id?: string | null
    } | null
    subscription?: {
      __typename?: 'Subscription'
      id: string
      createdAt: string
      expirationDate?: string | null
      firstBillingDate?: string | null
      isTrial?: boolean | null
      isCanceled?: boolean | null
      lastBillingDate?: string | null
      planName?: string | null
      recurring?: boolean | null
      trialExpDate?: string | null
      updatedAt?: string | null
      userId: string
      customPlanProperties?: {
        __typename?: 'CustomPlanProperties'
        planName?: string | null
        planTitle?: string | null
        storageLimitBytes?: number | null
        mediaLimitCount?: number | null
        hostingIncluded?: boolean | null
        price?: number | null
      } | null
      subscription?: {
        __typename?: 'SubscriptionInfo'
        id?: string | null
        status?: SubscriptionStatus | null
        account?: SubscriptionAccount | null
      } | null
    } | null
    usageReason?: {
      __typename?: 'UsageReason'
      personal?: boolean | null
      business?: boolean | null
      storage?: boolean | null
      hosting?: boolean | null
      sharing?: boolean | null
    } | null
  } | null
}

export type UserStateStatusQueryVariables = Exact<{ [key: string]: never }>

export type UserStateStatusQuery = {
  __typename?: 'Query'
  userStateStatus: { __typename?: 'UserStateStatus'; rds: boolean; kafka: boolean; firebase: boolean; userId?: string | null }
}

export type UserUpdateMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>
  lastName?: InputMaybe<Scalars['String']>
  birthDate?: InputMaybe<Scalars['String']>
  sex?: InputMaybe<Sex>
  country?: InputMaybe<Scalars['String']>
  stateOrProvince?: InputMaybe<Scalars['String']>
  zip?: InputMaybe<Scalars['String']>
  usageReasons?: InputMaybe<UsageReasonsInput>
}>

export type UserUpdateMutation = {
  __typename?: 'Mutation'
  userUpdate: {
    __typename?: 'AuthenticatedUser'
    id: string
    token: string
    tokenType: TokenType
    email: string
    phoneNumber?: string | null
    username: string
    birthDate?: string | null
    defaultAlbum: string
    mobileAlbum?: string | null
    firstName?: string | null
    lastName?: string | null
    country?: string | null
    zip?: string | null
    sex?: Sex | null
    stateOrProvince?: string | null
    createdAt?: string | null
    emailStatus: EmailStatus
    isPasswordExpired: boolean
    migrationStatus?: UserMigrationStatus | null
    albumsSettings: {
      __typename?: 'AlbumsSettings'
      easyLinkingMode: boolean
      showImageTitle: boolean
      linkBackOption: boolean
      defaultSortRule: { __typename?: 'Sorting'; desc?: boolean | null; field?: SortingField | null }
      links: {
        __typename?: 'MediaLinksSettings'
        email: boolean
        direct: boolean
        htmlCode: boolean
        htmlThumb: boolean
        imgThumb: boolean
        img: boolean
      }
    }
    usageReasons: { __typename?: 'UsageReasons'; personal: boolean; bussiness: boolean; storage: boolean; hosting: boolean; personalSharing: boolean }
    subscription: {
      __typename?: 'UserSubscription'
      expDate?: string | null
      subscriptionStatus?: UserSubscriptionStatus | null
      recurring?: boolean | null
      account?: BillingAccount | null
      totalUserUsedSpace: number
      totalImagesCount: number
      isTrial: boolean
      notifications: Array<ModalNotificationType | null>
      trialExpDate?: string | null
      firstBillingDate?: string | null
      lastBillingDate?: string | null
      restrictions?: Array<{ __typename?: 'SubscriptionRestriction'; key: RestrictionEnum; value: boolean }> | null
      thresholdDetails?: { __typename?: 'Threshold'; usedSpace: number; maxSpace?: number | null } | null
      plan: {
        __typename?: 'UserSubscriptionPlan'
        maxImagesCount?: number | null
        maxSpace?: number | null
        planId: string
        title: string
        hostingThreshold?: { __typename?: 'HostingThreshold'; maxHostingThreshold: number } | null
      }
    }
  }
}

export type VerifyEmailMutationVariables = Exact<{
  code: Scalars['String']
}>

export type VerifyEmailMutation = {
  __typename?: 'Mutation'
  verifyEmail?: {
    __typename?: 'AuthenticatedUser'
    token: string
    username: string
    email: string
    migrationStatus?: UserMigrationStatus | null
    subscription: {
      __typename?: 'UserSubscription'
      totalUserUsedSpace: number
      totalImagesCount: number
      thresholdDetails?: { __typename?: 'Threshold'; usedSpace: number; maxSpace?: number | null } | null
    }
  } | null
}

export type YearsViewQueryVariables = Exact<{
  topMonths: Scalars['Int']
  pagination: ViewPagination
  scrollPointer?: InputMaybe<Scalars['String']>
}>

export type YearsViewQuery = {
  __typename?: 'Query'
  yearsView: {
    __typename?: 'YearsView'
    scrollPointer?: string | null
    items: Array<{
      __typename?: 'YearsViewItem'
      year: string
      previewStats: Array<{
        __typename?: 'YearsPreviewStatsItem'
        month: string
        mediaCount: number
        previewImage: {
          __typename?: 'Image'
          imageId: string
          originalFilename: string
          dateTaken?: string | null
          description?: string | null
          title: string
          nsfw?: boolean | null
          status: Array<MediaStatus | null>
          isVideoType: boolean
          isMobileUpload: boolean
          isBlurred: boolean
          albumId: string
          albumName: string
          originalImage: { __typename?: 'ImageFile'; url: string; width: number; height: number; size: number; isLandscape?: boolean | null }
          deletionState?: { __typename?: 'DeletionState'; deletedAt: string } | null
        }
      } | null>
    } | null>
  }
}

export const BucketBulkDownloadFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketBulkDownloadFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketBulkDownload' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'archives' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalMediaCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketBulkDownloadFragmentFragment, unknown>
export const AlbumFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AlbumFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AlbumV2' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'privacyMode' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentAlbumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imageCountIncludeSubAlbums' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nestedAlbumsCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AlbumFragmentFragment, unknown>
export const AuthenticatedUserFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AuthenticatedUserFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'AuthenticatedUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'token' } },
          { kind: 'Field', name: { kind: 'Name', value: 'tokenType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phoneNumber' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'birthDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultAlbum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mobileAlbum' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zip' } },
          { kind: 'Field', name: { kind: 'Name', value: 'sex' } },
          { kind: 'Field', name: { kind: 'Name', value: 'stateOrProvince' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'emailStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isPasswordExpired' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'albumsSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'defaultSortRule' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'desc' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'easyLinkingMode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showImageTitle' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkBackOption' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'links' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'direct' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlThumb' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imgThumb' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'img' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usageReasons' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'personal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bussiness' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hosting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'personalSharing' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'expDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring' } },
                { kind: 'Field', name: { kind: 'Name', value: 'account' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalUserUsedSpace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalImagesCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'notifications' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trialExpDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstBillingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastBillingDate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'restrictions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'key' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'thresholdDetails' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'usedSpace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSpace' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'plan' },
                  arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'login' }, value: { kind: 'StringValue', value: '', block: false } }],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'maxImagesCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'maxSpace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'hostingThreshold' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'maxHostingThreshold' } }] }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                    ]
                  }
                }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'migrationStatus' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AuthenticatedUserFragmentFragment, unknown>
export const BucketAlbumFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketAlbumFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketAlbum' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'subAlbumCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalSubalbums' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalMedia' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userTags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketAlbumFragmentFragment, unknown>
export const BucketFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Bucket' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'architecture' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'easyLinkingMode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'linkBackToAlbums' } },
                { kind: 'Field', name: { kind: 'Name', value: 'showImageTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'shareOptions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'directLink' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlCode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'htmlThumb' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'img' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imgThumb' } }
                    ]
                  }
                }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketUserCount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isConverted' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultBucket' } },
          { kind: 'Field', name: { kind: 'Name', value: 'defaultSortRule' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalMediaInTrash' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalMediaMinusTrash' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSizeInTrash' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSizeMinusTrash' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalAlbumCount' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'apple_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billing_interval' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_favorites_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_hosting_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_create_bucket_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_image_editor_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_modify_albums_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_search_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_sharing_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_sharing_plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_sort_by_title_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_sort_by_upload_date_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_storage_plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_user_tags_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_video_player_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'media_limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paddle_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paypal_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storage_limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscription_tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trial_days' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vindicia_id' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'subscriptionStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketFragmentFragment, unknown>
export const BucketInvitationFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketInvitationFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketInvitation' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketTitle' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invitationStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRole' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketInvitationFragmentFragment, unknown>
export const BucketMediaFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketMediaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVideo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketMediaFragmentFragment, unknown>
export const BucketMediaSharedFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketMediaSharedFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMedia' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'height' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVideo' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'width' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketMediaSharedFragmentFragment, unknown>
export const BucketShareFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketShareFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketShare' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'password' } },
          { kind: 'Field', name: { kind: 'Name', value: 'passwordProtected' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'totalMedia' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'shareStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isVideo' } },
                { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userTags' } },
                { kind: 'Field', name: { kind: 'Name', value: 'width' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketShareFragmentFragment, unknown>
export const BucketTransferFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketTransferFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketTransfer' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'transferStatus' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketTransferFragmentFragment, unknown>
export const BucketUserPermissionsFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketUserPermissionsFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'PermissionPolicy' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'albumCreate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'albumDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'albumGet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'albumUpdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketCreate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketGet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketUpdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invitationCreate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invitationDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invitationGet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'invitationUpdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaCreate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaGet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'mediaUpdate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userCreate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userDelete' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userGet' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userList' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userUpdate' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketUserPermissionsFragmentFragment, unknown>
export const UserProfileFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserProfileFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'UserProfile' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'counters' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalMedia' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalSize' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'country' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currentBucketId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'desktopSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'cropMode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'groupedBy' } },
                { kind: 'Field', name: { kind: 'Name', value: 'flatten' } },
                { kind: 'Field', name: { kind: 'Name', value: 'layout' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'filterBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'owner' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'showImageTitle' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sortBy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'sortingOrder' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'zoom' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isQuickLinksEnabled' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'latestTermsAccepted' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'linkSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'directLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'htmlLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'htmlThumb' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imgLarge' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imgThumb' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'legacyBucketId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'migrationMetadata' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'migrationStatus' } },
                { kind: 'Field', name: { kind: 'Name', value: 'approximateMediaCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasKafkaBucket' } },
                { kind: 'Field', name: { kind: 'Name', value: 'migratedMediaCount' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'optIntoMarketingEmails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'optedIn' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'plan' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'apple_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'billing_interval' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_favorites_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_hosting_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_create_bucket_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_image_editor_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_modify_albums_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_search_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_sharing_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_sharing_plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_sort_by_title_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_sort_by_upload_date_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_storage_plan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_user_tags_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'is_video_player_enabled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'media_limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paddle_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paypal_id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'plan_name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storage_limit' } },
                { kind: 'Field', name: { kind: 'Name', value: 'subscription_tier' } },
                { kind: 'Field', name: { kind: 'Name', value: 'trial_days' } },
                { kind: 'Field', name: { kind: 'Name', value: 'vindicia_id' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'state' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'subscription' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'customPlanProperties' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'planName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'planTitle' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'storageLimitBytes' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'mediaLimitCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hostingIncluded' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'price' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'expirationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firstBillingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTrial' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isCanceled' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastBillingDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'planName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'recurring' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'account' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'trialExpDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'usageReason' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'personal' } },
                { kind: 'Field', name: { kind: 'Name', value: 'business' } },
                { kind: 'Field', name: { kind: 'Name', value: 'storage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hosting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sharing' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'userStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'zipCode' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserProfileFragmentFragment, unknown>
export const BucketUserFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BucketUserFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketUser' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userStatus' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userRole' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updated' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'permissions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketUserPermissionsFragment' } }]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileFragment' } }] }
          }
        ]
      }
    },
    ...BucketUserPermissionsFragmentFragmentDoc.definitions,
    ...UserProfileFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketUserFragmentFragment, unknown>
export const DtpFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DTPFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'DTP' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requests' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'capture' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requester' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'facebook' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requester' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'print' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'requester' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } }
                    ]
                  }
                }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userId' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DtpFragmentFragment, unknown>
export const MediaFragmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MediaFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Image' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isVideoType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'username' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBlurred' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nsfw' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLandscape' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exif' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'longitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'eastOrWestLongitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'latitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'northOrSouthLatitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'altitude' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'altitudeRef' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cameraBrand' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'cameraModel' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnailImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLandscape' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLandscape' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'livePhoto' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isLandscape' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userTags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'clarifaiTags' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uploadDate' } },
          { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isMobileUpload' } },
          { kind: 'Field', name: { kind: 'Name', value: 'albumName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'attributes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletionState' },
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MediaFragmentFragment, unknown>
export const AddUserTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AddUserTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'addUserTags' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'tags' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'blacklisted' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AddUserTagsMutation, AddUserTagsMutationVariables>
export const AlbumCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AlbumCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'parentAlbumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'albumCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'parentAlbumId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'parentAlbumId' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'title' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AlbumFragment' } }] }
          }
        ]
      }
    },
    ...AlbumFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<AlbumCreateMutation, AlbumCreateMutationVariables>
export const AlbumUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AlbumUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'privacyMode' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'AlbumPrivacyMode' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addPasswords' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AlbumPasswordInput' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'removePasswords' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AlbumPasswordInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'albumUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'title' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'privacyMode' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'privacyMode' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'addPasswords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'addPasswords' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'removePasswords' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'removePasswords' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AlbumFragment' } }] }
          }
        ]
      }
    },
    ...AlbumFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<AlbumUpdateMutation, AlbumUpdateMutationVariables>
export const AlbumsMoveToTrashDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'AlbumsMoveToTrash' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'albumsMoveToTrash' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'albumIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AlbumsMoveToTrashMutation, AlbumsMoveToTrashMutationVariables>
export const AlbumsReadAllDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'AlbumsReadAll' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sorter' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'albumsReadAll' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AlbumFragment' } }] }
          }
        ]
      }
    },
    ...AlbumFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<AlbumsReadAllQuery, AlbumsReadAllQueryVariables>
export const BanImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BanImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesBan' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BanImagesMutation, BanImagesMutationVariables>
export const BucketDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Bucket' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucket' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketFragment' } }] }
          }
        ]
      }
    },
    ...BucketFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketQuery, BucketQueryVariables>
export const BucketActivitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketActivities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketActivities' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'timezone' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'timezone' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'activities' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketUploadedMediaActivity' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'lastName' } }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'albums' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'counters' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'totalSubalbums' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'totalMedia' } }
                                            ]
                                          }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'parentId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'subAlbumCount' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'thumbnails' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'aiTags' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isVideo' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isMobile' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'livePhoto' },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                                    { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } }
                                                  ]
                                                }
                                              },
                                              { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'moderationStatus' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'userTags' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'width' } }
                                            ]
                                          }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'media' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'aiTags' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'imageUrl' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isVideo' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isMobile' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isFavorite' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'livePhoto' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              { kind: 'Field', name: { kind: 'Name', value: 'filename' } },
                                              { kind: 'Field', name: { kind: 'Name', value: 'fileSize' } }
                                            ]
                                          }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'mediaType' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'moderationStatus' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'userTags' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'width' } }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'count' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'window' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'start' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'end' } }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }
                                ]
                              }
                            },
                            {
                              kind: 'InlineFragment',
                              typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketJoinedMembersActivity' } },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'user' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'lastName' } }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'timestamp' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketActivitiesQuery, BucketActivitiesQueryVariables>
export const BucketAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucket' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketFragment' } }] }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketAlbum' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketAlbumFragment' } }] }
          }
        ]
      }
    },
    ...BucketFragmentFragmentDoc.definitions,
    ...BucketAlbumFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketAlbumQuery, BucketAlbumQueryVariables>
export const BucketAlbumCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketAlbumCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketAlbumInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketAlbumCreate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketAlbumFragment' } }] }
          }
        ]
      }
    },
    ...BucketAlbumFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketAlbumCreateMutation, BucketAlbumCreateMutationVariables>
export const BucketAlbumUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketAlbumUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketAlbumInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketAlbumUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketAlbumFragment' } }] }
          }
        ]
      }
    },
    ...BucketAlbumFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketAlbumUpdateMutation, BucketAlbumUpdateMutationVariables>
export const BucketAlbumsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketAlbums' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketAlbums' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketAlbumFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketAlbumFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketAlbumsQuery, BucketAlbumsQueryVariables>
export const BucketAlbumsMoveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketAlbumsMove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketAlbumsMoveInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketAlbumsMove' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumIds' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketAlbumsMoveMutation, BucketAlbumsMoveMutationVariables>
export const BucketAlbumsMoveCopyBetweenBucketsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketAlbumsMoveCopyBetweenBuckets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketAlbumsMoveCopyBetweenBucketsInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketAlbumsMoveCopyBetweenBuckets' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumIds' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketAlbumsMoveCopyBetweenBucketsMutation, BucketAlbumsMoveCopyBetweenBucketsMutationVariables>
export const BucketAndAlbumsAndUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketAndAlbumsAndUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucket' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketFragment' } }] }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketAlbums' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketAlbumFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketUser' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketUserFragment' } }] }
          }
        ]
      }
    },
    ...BucketFragmentFragmentDoc.definitions,
    ...BucketAlbumFragmentFragmentDoc.definitions,
    ...BucketUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketAndAlbumsAndUserQuery, BucketAndAlbumsAndUserQueryVariables>
export const BucketAndUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketAndUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucket' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketFragment' } }] }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketUser' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketUserFragment' } }] }
          }
        ]
      }
    },
    ...BucketFragmentFragmentDoc.definitions,
    ...BucketUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketAndUsersQuery, BucketAndUsersQueryVariables>
export const BucketBulkDownloadsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketBulkDownloads' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BulkDownloadStatus' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketBulkDownloads' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'status' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketBulkDownloadFragment' } }]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...BucketBulkDownloadFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketBulkDownloadsQuery, BucketBulkDownloadsQueryVariables>
export const BucketBulkMediaUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketBulkMediaUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMediaUpdateInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketBulkMediaUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketBulkMediaUpdateMutation, BucketBulkMediaUpdateMutationVariables>
export const BucketCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketCreate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketFragment' } }] }
          }
        ]
      }
    },
    ...BucketFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketCreateMutation, BucketCreateMutationVariables>
export const BucketCredentialsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketCredentials' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketCredentials' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessKeyId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'secretAccessKey' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sessionToken' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketCredentialsQuery, BucketCredentialsQueryVariables>
export const BucketDowngradeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketDowngrade' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'consent' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketDowngrade' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'consent' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'consent' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketDowngradeMutation, BucketDowngradeMutationVariables>
export const BucketDtpRequesterAddDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketDtpRequesterAdd' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'requester' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DTPRequester' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketDtpRequesterAdd' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'requester' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'requester' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'DTPFragment' } }] }
          }
        ]
      }
    },
    ...DtpFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketDtpRequesterAddMutation, BucketDtpRequesterAddMutationVariables>
export const BucketGenerateDownloadLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketGenerateDownloadLinks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'media' } },
          type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albums' } },
          type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketGenerateDownloadLinks' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'media' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'media' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albums' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albums' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'requestId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'requestedAt' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketGenerateDownloadLinksQuery, BucketGenerateDownloadLinksQueryVariables>
export const BucketInvitationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketInvitation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketInvitation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketInvitationFragment' } }]
            }
          }
        ]
      }
    },
    ...BucketInvitationFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketInvitationQuery, BucketInvitationQueryVariables>
export const BucketInvitationAcceptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketInvitationAccept' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketInvitationAccept' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketUserFragment' } }] }
          }
        ]
      }
    },
    ...BucketUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketInvitationAcceptMutation, BucketInvitationAcceptMutationVariables>
export const BucketInvitationCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketInvitationCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userRole' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketUserRole' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'emails' } },
          type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketInvitationCreate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'userRole' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userRole' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'emails' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'emails' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketInvitationFragment' } }]
            }
          }
        ]
      }
    },
    ...BucketInvitationFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketInvitationCreateMutation, BucketInvitationCreateMutationVariables>
export const BucketInvitationQueryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketInvitationQuery' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userRole' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketUserRole' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketInvitationQuery' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'userRole' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userRole' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketInvitationFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketInvitationFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketInvitationQueryQuery, BucketInvitationQueryQueryVariables>
export const BucketInvitationResendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketInvitationResend' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketInvitationResend' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketInvitationFragment' } }]
            }
          }
        ]
      }
    },
    ...BucketInvitationFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketInvitationResendMutation, BucketInvitationResendMutationVariables>
export const BucketInvitationResetDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketInvitationReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketInvitationReset' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketInvitationResetMutation, BucketInvitationResetMutationVariables>
export const BucketInvitationUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketInvitationUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketInvitationStatus' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketInvitationUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'status' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketInvitationFragment' } }]
            }
          }
        ]
      }
    },
    ...BucketInvitationFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketInvitationUpdateMutation, BucketInvitationUpdateMutationVariables>
export const BucketInvitationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketInvitations' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketInvitations' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketInvitationFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketInvitationFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketInvitationsQuery, BucketInvitationsQueryVariables>
export const BucketLoginSuccessDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketLoginSuccess' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isAdmin' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketLoginSuccess' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'isAdmin' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'isAdmin' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileFragment' } }] }
          }
        ]
      }
    },
    ...UserProfileFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketLoginSuccessMutation, BucketLoginSuccessMutationVariables>
export const BucketMediaDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketMedia' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMedia' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketMediaFragment' } }] }
          }
        ]
      }
    },
    ...BucketMediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketMediaQuery, BucketMediaQueryVariables>
export const BucketMediaByAlbumIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketMediaByAlbumId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
          defaultValue: { kind: 'IntValue', value: '40' }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filterBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMediaFilter' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMediaSorter' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaByAlbumId' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'filterBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'filterBy' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketMediaFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketMediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketMediaByAlbumIdQuery, BucketMediaByAlbumIdQueryVariables>
export const BucketMediaByIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketMediaByIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaByIds' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketMediaFragment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketMediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketMediaByIdsQuery, BucketMediaByIdsQueryVariables>
export const BucketMediaByShareIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketMediaByShareId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaByShareId' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'shareId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketMediaSharedFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketMediaSharedFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketMediaByShareIdQuery, BucketMediaByShareIdQueryVariables>
export const BucketMediaByShareIdsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketMediaByShareIds' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaByShareIds' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'shareId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketMediaFragment' } },
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketMediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketMediaByShareIdsQuery, BucketMediaByShareIdsQueryVariables>
export const BucketMediaCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketMediaCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMediaInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaCreate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'filename' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'filename' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketMediaFragment' } }] }
          }
        ]
      }
    },
    ...BucketMediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketMediaCreateMutation, BucketMediaCreateMutationVariables>
export const BucketMediaMoveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketMediaMove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMediaMoveInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaMove' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketMediaMoveMutation, BucketMediaMoveMutationVariables>
export const BucketMediaMoveCopyBetweenBucketsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketMediaMoveCopyBetweenBuckets' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMediaMoveCopyBetweenBucketsInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaMoveCopyBetweenBuckets' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaIds' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketMediaMoveCopyBetweenBucketsMutation, BucketMediaMoveCopyBetweenBucketsMutationVariables>
export const BucketMediaSearchDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketMediaSearch' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaSearch' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'query' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketMediaFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketMediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketMediaSearchQuery, BucketMediaSearchQueryVariables>
export const BucketMediaSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketMediaSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMedia' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'filename' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketMediaSignedUrlQuery, BucketMediaSignedUrlQueryVariables>
export const BucketMediaUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketMediaUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketMediaUpdateInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMediaUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketMediaFragment' } }] }
          }
        ]
      }
    },
    ...BucketMediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketMediaUpdateMutation, BucketMediaUpdateMutationVariables>
export const BucketShareByIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketShareById' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketShareById' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'shareId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketShareFragment' } }] }
          }
        ]
      }
    },
    ...BucketShareFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketShareByIdQuery, BucketShareByIdQueryVariables>
export const BucketShareByIdProtectedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketShareByIdProtected' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketShareById' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'shareId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'passwordProtected' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bucketId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'password' } },
                { kind: 'Field', name: { kind: 'Name', value: 'shareStatus' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketShareByIdProtectedQuery, BucketShareByIdProtectedQueryVariables>
export const BucketShareCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketShareCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketShareCreateInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketShareCreate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketShareFragment' } }] }
          }
        ]
      }
    },
    ...BucketShareFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketShareCreateMutation, BucketShareCreateMutationVariables>
export const BucketSharePasswordValidateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketSharePasswordValidate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketSharePasswordValidate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'shareId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketSharePasswordValidateMutation, BucketSharePasswordValidateMutationVariables>
export const BucketShareUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketShareUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'status' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketShareStatus' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledDeletionAt' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'shareType' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketShareType' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketShareUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'shareId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'shareId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'status' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'status' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'title' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scheduledDeletionAt' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scheduledDeletionAt' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'shareType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'shareType' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketShareFragment' } }] }
          }
        ]
      }
    },
    ...BucketShareFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketShareUpdateMutation, BucketShareUpdateMutationVariables>
export const BucketSharesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketShares' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } },
          defaultValue: { kind: 'IntValue', value: '20' }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketShares' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'limit' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketShareFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketShareFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketSharesQuery, BucketSharesQueryVariables>
export const BucketTransferOwnerAcceptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketTransferOwnerAccept' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'transferId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketTransferOwnerAccept' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'transferId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'transferId' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketFragment' } }] }
          }
        ]
      }
    },
    ...BucketFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketTransferOwnerAcceptMutation, BucketTransferOwnerAcceptMutationVariables>
export const BucketTransferOwnerCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketTransferOwnerCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketTransferOwnerCreate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketTransferFragment' } }] }
          }
        ]
      }
    },
    ...BucketTransferFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketTransferOwnerCreateMutation, BucketTransferOwnerCreateMutationVariables>
export const BucketTrashEmptyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketTrashEmpty' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketTrashEmpty' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketTrashEmptyMutation, BucketTrashEmptyMutationVariables>
export const BucketUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketFragment' } }] }
          }
        ]
      }
    },
    ...BucketFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketUpdateMutation, BucketUpdateMutationVariables>
export const BucketUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketUser' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketUserFragment' } }] }
          }
        ]
      }
    },
    ...BucketUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketUserQuery, BucketUserQueryVariables>
export const BucketUserMigrateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketUserMigrate' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userMigrate' },
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'id' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketUserMigrateMutation, BucketUserMigrateMutationVariables>
export const BucketUserUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'BucketUserUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'BucketUserInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketUserUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketUserFragment' } }] }
          }
        ]
      }
    },
    ...BucketUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketUserUpdateMutation, BucketUserUpdateMutationVariables>
export const BucketUsersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketUsers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketUsers' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketUserFragment' } }]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } }
              ]
            }
          }
        ]
      }
    },
    ...BucketUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketUsersQuery, BucketUsersQueryVariables>
export const BucketsByUserIdDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketsByUserId' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketsByUserId' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'BucketFragment' } }] }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileFragment' } }] }
          }
        ]
      }
    },
    ...BucketFragmentFragmentDoc.definitions,
    ...UserProfileFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<BucketsByUserIdQuery, BucketsByUserIdQueryVariables>
export const BucketsByUserIdDataTransferDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'BucketsByUserIdDataTransfer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketsByUserId' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nextToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nextToken' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'nextToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'architecture' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'ownerId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'isBanned' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'bucketUser' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'permissions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'mediaCreate' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mediaDelete' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mediaGet' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'mediaUpdate' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BucketsByUserIdDataTransferQuery, BucketsByUserIdDataTransferQueryVariables>
export const ChangeEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangeEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newEmail' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changeEmail' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'newEmail' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'newEmail' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ChangeEmailMutation, ChangeEmailMutationVariables>
export const ChangePasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'confirmNewPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'changePassword' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentPassword' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'confirmNewPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'confirmNewPassword' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ChangePasswordMutation, ChangePasswordMutationVariables>
export const DeleteAlbumsWithContentSavingDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeleteAlbumsWithContentSaving' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: { kind: 'NonNullType', type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deleteAlbumsWithContentSaving' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'ids' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } } }]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DeleteAlbumsWithContentSavingMutation, DeleteAlbumsWithContentSavingMutationVariables>
export const EmailAvailableDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EmailAvailable' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailAvailable' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'email' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EmailAvailableQuery, EmailAvailableQueryVariables>
export const EmailConfirmationAcceptDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EmailConfirmationAccept' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailConfirmationAccept' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EmailConfirmationAcceptMutation, EmailConfirmationAcceptMutationVariables>
export const EmailConfirmationResendDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EmailConfirmationResend' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emailConfirmationResend' } }] }
    }
  ]
} as unknown as DocumentNode<EmailConfirmationResendMutation, EmailConfirmationResendMutationVariables>
export const EmptyTrashDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'EmptyTrash' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'emptyTrash' } }] }
    }
  ]
} as unknown as DocumentNode<EmptyTrashMutation, EmptyTrashMutationVariables>
export const GetAlbumDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlbumDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAlbumDetails' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentAlbumId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'creationDate' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyMode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalUserImageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedSpace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nestedAlbumsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalUserUsedSpace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCountIncludeSubAlbums' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sorting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'desc' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAlbumDetailsQuery, GetAlbumDetailsQueryVariables>
export const EditorGetAlbumDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'EditorGetAlbum' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAlbumForEditor' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'owner' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacy' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'passwords' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'password' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EditorGetAlbumQuery, EditorGetAlbumQueryVariables>
export const GetAlbumImagesV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAlbumImagesV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sorter' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAlbumImagesV2' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'pageSize' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetAlbumImagesV2Query, GetAlbumImagesV2QueryVariables>
export const GetAllPublicAlbumsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAllPublicAlbums' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sorter' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'owner' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getAllPublicAlbums' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'owner' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'owner' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyMode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentAlbumId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCount' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetAllPublicAlbumsQuery, GetAllPublicAlbumsQueryVariables>
export const GetDirectLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDirectLinks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: { kind: 'NonNullType', type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDirectLinks' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'ids' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetDirectLinksQuery, GetDirectLinksQueryVariables>
export const GetDirectVideoLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetDirectVideoLinks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } },
          type: { kind: 'NonNullType', type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getDirectVideoLinks' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'ids' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'ids' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetDirectVideoLinksQuery, GetDirectVideoLinksQueryVariables>
export const GetForeignUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetForeignUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
          defaultValue: { kind: 'StringValue', value: '', block: false }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getForeignUser' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'username' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'defaultAlbum' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetForeignUserQuery, GetForeignUserQueryVariables>
export const GetImageNextBlockScrollPointerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetImageNextBlockScrollPointer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currentScrollPointer' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortingField' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortingField' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'desc' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lookupForAlbum' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getImageNextBlockScrollPointer' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currentScrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currentScrollPointer' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortingField' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortingField' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'desc' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'desc' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'lookupForAlbum' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'lookupForAlbum' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetImageNextBlockScrollPointerQuery, GetImageNextBlockScrollPointerQueryVariables>
export const GetImageScrollPointerByDateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetImageScrollPointerByDate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'date' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortingField' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortingField' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'forEndOfPeriod' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getImageScrollPointerByDate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'date' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'date' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortingField' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortingField' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'forEndOfPeriod' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'forEndOfPeriod' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetImageScrollPointerByDateQuery, GetImageScrollPointerByDateQueryVariables>
export const GetImagesFromAlbumAndSubAlbumsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetImagesFromAlbumAndSubAlbums' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sorter' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getImagesFromAlbumAndSubAlbums' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'pageSize' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetImagesFromAlbumAndSubAlbumsQuery, GetImagesFromAlbumAndSubAlbumsQueryVariables>
export const GetMigrationProgressStatisticDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMigrationProgressStatistic' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getMigrationProgressStatistic' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'totalImagesCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'processedImagesCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetMigrationProgressStatisticQuery, GetMigrationProgressStatisticQueryVariables>
export const GetProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getProfile' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthenticatedUserFragment' } }]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSupportedFileTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imageTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxImageFileSizeInBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxVideoFileSizeInBytes' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTrashAlbumDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedSpace' } }
              ]
            }
          }
        ]
      }
    },
    ...AuthenticatedUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetProfileQuery, GetProfileQueryVariables>
export const GetPublicAlbumDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPublicAlbumDetails' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'owner' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPublicAlbumDetails' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'owner' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'owner' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'privacyMode' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'nestedAlbumsCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'parentAlbumId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalUserUsedSpace' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalUserImageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCountIncludeSubAlbums' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sorting' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'field' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'desc' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetPublicAlbumDetailsQuery, GetPublicAlbumDetailsQueryVariables>
export const GetPublicAlbumImagesV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPublicAlbumImagesV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sorter' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPublicAlbumImagesV2' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'pageSize' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetPublicAlbumImagesV2Query, GetPublicAlbumImagesV2QueryVariables>
export const GetPublicImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPublicImage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPublicImage' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetPublicImageQuery, GetPublicImageQueryVariables>
export const GetPublicSubAlbumsV2Document = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPublicSubAlbumsV2' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sorter' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'nestedImagesCount' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getPublicSubAlbumsV2' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'pageSize' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'nestedImagesCount' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'nestedImagesCount' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'username' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'privacyMode' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'imageCount' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'hasNestedAlbums' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'images' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'thumbnailImage' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'originalImage' },
                              selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] }
                            },
                            { kind: 'Field', name: { kind: 'Name', value: 'uploadDate' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isVideoType' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'isBlurred' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetPublicSubAlbumsV2Query, GetPublicSubAlbumsV2QueryVariables>
export const GetSignedUrlDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSignedUrl' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'bucketMedia' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'bucketId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'bucketId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'mediaId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaId' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'signedUrl' } }] }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetSignedUrlQuery, GetSignedUrlQueryVariables>
export const GetSupportedFileTypesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetSupportedFileTypes' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getSupportedFileTypes' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imageTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'videoTypes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxImageFileSizeInBytes' } },
                { kind: 'Field', name: { kind: 'Name', value: 'maxVideoFileSizeInBytes' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetSupportedFileTypesQuery, GetSupportedFileTypesQueryVariables>
export const GetTrashAlbumDetailsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrashAlbumDetails' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTrashAlbumDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedSpace' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetTrashAlbumDetailsQuery, GetTrashAlbumDetailsQueryVariables>
export const GetTrashImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTrashImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'trashSorter' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'TrashSorterInput' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTrashImages' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'pageSize' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'trashSorter' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'trashSorter' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getTrashAlbumDetails' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageCount' } },
                { kind: 'Field', name: { kind: 'Name', value: 'usedSpace' } }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetTrashImagesQuery, GetTrashImagesQueryVariables>
export const GetUserImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollDirection' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ScrollDirection' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sorter' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserImages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollDirection' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollDirection' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'pageSize' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetUserImagesQuery, GetUserImagesQueryVariables>
export const GetUserTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUserTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tagPattern' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'getUserTags' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'tagPattern' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'tagPattern' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetUserTagsQuery, GetUserTagsQueryVariables>
export const HostedImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'HostedImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hostedImages' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'pageSize' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'images' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'imageId' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'bandwidth' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lastView' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'originalImage' },
                        selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }] }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<HostedImagesQuery, HostedImagesQueryVariables>
export const ImageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'Image' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            arguments: [{ kind: 'Argument', name: { kind: 'Name', value: 'id' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'id' } } }],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<ImageQuery, ImageQueryVariables>
export const ImageReferrersDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImageReferrers' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageReferrers' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageId' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'views' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lastView' } },
                { kind: 'Field', name: { kind: 'Name', value: 'bandwidth' } },
                { kind: 'Field', name: { kind: 'Name', value: 'referrer' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ImageReferrersQuery, ImageReferrersQueryVariables>
export const ImageUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImageUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'title' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'description' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userTags' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imageUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'title' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'title' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'description' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'description' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'userTags' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userTags' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<ImageUpdateMutation, ImageUpdateMutationVariables>
export const ImagesDeleteDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImagesDelete' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: { kind: 'NonNullType', type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesDelete' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'albumId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ImagesDeleteMutation, ImagesDeleteMutationVariables>
export const ImagesMoveDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImagesMove' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetAlbumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesMove' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetAlbumId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetAlbumId' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'imageId' } },
                { kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<ImagesMoveMutation, ImagesMoveMutationVariables>
export const ImagesMoveToTrashDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImagesMoveToTrash' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesMoveToTrash' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ImagesMoveToTrashMutation, ImagesMoveToTrashMutationVariables>
export const ImagesReadByAttributesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'ImagesReadByAttributes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'SortingField' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'desc' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesReadByAttributes' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'attributes' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'attributes' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'pageSize' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'pageSize' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'sortBy' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sortBy' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'desc' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'desc' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<ImagesReadByAttributesQuery, ImagesReadByAttributesQueryVariables>
export const ImagesUpdateAttributesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ImagesUpdateAttributes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'add' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'delete' } },
          type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesUpdateAttributes' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'add' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'add' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'delete' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'delete' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<ImagesUpdateAttributesMutation, ImagesUpdateAttributesMutationVariables>
export const LoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'Login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'captchaToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'captchaSource' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaptchaSource' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'login' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'login' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'captchaToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'captchaToken' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'captchaSource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'captchaSource' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalUserUsedSpace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalImagesCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thresholdDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'usedSpace' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxSpace' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'plan' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'login' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } }
                          }
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'maxImagesCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxSpace' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'migrationStatus' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>
export const GetMonthsScrollPointerDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMonthsScrollPointer' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sortingField' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'DateSortingField' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthsScrollPointer' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'sortingField' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'sortingField' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GetMonthsScrollPointerQuery, GetMonthsScrollPointerQueryVariables>
export const MonthsViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'MonthsView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topDays' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewPagination' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scroll' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Scroll' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'monthsView' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'topDays' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'topDays' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'scroll' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'scroll' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'localDate' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'viewMoreStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'itemsCount' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'dayOfMonth' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'albumName' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deletionState' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } }]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'imageId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isBlurred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isMobileUpload' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isVideoType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'originalImage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isLandscape' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MonthsViewQuery, MonthsViewQueryVariables>
export const MoveAlbumsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'MoveAlbums' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'targetAlbumId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'albumIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'moveAlbums' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'targetAlbumId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'targetAlbumId' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'albumIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'albumIds' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MoveAlbumsMutation, MoveAlbumsMutationVariables>
export const ReadNotificationsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ReadNotifications' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'notifications' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ModalNotificationType' } } }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'readNotifications' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'notifications' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'notifications' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ReadNotificationsMutation, ReadNotificationsMutationVariables>
export const RegisterSimplifiedDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'RegisterSimplified' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'email' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'captchaToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'captchaSource' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaptchaSource' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'addons' } },
          type: { kind: 'NonNullType', type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isTrial' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'registerSimplified' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'email' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'email' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'username' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'planId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'captchaToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'captchaToken' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'captchaSource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'captchaSource' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'addons' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'addons' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'isTrial' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'isTrial' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'planId' } }] }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RegisterSimplifiedMutation, RegisterSimplifiedMutationVariables>
export const RemoveUserTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'removeUserTags' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } },
          type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'removeUserTags' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'tags' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'tags' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RemoveUserTagsMutation, RemoveUserTagsMutationVariables>
export const ResendActivationCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResendActivationCode' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'resendActivationCode' } }] }
    }
  ]
} as unknown as DocumentNode<ResendActivationCodeMutation, ResendActivationCodeMutationVariables>
export const ResetPasswordDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ResetPassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'confirmNewPassword' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resetPassword' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'code' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'newPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'newPassword' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'confirmNewPassword' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'confirmNewPassword' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ResetPasswordMutation, ResetPasswordMutationVariables>
export const SearchImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'query' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'page' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'searchImages' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'query' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'query' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'page' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'page' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'perPage' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'perPage' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'result' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'total' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasMore' } }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<SearchImagesQuery, SearchImagesQueryVariables>
export const SendResetPasswordCodeDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SendResetPasswordCode' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'username' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sendResetPasswordCode' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'login' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'username' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SendResetPasswordCodeMutation, SendResetPasswordCodeMutationVariables>
export const ShareByEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ShareByEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'captchaToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'captchaSource' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'CaptchaSource' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'to' } },
          type: { kind: 'NonNullType', type: { kind: 'ListType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'author' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'name' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'message' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'directUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'fullscreenUrl' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'mediaType' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ShareByEmailMediaType' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'shareByEmail' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'captchaToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'captchaToken' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'captchaSource' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'captchaSource' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'to' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'to' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'author' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'author' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'name' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'name' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'message' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'message' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'directUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'directUrl' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'fullscreenUrl' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'fullscreenUrl' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'mediaType' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'mediaType' } }
              }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ShareByEmailMutation, ShareByEmailMutationVariables>
export const TurnOffAllHostingLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'turnOffAllHostingLinks' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'turnOffAllHostingLinks' } }] }
    }
  ]
} as unknown as DocumentNode<TurnOffAllHostingLinksMutation, TurnOffAllHostingLinksMutationVariables>
export const TurnOffHostingLinksDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'turnOffHostingLinks' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'turnOffHostingLinks' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<TurnOffHostingLinksMutation, TurnOffHostingLinksMutationVariables>
export const UnbanImagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'unbanImages' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'ListType', type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'imagesUnban' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'imageIds' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'imageIds' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'MediaFragment' } }] }
                }
              ]
            }
          }
        ]
      }
    },
    ...MediaFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<UnbanImagesMutation, UnbanImagesMutationVariables>
export const UpdateTokenDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateToken' },
      selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'Field', name: { kind: 'Name', value: 'updateToken' } }] }
    }
  ]
} as unknown as DocumentNode<UpdateTokenMutation, UpdateTokenMutationVariables>
export const UserAlbumsSettingsUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserAlbumsSettingsUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'AlbumsSettingsInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userAlbumsSettingsUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'settings' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'settings' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthenticatedUserFragment' } }]
            }
          }
        ]
      }
    },
    ...AuthenticatedUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<UserAlbumsSettingsUpdateMutation, UserAlbumsSettingsUpdateMutationVariables>
export const UserLoginDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserLogin' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLogin' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'token' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthenticatedUserFragment' } }]
            }
          }
        ]
      }
    },
    ...AuthenticatedUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<UserLoginMutation, UserLoginMutationVariables>
export const UserLoginRuleDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserLoginRule' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserPlanId' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'storageStatistics' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserStorageStatistics' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userLoginRule' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'userId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'userId' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'planId' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'planId' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'storageStatistics' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'storageStatistics' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'planId' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'redirectUri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isNewsBarDisplayed' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayHeadline' } },
                { kind: 'Field', name: { kind: 'Name', value: 'newsBarMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'upgradePlan' } },
                { kind: 'Field', name: { kind: 'Name', value: 'discount' } },
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserLoginRuleQuery, UserLoginRuleQueryVariables>
export const UserPasswordUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserPasswordUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'password' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userPasswordUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'password' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'password' } } }
            ]
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserPasswordUpdateMutation, UserPasswordUpdateMutationVariables>
export const UserProfileDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserProfile' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfile' },
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileFragment' } }] }
          }
        ]
      }
    },
    ...UserProfileFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<UserProfileQuery, UserProfileQueryVariables>
export const UserProfileCreateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserProfileCreate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserProfileInput' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'isConverted' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Boolean' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfileCreate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'isConverted' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'isConverted' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'invitationId' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'invitationId' } }
              }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileFragment' } }] }
          }
        ]
      }
    },
    ...UserProfileFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<UserProfileCreateMutation, UserProfileCreateMutationVariables>
export const UserProfileUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UserProfileUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'data' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'UserProfileInput' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userProfileUpdate' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'data' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'data' } } }
            ],
            selectionSet: { kind: 'SelectionSet', selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'UserProfileFragment' } }] }
          }
        ]
      }
    },
    ...UserProfileFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<UserProfileUpdateMutation, UserProfileUpdateMutationVariables>
export const UserStateStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'UserStateStatus' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userStateStatus' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'rds' } },
                { kind: 'Field', name: { kind: 'Name', value: 'kafka' } },
                { kind: 'Field', name: { kind: 'Name', value: 'firebase' } },
                { kind: 'Field', name: { kind: 'Name', value: 'userId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserStateStatusQuery, UserStateStatusQueryVariables>
export const UserUpdateDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'userUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'birthDate' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'sex' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Sex' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'stateOrProvince' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'usageReasons' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'UsageReasonsInput' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'firstName' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'firstName' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'lastName' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'lastName' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'birthDate' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'birthDate' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'country' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'stateOrProvince' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'stateOrProvince' } }
              },
              { kind: 'Argument', name: { kind: 'Name', value: 'zip' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'zip' } } },
              { kind: 'Argument', name: { kind: 'Name', value: 'sex' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'sex' } } },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'usageReasons' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'usageReasons' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'AuthenticatedUserFragment' } }]
            }
          }
        ]
      }
    },
    ...AuthenticatedUserFragmentFragmentDoc.definitions
  ]
} as unknown as DocumentNode<UserUpdateMutation, UserUpdateMutationVariables>
export const VerifyEmailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'VerifyEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'code' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verifyEmail' },
            arguments: [
              { kind: 'Argument', name: { kind: 'Name', value: 'code' }, value: { kind: 'Variable', name: { kind: 'Name', value: 'code' } } }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'username' } },
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subscription' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'totalUserUsedSpace' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'totalImagesCount' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'thresholdDetails' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'usedSpace' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'maxSpace' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'migrationStatus' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<VerifyEmailMutation, VerifyEmailMutationVariables>
export const YearsViewDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'YearsView' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'topMonths' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ViewPagination' } } }
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'yearsView' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'topMonths' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'topMonths' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'pagination' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'pagination' } }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'scrollPointer' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'scrollPointer' } }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'scrollPointer' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'items' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'year' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'previewStats' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'month' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'mediaCount' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'previewImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'imageId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'originalFilename' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'dateTaken' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'originalImage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'size' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'isLandscape' } }
                                      ]
                                    }
                                  },
                                  { kind: 'Field', name: { kind: 'Name', value: 'nsfw' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isVideoType' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isMobileUpload' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'isBlurred' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'albumId' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'albumName' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'deletionState' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [{ kind: 'Field', name: { kind: 'Name', value: 'deletedAt' } }]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<YearsViewQuery, YearsViewQueryVariables>
