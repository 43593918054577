import { getAuth } from 'firebase/auth'
import { SubscriptionStatus, UserProfileFragmentFragmentDoc } from '../../../graphql/generated'
import cache from '../../../providers/apollo/cache'

export default function useUserSubscription() {
  const userId = getAuth().currentUser?.uid
  const userProfile = cache.readFragment({ fragment: UserProfileFragmentFragmentDoc, id: `UserProfile:${userId}` })

  const subscription = userProfile?.subscription
  const isSubscriptionActive = subscription?.subscription?.status === SubscriptionStatus.Active && subscription.planName !== 'no-plan'

  return { isSubscriptionActive, subscription }
}
