import { getAuth } from 'firebase/auth'
import { UserProfileFragmentFragmentDoc } from '../../../graphql/generated'
import cache from '../../../providers/apollo/cache'

export default function useProfilePlan() {
  const userId = getAuth().currentUser?.uid ?? ''
  const profile = cache.readFragment({ fragment: UserProfileFragmentFragmentDoc, id: `UserProfile:${userId}` })
  const plan = profile?.plan ?? { plan_name: 'No plan' }
  const billingInterval = plan.billing_interval ?? 'Never'

  const isStoragePlan = plan.is_storage_plan === true
  const isSharingPlan = plan.is_sharing_plan === true
  const isNoPlan = plan.plan_name === 'no-plan'
  const canCreateBucket = plan.is_create_bucket_enabled === true

  return { isSharingPlan, isStoragePlan, billingInterval, isNoPlan, canCreateBucket }
}
