import { InMemoryCache, InMemoryCacheConfig, makeVar, StoreObject } from '@apollo/client'
import { KeyFieldsContext } from '@apollo/client/cache/inmemory/policies'
import { uniqBy } from 'lodash'
import {
  BucketAlbumsQuery,
  BucketMediaByAlbumIdQuery,
  BucketMediaSearchQuery,
  BucketSharesQuery,
  GetAlbumImagesV2Query,
  GetImagesFromAlbumAndSubAlbumsQuery,
  GetPublicAlbumImagesV2Query,
  GetTrashImagesQuery,
  GetUserImagesQuery,
  HostedImagesQuery,
  ImagesReadByAttributesQuery,
  MonthsViewQuery,
  SearchImagesQuery,
  BucketsByUserIdQuery,
  BucketMediaByShareIdQuery
} from '../../graphql/generated'
import { Media } from '../../views/account/components/types'
import { Alert } from './models/alert'
import { AuthenticatedUser } from './models/authenticatedUser'
import { BottomToolbarConfig } from './models/galleryControlSettings'
import { SelectedMedia } from './models/SelectedMedia'
import { UploadInProgress } from './models/uploadsInProgress'

type Modal =
  | 'albumDelete'
  | 'albumShare'
  | 'bucketAlbumCreate'
  | 'bucketAlbumDelete'
  | 'bucketInvite'
  | 'bucketMediaDelete'
  | 'bucketMediaDeletePermanently'
  | 'bucketSharingLinkCreate'
  | 'bucketMediaShareDelete'
  | 'bucketMediaShareUpdate'
  | 'bucketTrashEmpty'
  | 'bucketUpgrade'
  | 'changeRole'
  | 'createBucket'
  | 'termsAndConditions'
  | 'termsAndConditionsLogin'
  | null

type Drawer =
  | 'albumDetails'
  | 'albumMove'
  | 'bucketAlbumDetails'
  | 'bucketAlbumMove'
  | 'bucketMediaDetails'
  | 'bucketMediaMove'
  | 'bucketMediaRestore'
  | 'main'
  | 'mediaDetails'
  | null

// TODO: delete this var when legacy architecture is deprecated
export const convertedVar = makeVar(false)

export const alertsVar = makeVar<Alert[]>([])
export const drawerVar = makeVar<Drawer>(null)
export const modalVar = makeVar<Modal>(null)
export const adminUserIdVar = makeVar<string | null>(null)
export const selectModeEnabledVar = makeVar<{ albums?: boolean; media?: boolean }>({})
export const selectedMediaVar = makeVar<string[]>([])
export const selectedAlbumsVar = makeVar<string[]>([])
export const uploadsInProgressVar = makeVar<UploadInProgress>({})
export const authenticatedUserVar = makeVar<AuthenticatedUser | null>(null)
export const activeScrollingVar = makeVar<'up' | 'down' | null>(null)
export const galleryDatesVar = makeVar<{ startDate?: string; endDate?: string }>({})
export const galleryControlSettingsVar = makeVar<BottomToolbarConfig | null>(null)
export const activeAlbumIdVar = makeVar<string | null>(null)
export const activeShareIdVar = makeVar<string | null>(null)
export const publicPasswordValidatedVar = makeVar<boolean | undefined>(undefined)

/**
 * TODO: This is a super short term var to provide feedback to the user after disabling hosting
 * This should be deleted as soon as the API is updated to return an isActive hosting field
 */

export const hostedDisabledVar = makeVar<SelectedMedia>({})

const config: InMemoryCacheConfig = {
  typePolicies: {
    BucketUser: {
      keyFields: ({ id, bucketId }: StoreObject, { typename }: KeyFieldsContext) => `${typename}:${bucketId}:${id}`
    },
    HostedImage: {
      keyFields: ({ imageId }: StoreObject, { typename }: KeyFieldsContext) => `${typename}:${imageId}`
    },
    Image: {
      fields: {
        thumbnailImage: {
          merge: (existing: Media, incoming: Media) => ({ ...existing, ...incoming })
        }
      }
    },
    MonthsView: {
      keyFields: ({ scrollPointer }: StoreObject, { typename }: KeyFieldsContext) => `${typename}:${scrollPointer}`
    },
    MonthViewItem: {
      keyFields: ({ localDate }: StoreObject, { typename }: KeyFieldsContext) => `${typename}:${localDate}`,
      merge: (existing: MonthsViewQuery['monthsView'], incoming: MonthsViewQuery['monthsView']) => ({ ...existing, ...incoming })
    },
    YearsView: {
      keyFields: ({ scrollPointer }: StoreObject, { typename }: KeyFieldsContext) => `${typename}:${scrollPointer}`
    },
    YearsViewItem: {
      keyFields: ({ year }: StoreObject, { typename }: KeyFieldsContext) => `${typename}:${year}`
    },
    Query: {
      fields: {
        bucketMediaByAlbumId: {
          keyArgs: ['albumId', 'bucketId', 'limit', 'filterBy', 'sortBy'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing?: BucketMediaByAlbumIdQuery['bucketMediaByAlbumId'], incoming?: BucketMediaByAlbumIdQuery['bucketMediaByAlbumId']) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const items = existing?.nextToken === incoming?.nextToken ? [...incomingItems] : [...existingItems, ...incomingItems]
            return {
              nextToken: incoming?.nextToken,
              items: uniqBy(items, '__ref')
            }
          }
        },
        bucketMediaByShareId: {
          keyArgs: ['albumId', 'bucketId'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing?: BucketMediaByShareIdQuery['bucketMediaByShareId'], incoming?: BucketMediaByShareIdQuery['bucketMediaByShareId']) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const items = existing?.nextToken === incoming?.nextToken ? [...incomingItems] : [...existingItems, ...incomingItems]
            return {
              nextToken: incoming?.nextToken,
              items: uniqBy(items, '__ref')
            }
          }
        },
        bucketMediaSearch: {
          keyArgs: ['bucketId', 'query'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing?: BucketMediaSearchQuery['bucketMediaSearch'], incoming?: BucketMediaSearchQuery['bucketMediaSearch']) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const items = existing?.nextToken === incoming?.nextToken ? [...incomingItems] : [...existingItems, ...incomingItems]
            return {
              nextToken: incoming?.nextToken,
              items: uniqBy(items, '__ref')
            }
          }
        },
        bucketAlbums: {
          keyArgs: ['albumId', 'bucketId'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing?: BucketAlbumsQuery['bucketAlbums'], incoming?: BucketAlbumsQuery['bucketAlbums']) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const items = existing?.nextToken === incoming?.nextToken ? [...incomingItems] : [...existingItems, ...incomingItems]
            return {
              nextToken: incoming?.nextToken,
              items: uniqBy(items, '__ref')
            }
          }
        },
        bucketShares: {
          keyArgs: ['bucketId', 'limit'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing?: BucketSharesQuery['bucketShares'], incoming?: BucketSharesQuery['bucketShares']) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const items = existing?.nextToken === incoming?.nextToken ? [...incomingItems] : [...existingItems, ...incomingItems]
            return {
              nextToken: incoming?.nextToken,
              items: uniqBy(items, '__ref')
            }
          }
        },
        bucketsByUserId: {
          keyArgs: false, // adjust according to your query's key arguments
          merge(existing?: BucketsByUserIdQuery['bucketsByUserId'], incoming?: BucketsByUserIdQuery['bucketsByUserId']) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const items = existing?.nextToken === incoming?.nextToken ? [...incomingItems] : [...existingItems, ...incomingItems]
            return {
              nextToken: incoming?.nextToken,
              items: uniqBy(items, '__ref')
            }
          }
        },
        getUserImages: {
          // Don't cache separate results based on any of this field's arguments.
          keyArgs: ['pageSize', 'sortBy', 'scrollDirection'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing: GetUserImagesQuery['getUserImages'], incoming: GetUserImagesQuery['getUserImages'], { mergeObjects }) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const results = {
              scrollPointer: incoming?.scrollPointer,
              items: [...existingItems, ...incomingItems]
            }
            return existing?.scrollPointer === incoming?.scrollPointer ? mergeObjects(existing, incoming) : results
          }
        },
        hostedImages: {
          // Don't cache separate results based on any of this field's arguments.
          keyArgs: false as false,
          // Concatenate the incoming list items with the existing list items.
          merge(existing?: HostedImagesQuery['hostedImages'], incoming?: HostedImagesQuery['hostedImages']) {
            const existingImages = existing?.images || []
            const incomingImages = incoming?.images || []
            return {
              scrollPointer: incoming?.scrollPointer,
              images: [...existingImages, ...incomingImages]
            }
          }
        },
        getTrashImages: {
          // Don't cache separate results based on any of this field's arguments.
          keyArgs: false as false,
          // Concatenate the incoming list items with the existing list items.
          merge(existing: GetTrashImagesQuery['getTrashImages'], incoming: GetTrashImagesQuery['getTrashImages'], { mergeObjects }) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const results = {
              scrollPointer: incoming?.scrollPointer,
              items: [...existingItems, ...incomingItems]
            }
            return existing?.scrollPointer === incoming?.scrollPointer ? mergeObjects(existing, incoming) : results
          }
        },
        imagesReadByAttributes: {
          keyArgs: ['sortBy', 'desc', 'pageSize', 'attributes'],
          // Concatenate the incoming list items with the existing list items.
          merge(
            existing: ImagesReadByAttributesQuery['imagesReadByAttributes'],
            incoming: ImagesReadByAttributesQuery['imagesReadByAttributes'],
            { mergeObjects }
          ) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const results = {
              scrollPointer: incoming?.scrollPointer,
              items: [...existingItems, ...incomingItems],
              total: incoming?.total
            }
            return existing?.scrollPointer === incoming?.scrollPointer ? mergeObjects(existing, incoming) : results
          }
        },
        getImagesFromAlbumAndSubAlbums: {
          keyArgs: ['albumId', 'sortBy', 'pageSize'],
          // Concatenate the incoming list items with the existing list items.
          merge(
            existing: GetImagesFromAlbumAndSubAlbumsQuery['getImagesFromAlbumAndSubAlbums'],
            incoming: GetImagesFromAlbumAndSubAlbumsQuery['getImagesFromAlbumAndSubAlbums'],
            { mergeObjects }
          ) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const results = {
              scrollPointer: incoming?.scrollPointer,
              items: [...existingItems, ...incomingItems]
            }

            return existing?.scrollPointer === incoming?.scrollPointer ? mergeObjects(existing, incoming) : results
          }
        },
        getAlbumImagesV2: {
          keyArgs: ['albumId', 'sortBy', 'pageSize'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing: GetAlbumImagesV2Query['getAlbumImagesV2'], incoming: GetAlbumImagesV2Query['getAlbumImagesV2'], { mergeObjects }) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const results = {
              scrollPointer: incoming?.scrollPointer,
              items: [...existingItems, ...incomingItems]
            }
            return existing?.scrollPointer === incoming?.scrollPointer ? mergeObjects(existing, incoming) : results
          }
        },
        getPublicAlbumImagesV2: {
          keyArgs: ['albumId', 'sortBy', 'pageSize', 'password'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing?: GetPublicAlbumImagesV2Query['getPublicAlbumImagesV2'], incoming?: GetPublicAlbumImagesV2Query['getPublicAlbumImagesV2']) {
            const existingItems = existing?.items || []
            const incomingItems = incoming?.items || []
            const items = [...existingItems, ...incomingItems]
            return {
              scrollPointer: incoming?.scrollPointer,
              items: uniqBy(items, '__ref')
            }
          }
        },
        searchImages: {
          keyArgs: ['query', 'page', 'perPage'],
          // Concatenate the incoming list items with the existing list items.
          merge(existing?: SearchImagesQuery['searchImages'], incoming?: SearchImagesQuery['searchImages']) {
            const existingItems = existing?.result || []
            const incomingItems = incoming?.result || []
            const result = existing?.result === incoming?.result ? [...incomingItems] : [...existingItems, ...incomingItems]
            return {
              ...incoming,
              result
            }
          }
        }
      }
    }
  }
}

const cache = new InMemoryCache(config)

export default cache
