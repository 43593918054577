import React, { ChangeEvent, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SearchIcon from '@mui/icons-material/Search'
import { Tooltip, IconButton, InputBase, Popover, Typography, Box } from '@mui/material'
import useBucket from '../../hooks/useBucket'
import useBucketPlan from '../../hooks/useBucketPlan'

const searchBarStyles = {
  light: {
    color: '#fff',
    p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 0,
    margin: '0 15px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)'
    }
  },
  dark: {
    color: '#000',
    p: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    boxShadow: 0,
    margin: '0 10px',
    border: '1px solid #919AA9',
    borderRadius: '4px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.15)'
    }
  }
}

export default function SearchBar() {
  const { bucket } = useBucket()
  const { restrictions, tooltipTitle } = useBucketPlan()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
  const [popoverMessage, setPopoverMessage] = useState('')
  const open = Boolean(anchorEl)
  const id = open ? 'search-popover' : undefined
  const [q, setQ] = useState<string>(searchParams.get('q') ?? '')
  const [isInputValid, setIsInputValid] = useState(true)
  const restricted = restrictions.search
  const hideTooltip = restricted === false
  const title = tooltipTitle('search')

  const sanitizeInput = (input: string) => {
    return input.slice(0, 20)
  }

  const validateInput = (input: string) => {
    if (input === '') return true
    const allowableCharsRegex = /^[a-zA-Z0-9_'.,@$]*$/
    return allowableCharsRegex.test(input) && input.length <= 20
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    setQ(sanitizeInput(inputValue))
    setIsInputValid(validateInput(inputValue))
  }

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    const inputEl = event.currentTarget.querySelector('input')

    if (!isInputValid || (q.length < 3 && q.length !== 0)) {
      setPopoverMessage('Invalid input. Please use 3-20 characters')
      setAnchorEl(inputEl)
      return
    }

    setAnchorEl(null)

    if (q.trim() === '') {
      navigate(`/bucket/${bucket?.id}`)
    } else {
      navigate(`/bucket/${bucket?.id}/search?q=${encodeURIComponent(q.trim())}`)
    }
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Tooltip
        PopperProps={{ disablePortal: hideTooltip }}
        disableFocusListener={hideTooltip}
        disableHoverListener={hideTooltip}
        disableTouchListener={hideTooltip}
        title={isInputValid ? title : "Invalid input. Please use 3-20 characters: a-z, A-Z, 0-9, _ ' . , @ $"}
        open={!isInputValid}
      >
        <Box component="form" onSubmit={handleSubmit} sx={searchBarStyles.dark}>
          <InputBase
            name="unique-search-field-name"
            autoComplete="off"
            disabled={restricted}
            size="small"
            sx={{
              marginLeft: 1,
              flex: 1,
              '& .MuiInputBase-input': {
                padding: 0
              },
              '&.MuiInputBase-root': {
                color: 'inherit'
              }
            }}
            onChange={handleChange}
            id="search"
            value={q}
            placeholder="Search this bucket..."
            inputProps={{ 'aria-label': 'Search this Bucket' }}
          />
          <IconButton disabled={restricted || !isInputValid} type="submit" sx={{ p: '6px' }} color="inherit" aria-label="search">
            <SearchIcon fontSize="small" />
          </IconButton>
        </Box>
      </Tooltip>
      <Popover id={id} open={open} anchorEl={anchorEl} onClose={handlePopoverClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
        <Typography sx={{ p: 2 }}>{popoverMessage}</Typography>
      </Popover>
    </>
  )
}
