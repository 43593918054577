import { Box, styled, Typography } from '@mui/material'

interface Props {
  step: string
  title: string
}

interface GenericProps {
  title: string
}

const StepNumber = styled(Typography)(({ theme }) => ({
  color: '#1C47CB',
  fontFamily: '"proxima-nova", sans-serif',
  fontSize: '1rem',
  fontWeight: 500,
  textAlign: 'center',
  textTransform: 'uppercase',
  lineHeight: 1,
  [theme.breakpoints.up('md')]: {
    fontSize: '1.25rem'
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.75rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2rem'
  }
}))

const StepTitle = styled(Typography)(({ theme }) => ({
  color: '#101828',
  fontSize: '2.0rem',
  fontWeight: 600,
  lineHeight: 1,
  textAlign: 'center',
  [theme.breakpoints.up('xs')]: {
    margin: '10px 0 20px'
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2.5rem'
  },
  [theme.breakpoints.up('lg')]: {
    margin: '10px 0 40px',
    fontSize: '3.0rem'
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '3.5rem'
  }
}))

function StepHeader({ step, title }: Props) {
  return (
    <Box component="header" sx={{ padding: '0 20px' }}>
      <StepNumber variant="h2">Step {step}</StepNumber>
      <StepTitle variant="h3">{title}</StepTitle>
    </Box>
  )
}

function GenericHeader({ title }: GenericProps) {
  return (
    <Box component="header" sx={{ padding: '0 20px' }}>
      <StepTitle variant="h3">{title}</StepTitle>
    </Box>
  )
}

export { StepHeader, GenericHeader }
