import { Typography } from '@mui/material'
import { getAuth } from 'firebase/auth'

export default function Success() {
  return (
    <div>
      <Typography variant="h6">Success!</Typography>
      <Typography>Your email has been updated to {getAuth().currentUser?.email}</Typography>
      <Typography>Check your email to confirm your address.</Typography>
    </div>
  )
}
